import React from 'react';
import { Wrapper, Description } from './style';

interface IProps {
  notes?: string;
}
const Notes: React.FC<IProps> = ({ notes = '' }) => {
  return (
    <Wrapper>
      <p>#Notes</p>
      <Description>{notes}</Description>
    </Wrapper>
  );
};

export default Notes;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  CREATE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_SUCCESS,
} from '@/constants/toastStatus';

import {
  getSuppliersApi,
  createSupplierApi,
  updateSupplierApi,
  deleteSupplierApi,
  getSupplierApi,
} from '@/apis/supplier';

import {
  IGetSuppliersRequest,
  IGetSuppliersResponse,
  ICreateSupplierRequest,
  ICreateSupplierResponse,
  IUpdateSupplierRequest,
  IUpdateSupplierResponse,
  IDeleteSupplierRequest,
  IDeleteSupplierResponse,
  IGetSupplierRequest,
  IGetSupplierResponse,
} from './type';

export const getSuppliers = createAsyncThunk<
  IGetSuppliersResponse,
  IGetSuppliersRequest
>('supplier/getSuppliers', async (body, { rejectWithValue }) => {
  try {
    return await getSuppliersApi(body);
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

export const createSupplier = createAsyncThunk<
  ICreateSupplierResponse,
  ICreateSupplierRequest
>('supplier/createSupplier', async (body, { rejectWithValue }) => {
  try {
    const res = await createSupplierApi(body);
    toast.success(CREATE_SUPPLIER_SUCCESS);

    return res;
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

export const updateSupplier = createAsyncThunk<
  IUpdateSupplierResponse,
  IUpdateSupplierRequest
>('supplier/updateSupplier', async (body, { rejectWithValue }) => {
  try {
    const res = await updateSupplierApi(body);
    toast.success(UPDATE_SUPPLIER_SUCCESS);

    return res;
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

export const deleteSupplier = createAsyncThunk<
  IDeleteSupplierResponse,
  IDeleteSupplierRequest
>('supplier/deleteSupplier', async (body, { rejectWithValue }) => {
  try {
    const res = await deleteSupplierApi(body);
    toast.success(DELETE_SUPPLIER_SUCCESS);

    return res;
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

export const getSupplier = createAsyncThunk<
  IGetSupplierResponse,
  IGetSupplierRequest
>('supplier/getSupplier', async (params, { rejectWithValue }) => {
  try {
    return await getSupplierApi(params);
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

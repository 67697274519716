import React from 'react';
import { ThemeProvider as ThemeWrapper } from 'styled-components';
import { LIGHT_THEME } from '@/constants/themes';

interface IProps {
  children: React.ReactNode;
}

const ThemeProvider: React.FC<IProps> = ({ children }) => {
  return <ThemeWrapper theme={LIGHT_THEME}>{children}</ThemeWrapper>;
};

export default ThemeProvider;

import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { OrderLayout } from '@/layouts';
import { useAppDispatch, useAppSelector, useSocket } from '@/hooks';
import { getMessages } from '@/stores/order/actions';
import { uploadSingle } from '@/stores/upload/actions';
import { IUploadSingleRequest } from '@/stores/upload/type';
import { unwrapResult } from '@reduxjs/toolkit';
import configs from '@/configs';
import SOCKET_EVENTS from '@/constants/socketEvents';
import MESSAGE_TYPES from '@/constants/messageTypes';
import { setMessages } from '@/stores/order/reducer';
import { getUsers } from '@/stores/user/actions';
import _ from 'lodash';
import { MentionData } from '@draft-js-plugins/mention';
import avatarDefault from '@/assets/images/avatarDefault.png';

import {
  OrderChatForm,
  IFormValues,
  Messages,
  ISelectedUser,
} from './components';

import { Wrapper } from './style';

const Suppliers: React.FC = () => {
  const dispatch = useAppDispatch();
  const { order, auth, upload, user } = useAppSelector((state) => state);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { socket } = useSocket();
  const params = useParams<{ id: string }>();
  const [foundUser, setFoundUser] = useState<MentionData[]>([]);
  const [initialSpinning, setInitialSpinning] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<ISelectedUser[]>([]);

  useEffect(() => {
    setInitialSpinning(true);
    dispatch(setMessages({ data: [], hasMore: true }));
    setCurrentPage(1);
    dispatch(
      getMessages({
        _id: params.id,
        pagination: { limit: configs.limit, page: 1 },
      }),
    )
      .then(unwrapResult)
      .then(() => {
        setInitialSpinning(false);
        setCurrentPage(2);
      });
  }, [params.id]);

  const handleGetMessages = () => {
    dispatch(
      getMessages({
        _id: params.id,
        pagination: { limit: configs.limit, page: currentPage },
      }),
    );

    setCurrentPage(currentPage + 1);
  };

  const handleSendTextMessage = ({ content }: IFormValues) => {
    const customContent = content;
    const tags = _.unionBy(selectedUser, 'id')?.map((user) => {
      if (content.includes(user.fullName)) {
        return user.id;
      }
    });

    handleNewMessageEvent(customContent, MESSAGE_TYPES.TEXT, tags as string[]);
  };

  const handleUploadSingle = (formData: IUploadSingleRequest) => {
    dispatch(uploadSingle(formData))
      .then(unwrapResult)
      .then(({ url }) => {
        handleNewMessageEvent(url, MESSAGE_TYPES.IMAGE);
      });
  };

  const handleNewMessageEvent = (
    content: string,
    type: string,
    tags: string[] = [],
  ) => {
    socket.emit(SOCKET_EVENTS.CLIENT.NEW_MESSAGE, {
      content,
      order: params.id,
      type,
      user: auth.user._id,
      tags,
    });

    setSelectedUser([]);
  };

  const handleGetUsers = () => {
    dispatch(getUsers({ limit: 1000 }))
      .then(unwrapResult)
      .then((res) => {
        const customFoundUser: MentionData[] = [];
        res.forEach((user) => {
          if (user._id !== auth.user._id) {
            const { _id, fullName, cover } = user;
            customFoundUser.push({
              id: _id,
              name: fullName,
              avatar: cover || avatarDefault,
            });
          }
        });
        setFoundUser(customFoundUser);
      });
  };

  return (
    <OrderLayout>
      <Wrapper>
        <Spin spinning={initialSpinning} style={{ marginTop: 50 }}>
          {currentPage !== 1 && (
            <Messages
              order={order}
              fetchMoreMessagesData={handleGetMessages}
              user={auth.user}
            />
          )}
        </Spin>

        <OrderChatForm
          upload={upload}
          foundUser={foundUser}
          isFetching={user.isFetching}
          onFinish={handleSendTextMessage}
          handleUploadSingle={handleUploadSingle}
          handleGetUsers={handleGetUsers}
          addSelectedUser={(user) => {
            setSelectedUser([...selectedUser, user]);
          }}
        />
      </Wrapper>
    </OrderLayout>
  );
};

export default Suppliers;

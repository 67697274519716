import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCountriesApi } from '@/apis/country';

import { IGetCountriesResponse } from './type';

export const getCountries = createAsyncThunk<IGetCountriesResponse>(
  'country/getCountries',
  async (body, { rejectWithValue }) => {
    try {
      return await getCountriesApi();
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

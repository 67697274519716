import React from 'react';
import { Menu, Dropdown, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { UPDATE_KEY, DELETE_KEY } from '@/constants/supplierDropdownKeys';
import ROUTES from '@/constants/routes';
import { ISupplier } from '@/type';
import supplierDefault from '@/assets/images/supplierDefault.png';
import replaceRoutePath from '@/utils/replaceRoutePath';

import {
  Wrapper,
  Avatar,
  RightContainer,
  LeftContainer,
  InformationContainer,
  NameTitle,
  OrderTitle,
  DropdownContainer,
  MenuContainer,
  ItemText,
  IconMenu,
  BlockText,
  BottomInformationContainer,
  DayTitle,
} from './style';

interface IProps {
  viewOnly?: boolean;
  supplier: ISupplier;
  supplierSelected?: ISupplier;
  handleSelectSupplier: (supplierId: ISupplier['_id']) => void;
  handleDeleteSupplier: () => void;
}

const SupplierItem: React.FC<IProps> = ({
  viewOnly,
  supplier,
  handleDeleteSupplier,
  handleSelectSupplier,
  supplierSelected,
}) => {
  const history = useHistory();

  const handleClickDropdown = ({ key }: { key: string }) => {
    switch (key) {
      case UPDATE_KEY:
        if (supplierSelected) {
          const path = replaceRoutePath({
            originalPath: ROUTES.UPDATE_SUPPLIER_PAGE.path,
            replacementValue: supplierSelected?._id,
          });

          history.push(path);
        }

        break;

      case DELETE_KEY:
        Modal.confirm({
          title: 'Are you sure you want to delete this supplier?',
          icon: <ExclamationCircleOutlined />,
          okText: 'OK',
          cancelText: 'Cancel',
          onOk: handleDeleteSupplier,
        });
        break;
    }
  };

  const menu = (
    <MenuContainer onClick={handleClickDropdown}>
      <Menu.Item key={UPDATE_KEY}>
        <ItemText>Update</ItemText>
      </Menu.Item>
      <Menu.Item key={DELETE_KEY}>
        <BlockText>Delete</BlockText>
      </Menu.Item>
    </MenuContainer>
  );

  return (
    <Wrapper>
      <LeftContainer>
        <Avatar preview={false} src={supplier.cover || supplierDefault} />
        <InformationContainer>
          <NameTitle>{supplier.name}</NameTitle>
          <BottomInformationContainer>
            <DayTitle>{supplier.overDay} day</DayTitle>
            <OrderTitle>{supplier.totalOrder || 0} orders</OrderTitle>
          </BottomInformationContainer>
        </InformationContainer>
      </LeftContainer>

      {!viewOnly && (
        <RightContainer
          onClick={() => {
            handleSelectSupplier(supplier._id);
          }}
        >
          <Dropdown overlay={menu} trigger={['click']}>
            <DropdownContainer
              className='ant-dropdown-link'
              onClick={(e) => e.preventDefault()}
            >
              <IconMenu />
            </DropdownContainer>
          </Dropdown>
        </RightContainer>
      )}
    </Wrapper>
  );
};

export default SupplierItem;

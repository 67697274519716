import React, { useState } from 'react';
import { Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

import { IMessageState } from '@/stores/message/type';
import { HEADER_HEIGHT, BREADCRUMB_HEIGHT } from '@/constants/componentSize';
import NOTIFICATION_TYPES from '@/constants/notificationTypes';
import { NotificationHeader, NotificationItem } from '@/components';
import { useWindowSize } from '@/hooks';
import { MessagesWrapper } from './style';

interface IProps {
  message: IMessageState;
  fetchMoreData: () => void;
  handleViewUnread: () => void;
  handleViewAll: () => void;
  handleSetRead: (_id: string) => void;
  handleCloseDropdown: () => void;
}

const Messages: React.FC<IProps> = ({
  message,
  fetchMoreData,
  handleViewUnread,
  handleViewAll,
  handleSetRead,
  handleCloseDropdown,
}) => {
  const size = useWindowSize();
  const [isUnread, setIsUnread] = useState<boolean>(false);
  const title = 'message';

  return (
    <MessagesWrapper>
      <NotificationHeader
        title={title}
        isUnread={isUnread}
        setIsUnread={setIsUnread}
        handleViewAll={handleViewAll}
        handleViewUnread={handleViewUnread}
        handleCloseDropdown={handleCloseDropdown}
      />

      <InfiniteScroll
        dataLength={message.data.length}
        next={fetchMoreData}
        height={size.height - HEADER_HEIGHT - BREADCRUMB_HEIGHT}
        hasMore={message.hasMore}
        loader={
          <Spin
            spinning={message.isFetching}
            style={{ marginTop: 20, width: '100%' }}
          />
        }
      >
        {message.data.map((message) => (
          <NotificationItem
            data={message}
            key={message._id}
            onRead={handleSetRead}
            type={NOTIFICATION_TYPES.MESSAGE}
            handleCloseDropdown={handleCloseDropdown}
          />
        ))}
      </InfiniteScroll>
    </MessagesWrapper>
  );
};

export default Messages;

import { IOrderItem } from '@/type';

const getPrices = (orderItems: IOrderItem[]): number => {
  let price = 0;
  orderItems?.forEach((item) => {
    price += item?.price || 0;
  });

  return price;
};

export default getPrices;

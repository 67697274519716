import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

interface IProps {
  children: React.ReactNode;
}

interface IContextValue {
  backToRoute: string;
  setBackToRoute: Dispatch<SetStateAction<string>>;
}

export const MenuContext = createContext<IContextValue>({
  backToRoute: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setBackToRoute: () => {},
});

const MenuContextProvider: React.FC<IProps> = ({ children }) => {
  const [backToRoute, setBackToRoute] = useState<string>('');

  return (
    <MenuContext.Provider value={{ backToRoute, setBackToRoute }}>
      {children}
    </MenuContext.Provider>
  );
};

export default MenuContextProvider;

import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 64px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.color.lightBorder};
  padding-left: 1.2rem;
  display: flex;
  align-items: center;
`;

import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spin } from 'antd';
import { MenuContext } from '@/contexts/MenuContext';
import ROUTES from '@/constants/routes';
import { IStaffPositionFormValues } from '@/pages/Employee/Employees/components';
import { StaffItem } from '@/components';
import { IUser } from '@/type';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  IUpdateUserRequest,
  IChangePasswordUserRequest,
} from '@/stores/user/type';
import config from '@/configs';
import { getUsers, updateUser, changePassword } from '@/stores/user/actions';
import { ActionButtons } from './components';

const EmployeesPage: React.FC = () => {
  const { setBackToRoute } = useContext(MenuContext);
  const dispatch = useAppDispatch();
  const { user, role, auth } = useAppSelector((state) => state);
  const [staffSelected, setStaffSelected] = useState<IUser>();
  const { limit } = config;
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    setBackToRoute(ROUTES.HOME_PAGE.path);
    fetchMoreUsersData();
  }, []);

  const handleUpdateUser = (values: IUpdateUserRequest) => {
    dispatch(updateUser(values));
  };

  const initialStaffPositionValues: IStaffPositionFormValues = {
    role: staffSelected?.role._id,
  };

  const handleSelectStaff = (staffId: IUser['_id']): void => {
    setStaffSelected(user.data[staffId]);
  };

  const handleChangePasswordUser = (values: IChangePasswordUserRequest) => {
    dispatch(changePassword(values));
  };

  const fetchMoreUsersData = () => {
    dispatch(getUsers({ limit, page: currentPage }));
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <ActionButtons />

      <InfiniteScroll
        dataLength={Object.keys(user.data).length}
        next={fetchMoreUsersData}
        hasMore={user.hasMore}
        loader={
          <Spin
            style={{ width: '100%', marginTop: 20 }}
            spinning={user.isFetching}
          />
        }
      >
        {Object.values(user.data).map((staff, index) => (
          <StaffItem
            viewOnly={staff._id === auth.user._id}
            roles={role.data}
            staffSelected={staffSelected}
            handleUpdateUser={handleUpdateUser}
            handleSelectStaff={handleSelectStaff}
            staff={staff}
            initialStaffPositionValues={initialStaffPositionValues}
            handleChangePasswordUser={handleChangePasswordUser}
            key={index}
          />
        ))}
      </InfiniteScroll>
    </>
  );
};

export default EmployeesPage;

import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

export const ProductContainer = styled.div`
  width: 100%;
  border: 1px solid #d9d9d9;
  margin-bottom: 0.5rem;
`;

export const TopContainer = styled.div`
  height: 42px;
  background-color: ${({ theme }) => theme.color.gray};
  border-bottom: 1px solid #d9d9d9;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

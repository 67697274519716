import { ImageLightBox } from '@/components';
import DELETE_STATUS from '@/constants/deleteStatus';
import { ORDER_TO_RESTORE } from '@/constants/toastStatus';
import { IOrder, ISupplier } from '@/type';
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter';
import formatCurrency from '@/utils/formatCurrency';
import getOrderStatusColor from '@/utils/getOrderStatusColor';
import getPrices from '@/utils/getPrices';
import {
  AntDesignOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import { Avatar } from 'antd';
import moment from 'moment';
import React from 'react';

import {
  BottomRightContainer,
  ButtonRestore,
  CInformation,
  CoverContainer,
  Customer,
  Date,
  ListProductContainer,
  Order,
  OrderId,
  PaidContainer,
  PaidTitle,
  Payment,
  PLeftContainer,
  PRightContainer,
  ProductContainer,
  Size,
  Status,
  TopRightContainer,
  Wrapper,
  WrapperIcon,
  WrapperStatusOder,
} from './style';

interface IProps {
  order: IOrder;
  onUpdateOrderTrash?: (
    orderId: string,
    deleteStatus: IOrder['deleteStatus'],
    toastStatus?: string,
  ) => void;
}

const OrderCardTrash: React.FC<IProps> = ({ order, onUpdateOrderTrash }) => {
  const totalAmount =
    getPrices(order.orderItems) + order.shippingFee - order.discount;

  const handleUpdateDeleteStatus = () => {
    if (onUpdateOrderTrash) {
      onUpdateOrderTrash(order._id, DELETE_STATUS.RESTORE, ORDER_TO_RESTORE);
    }
  };
  return (
    <Wrapper>
      <Order>
        <ListProductContainer>
          {order?.orderItems?.map((item) => (
            <ProductContainer key={item._id}>
              <PLeftContainer>
                {item.product.cover ? (
                  <CoverContainer>
                    <ImageLightBox imageUrl={item.product.cover || ''} />
                  </CoverContainer>
                ) : (
                  <Avatar
                    shape='square'
                    icon={<AntDesignOutlined />}
                    size={64}
                  />
                )}
              </PLeftContainer>
              <PRightContainer>
                <h3>{item.product.name}</h3>
                <div style={{ display: 'flex', gap: 10 }}>
                  <p>{(item.product.supplier as ISupplier).name}</p>
                  {!!item.product?.size && (
                    <Size>
                      <p>size</p> <p>{item.product.size}</p>
                    </Size>
                  )}
                </div>

                <p>{formatCurrency(item.price, '$')}</p>
                <WrapperStatusOder>
                  <ButtonRestore
                    onClick={handleUpdateDeleteStatus}
                    color='green'
                  >
                    <WrapperIcon>
                      <HistoryOutlined />
                    </WrapperIcon>
                    Restore
                  </ButtonRestore>
                </WrapperStatusOder>
              </PRightContainer>
            </ProductContainer>
          ))}
          <TopRightContainer>
            <Status color={getOrderStatusColor(order.status)}>
              {capitalizeFirstLetter(order.status || '')}
            </Status>
            <OrderId>#{order?.orderId}</OrderId>
          </TopRightContainer>
          <BottomRightContainer>
            <Date>
              <CalendarOutlined />
              <span>{moment(order?.createdAt).format('DD/MM/YYYY')}</span>
            </Date>
          </BottomRightContainer>
        </ListProductContainer>
      </Order>

      <Customer>
        <CInformation>
          <p>{order.customer?.facebookName}</p>
          <p>{order.customer?.fullName}</p>
          <p>{order.customer?.phoneNumber}</p>
          <p>{order.customer?.email}</p>
          <p>{order?.deliveryAddress || order.customer?.deliveryAddress}</p>
          <p>
            {typeof order.customer?.country !== 'string' &&
              order.customer?.country?.name}
          </p>
        </CInformation>

        <Payment>
          <p>Total price: {formatCurrency(getPrices(order.orderItems), '$')}</p>
          <p>Shipping: {formatCurrency(order.shippingFee, '$')}</p>
          <p>Discount: {formatCurrency(order.discount, '$')}</p>
          <p>Total amount: {formatCurrency(totalAmount, '$')}</p>

          {order.paidAmount === totalAmount ? (
            <PaidContainer>
              <CheckCircleOutlined style={{ fontSize: '1.43rem' }} />
              <PaidTitle>Paid</PaidTitle>
            </PaidContainer>
          ) : (
            <>
              <p>Paid amount: {formatCurrency(order.paidAmount, '$')}</p>
              <p>
                Unpaid amount:{' '}
                {formatCurrency(totalAmount - order.paidAmount, '$')}
              </p>
            </>
          )}
        </Payment>
      </Customer>
    </Wrapper>
  );
};

export default OrderCardTrash;

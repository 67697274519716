import React from 'react';
import { match } from 'react-router-dom';
import { useAppSelector } from '@/hooks';

interface IProps {
  match: match<{ id: string }>;
}

const DynamicSupplierBreadcrumb: React.FC<IProps> = ({ match }) => {
  const { supplier } = useAppSelector((state) => state);
  return <span>{supplier.data?.[match.params.id]?.name}</span>;
};

export default DynamicSupplierBreadcrumb;

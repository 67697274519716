import styled from 'styled-components';

export const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1.145rem;
  line-height: 1.715;
  color: ${({ theme }) => theme.color.black};
  opacity: 0.6;
  margin-bottom: 0.715rem;
`;

export const FormContainer = styled.div`
  margin-bottom: -1rem;
`;

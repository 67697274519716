import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getMessagesApi,
  setReadMessageApi,
  getTotalUnreadApi,
} from '@/apis/message';

import {
  IGetMessagesRequest,
  IGetMessagesResponse,
  ISetReadMessageRequest,
  ISetReadMessageResponse,
  IGetTotalUnreadRequest,
  IGetTotalUnreadResponse,
} from './type';

export const getMessages = createAsyncThunk<
  IGetMessagesResponse,
  IGetMessagesRequest
>('message/getMessages', async (query, { rejectWithValue }) => {
  try {
    return await getMessagesApi(query);
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const setReadMessage = createAsyncThunk<
  ISetReadMessageResponse,
  ISetReadMessageRequest
>('message/setReadMessage', async (params, { rejectWithValue }) => {
  try {
    return await setReadMessageApi(params);
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const getTotalUnreadMessage = createAsyncThunk<
  IGetTotalUnreadResponse,
  IGetTotalUnreadRequest
>('notification/getTotalUnreadMessage', async (body, { rejectWithValue }) => {
  try {
    return await getTotalUnreadApi();
  } catch (e) {
    return rejectWithValue(e);
  }
});

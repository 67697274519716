const SOCKET_EVENTS = {
  CLIENT: {
    TEST: 'TEST',
    NEW_MESSAGE: 'NEW_MESSAGE',
  },
  SERVER: {
    TEST: 'TEST',
    NEW_NOTIFICATION: 'NEW_NOTIFICATION',
    NEW_MESSAGE: 'NEW_MESSAGE',
  },
};

export default SOCKET_EVENTS;

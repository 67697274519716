import apiRequest from '@/utils/apiRequest';
import {
  IGetMessagesRequest,
  IGetMessagesResponse,
  ISetReadMessageRequest,
  ISetReadMessageResponse,
  IGetTotalUnreadResponse,
} from '@/stores/message/type';

const endpoint = '/messages';

export const getMessagesApi = (
  query: IGetMessagesRequest,
): Promise<IGetMessagesResponse> => {
  return apiRequest.get(endpoint, { params: query });
};

export const setReadMessageApi = (
  params: ISetReadMessageRequest,
): Promise<ISetReadMessageResponse> => {
  return apiRequest.put(`${endpoint}/${params._id}`);
};

export const getTotalUnreadApi = (): Promise<IGetTotalUnreadResponse> => {
  return apiRequest.get(`${endpoint}/total-unread`);
};

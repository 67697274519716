import React, { useState } from 'react';
import { Menu, Dropdown, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { IUser, IRole } from '@/type';
import POSITIONS from '@/constants/positions';
import {
  CHANGE_PASSWORD_KEY,
  BLOCK_KEY,
  CHANGE_POSITION_KEY,
} from '@/constants/employeeDropdownKeys';
import {
  ChangeStaffPasswordModal,
  IStaffPasswordFormValues,
  ChangeStaffPositionModal,
  IStaffPositionFormValues,
} from '@/pages/Employee/Employees/components';
import avatarDefault from '@/assets/images/avatarDefault.png';
import {
  IUpdateUserRequest,
  IChangePasswordUserRequest,
} from '@/stores/user/type';

import {
  Wrapper,
  Avatar,
  RightContainer,
  LeftContainer,
  InformationContainer,
  NameTitle,
  PhoneTitle,
  DropdownContainer,
  MenuContainer,
  ItemText,
  IconMenu,
  BlockText,
  TopInformationContainer,
  RoleContainer,
} from './style';

interface IProps {
  roles: Array<IRole>;
  staff: IUser;
  staffSelected?: IUser;
  viewOnly?: boolean;
  initialStaffPositionValues?: IStaffPositionFormValues;
  handleUpdateUser?: (values: IUpdateUserRequest) => void;
  handleSelectStaff?: (staffId: IUser['_id']) => void;
  handleChangePasswordUser?: (values: IChangePasswordUserRequest) => void;
}

const StaffItem: React.FC<IProps> = ({
  staff,
  roles,
  staffSelected,
  viewOnly,
  initialStaffPositionValues,
  handleUpdateUser,
  handleSelectStaff,
  handleChangePasswordUser,
}) => {
  const [visibleChangeStaffPassword, setVisibleChangeStaffPassword] =
    useState<boolean>(false);
  const [visibleChangeStaffPosition, setVisibleChangeStaffPosition] =
    useState<boolean>(false);

  const handleBlockAccount = (): void => {
    if (handleUpdateUser)
      handleUpdateUser({
        payload: {
          isBlocked: !staffSelected?.isBlocked,
        },
        _id: staffSelected?._id,
      });
  };

  const handleClickDropdown = ({ key }: { key: string }) => {
    switch (key) {
      case CHANGE_PASSWORD_KEY:
        setVisibleChangeStaffPassword(true);
        break;
      case BLOCK_KEY:
        Modal.confirm({
          title: 'Are you sure you want to lock this account?',
          icon: <ExclamationCircleOutlined />,
          okText: 'OK',
          cancelText: 'Cancel',
          onOk: handleBlockAccount,
        });
        break;
      case CHANGE_POSITION_KEY:
        setVisibleChangeStaffPosition(true);
        break;
    }
  };

  const menu = (
    <MenuContainer onClick={handleClickDropdown}>
      <Menu.Item key={CHANGE_PASSWORD_KEY}>
        <ItemText>Change password</ItemText>
      </Menu.Item>
      <Menu.Item key={CHANGE_POSITION_KEY}>
        <ItemText>Change position</ItemText>
      </Menu.Item>
      <Menu.Item key={BLOCK_KEY}>
        <BlockText>
          {staff.isBlocked ? 'Unblock this account' : 'Block this account'}
        </BlockText>
      </Menu.Item>
    </MenuContainer>
  );

  const handleChangeStaffPassword = (values: IStaffPasswordFormValues) => {
    if (staffSelected) {
      const userCustom: IChangePasswordUserRequest = {
        _id: staffSelected?._id,
        payload: {
          newPassword: values.newPassword,
        },
      };

      if (handleChangePasswordUser) handleChangePasswordUser(userCustom);
    }

    setVisibleChangeStaffPassword(false);
  };

  const handleChangeStaffPosition = (values: IStaffPositionFormValues) => {
    setVisibleChangeStaffPosition(false);

    if (handleUpdateUser)
      handleUpdateUser({
        payload: {
          role: values.role,
        },
        _id: staffSelected?._id,
      });
  };

  return (
    <Wrapper>
      <LeftContainer>
        <Avatar
          width={42}
          height={42}
          preview={false}
          src={staff.cover || avatarDefault}
        />
        <InformationContainer>
          <TopInformationContainer>
            <NameTitle>{staff.fullName}</NameTitle>
            <RoleContainer
              isBlocked={staff.isBlocked}
              hidden={
                !(
                  staff.role.name === POSITIONS.ADMIN ||
                  staff.role.name === POSITIONS.MANAGER ||
                  staff.isBlocked
                )
              }
            >
              {staff.isBlocked ? 'Blocked' : staff.role.name}
            </RoleContainer>
          </TopInformationContainer>
          <PhoneTitle>{staff.phoneNumber}</PhoneTitle>
        </InformationContainer>
      </LeftContainer>

      {!viewOnly && (
        <RightContainer
          onClick={() => {
            if (handleSelectStaff && staff) {
              handleSelectStaff(staff._id);
            }
          }}
        >
          <Dropdown overlay={menu} trigger={['click']}>
            <DropdownContainer
              className='ant-dropdown-link'
              onClick={(e) => e.preventDefault()}
            >
              <IconMenu />
            </DropdownContainer>
          </Dropdown>
        </RightContainer>
      )}

      <ChangeStaffPasswordModal
        visible={visibleChangeStaffPassword}
        onCancel={() => setVisibleChangeStaffPassword(false)}
        onFinish={handleChangeStaffPassword}
        fullName={staff.fullName}
      />

      <ChangeStaffPositionModal
        roles={roles}
        visible={visibleChangeStaffPosition}
        onCancel={() => setVisibleChangeStaffPosition(false)}
        onFinish={handleChangeStaffPosition}
        initialValues={initialStaffPositionValues}
        fullName={staff.fullName}
      />
    </Wrapper>
  );
};

export default StaffItem;

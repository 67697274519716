import React, { useContext, useEffect, useState } from 'react';
import { MenuContext } from '@/contexts/MenuContext';
import ROUTES from '@/constants/routes';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { SupplierForm, ISupplierFormValues } from '@/components';
import { IUploadSingleRequest } from '@/stores/upload/type';
import { ICreateSupplierRequest } from '@/stores/supplier/type';
import { useHistory } from 'react-router-dom';
import { uploadSingle } from '@/stores/upload/actions';
import { createSupplier } from '@/stores/supplier/actions';
import { ADD } from '@/constants/submitText';

const AddSupplier: React.FC = () => {
  const { setBackToRoute } = useContext(MenuContext);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { supplier, upload } = useAppSelector((state) => state);
  const [cover, setCover] = useState<string>('');

  useEffect(() => {
    setBackToRoute(ROUTES.SUPPLIERS_PAGE.path);
  }, []);

  const handleAddSupplier = (values: ISupplierFormValues): void => {
    const { name, overDay } = values;

    const supplierCustom: ICreateSupplierRequest = {
      name,
      overDay,
    };

    if (cover) supplierCustom.cover = cover;

    dispatch(createSupplier(supplierCustom))
      .then(unwrapResult)
      .then(() => {
        history.push(ROUTES.SUPPLIERS_PAGE.path);
      });
  };

  const handleUploadSingle = (formData: IUploadSingleRequest) => {
    dispatch(uploadSingle(formData))
      .then(unwrapResult)
      .then(({ url }) => {
        setCover(url);
      });
  };

  return (
    <>
      <SupplierForm
        cover={cover}
        submitText={ADD}
        upload={upload}
        supplier={supplier}
        onFinish={handleAddSupplier}
        handleUploadSingle={handleUploadSingle}
      />
    </>
  );
};

export default AddSupplier;

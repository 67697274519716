import React from 'react';
import { Form, Input, Button } from 'antd';
import logo from '@/assets/images/logo.svg';
import { Wrapper, FormHeader, FormWrapper } from './style';

export interface IFormValues {
  phoneNumber: string;
  password: string;
}

interface IProps {
  onFinish: (values: IFormValues) => void;
  loading: boolean;
}

const LoginForm: React.FC<IProps> = ({ onFinish, loading }) => {
  const [form] = Form.useForm();

  return (
    <Wrapper>
      <FormHeader>
        <img src={logo} alt='logo' />
        <h3>LOGIN</h3>
      </FormHeader>

      <FormWrapper>
        <Form layout='vertical' form={form} onFinish={onFinish}>
          <Form.Item
            label='Phone number'
            name='phoneNumber'
            rules={[
              { required: true, message: 'Please input your phone number!' },
            ]}
            hasFeedback
          >
            <Input autoComplete='username' />
          </Form.Item>

          <Form.Item
            label='Password'
            name='password'
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
              {
                min: 6,
                message: 'Password must be at least 6 characters!',
              },
            ]}
            hasFeedback
          >
            <Input.Password autoComplete='current-password' />
          </Form.Item>

          <Form.Item>
            <Button htmlType='submit' block loading={loading}>
              Login
            </Button>
          </Form.Item>
        </Form>
      </FormWrapper>
    </Wrapper>
  );
};

export default LoginForm;

import React, { useContext } from 'react';
import { Result, Button } from 'antd';
import { useHistory } from 'react-router';
import ROUTES from '@/constants/routes';
import { MenuContext } from '@/contexts/MenuContext';

import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotFoundPage: React.FC = () => {
  const { setBackToRoute } = useContext(MenuContext);

  const history = useHistory(),
    status = '404',
    title = '404',
    subTitle = 'Sorry, the page you visited does not exist.';

  return (
    <Wrapper>
      <Result
        status={status}
        title={title}
        subTitle={subTitle}
        extra={
          <Button
            type='primary'
            onClick={() => {
              setBackToRoute('');
              history.push(ROUTES.HOME_PAGE.path);
            }}
          >
            Back Home
          </Button>
        }
      />
    </Wrapper>
  );
};

export default NotFoundPage;

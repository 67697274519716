import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
  getNotificationsApi,
  getTotalUnreadApi,
  setReadNotificationApi,
} from '@/apis/notification';

import {
  IGetNotificationsRequest,
  IGetNotificationsResponse,
  IGetTotalUnreadRequest,
  IGetTotalUnreadResponse,
  ISetReadNotificationRequest,
  ISetReadNotificationResponse,
} from './type';

export const getNotifications = createAsyncThunk<
  IGetNotificationsResponse,
  IGetNotificationsRequest
>('notification/getNotifications', async (query, { rejectWithValue }) => {
  try {
    return await getNotificationsApi(query);
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const getTotalUnreadNotification = createAsyncThunk<
  IGetTotalUnreadResponse,
  IGetTotalUnreadRequest
>(
  'notification/getTotalUnreadNotification',
  async (body, { rejectWithValue }) => {
    try {
      return await getTotalUnreadApi();
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const setReadNotification = createAsyncThunk<
  ISetReadNotificationResponse,
  ISetReadNotificationRequest
>('notification/setReadNotification', async (params, { rejectWithValue }) => {
  try {
    return await setReadNotificationApi(params);
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

import React from 'react';
import { useHistory } from 'react-router-dom';
import { ActionButton } from '@/components';
import { PlusOutlined } from '@ant-design/icons';
import ROUTES from '@/constants/routes';

import { Wrapper } from './style';

const ActionButtons: React.FC = () => {
  const history = useHistory();

  return (
    <Wrapper>
      <ActionButton
        type='primary'
        icon={<PlusOutlined />}
        onClick={() => {
          history.push(ROUTES.ADD_SUPPLIER_PAGE.path);
        }}
      >
        Add new supplier
      </ActionButton>
    </Wrapper>
  );
};

export default ActionButtons;

import React from 'react';
import { Button, Form } from 'antd';

import { IOrderFormValues, OrderForm } from '@/components';
import { ISupplierState } from '@/stores/supplier/type';
import { IOrder } from '@/type';

interface IProps {
  onFinish: (values: IOrderFormValues) => void;
  onSearchProducts: (name: string) => void;
  onSetSelectedOrder: (values: Partial<IOrderFormValues>) => void;
  supplier: ISupplierState;
  order: IOrder;
  loading: boolean;
}

const OrderTab: React.FC<IProps> = ({
  supplier,
  order,
  loading,
  onFinish,
  onSearchProducts,
  onSetSelectedOrder,
}) => {
  const [form] = Form.useForm();

  return (
    <div style={{ marginTop: -20 }}>
      <OrderForm
        order={order}
        form={form}
        isUpdateForm={true}
        supplier={supplier}
        onFinish={onFinish}
        onSearchProducts={onSearchProducts}
        onSetSelectedOrder={onSetSelectedOrder}
      />

      <Form.Item>
        <Button type='primary' block onClick={form.submit} loading={loading}>
          Update
        </Button>
      </Form.Item>
    </div>
  );
};

export default OrderTab;

import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 40px;
  width: 100%;
  padding: 0 1.07rem;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.35rem;
  text-transform: capitalize;
  color: ${({ theme }) => theme.color.black};
`;

export const SetReadTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 20px;
  color: ${({ theme }) => theme.color.primary};
`;

export const TextButton = styled.button`
  border: none;
  background: none;
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  margin-left: 1rem;
  color: ${({ theme }) => theme.color.danger};
`;

export const ButtonContainer = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: flex-end;
`;

import React from 'react';
import formatCurrency from '@/utils/formatCurrency';

import { Wrapper } from './style';

interface IProps {
  count: number;
  totalAmount: number;
}

const Statistics: React.FC<IProps> = ({ count, totalAmount }) => {
  return (
    <Wrapper>
      <p>Orders: {count}</p>
      <p>Total amount: ${formatCurrency(totalAmount)} </p>
    </Wrapper>
  );
};

export default Statistics;

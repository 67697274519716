import React from 'react';
import { Modal, Form, Select } from 'antd';
import { IRole } from '@/type';
import { Description, FormContainer } from './style';

export interface IStaffPositionFormValues {
  role: string | undefined;
}

interface IProps {
  roles: Array<IRole>;
  visible: boolean;
  fullName: string;
  onCancel: () => void;
  onFinish: (value: IStaffPositionFormValues) => void;
  initialValues?: IStaffPositionFormValues;
}

const { Option } = Select;

const ChangeStaffPositionModal: React.FC<IProps> = ({
  visible,
  roles,
  fullName,
  onCancel,
  onFinish,
  initialValues,
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title='Change staff’s position'
      okText='Change position'
      cancelText='Close'
      onCancel={onCancel}
      onOk={form.submit}
    >
      <Description>
        You are changing the position for <strong>{`${fullName} `} </strong>
        account.
      </Description>

      <FormContainer>
        <Form
          layout='vertical'
          form={form}
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <Form.Item
            label='Position'
            name='role'
            rules={[
              {
                required: true,
                message: 'Please input position!',
              },
            ]}
          >
            <Select placeholder='Select position'>
              {roles.map((role, index) => (
                <Option value={role._id} key={index.toString()}>
                  {role.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </FormContainer>
    </Modal>
  );
};

export default ChangeStaffPositionModal;

import config from '@/configs';
import DELETE_STATUS from '@/constants/deleteStatus';
import { ORDER_TO_CLEANALL } from '@/constants/toastStatus';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { getOrderTrash, updateOrderTrash } from '@/stores/trash/actions';
import { IOrder } from '@/type';
import { unwrapResult } from '@reduxjs/toolkit';
import { Modal, Spin } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { toArray } from 'lodash';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ClearTrashButton, ListOfOrder } from './components';

const Trash: React.FC = () => {
  const dispatch = useAppDispatch();
  const { trash } = useAppSelector((state) => state);
  const { isFetching } = trash;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { limit } = config;
  const [initialSpinning, setInitialSpinning] = useState<boolean>(false);
  const ordersRef = useRef<HTMLDivElement>(null);
  const isShowButton = toArray(trash.data).length;
  const getOrdersTrash = useCallback(() => {
    dispatch(getOrderTrash({ limit, page: 1, deleteStatus: 'trash' }))
      .then(unwrapResult)
      .then(() => {
        setInitialSpinning(false);
        setCurrentPage(currentPage + 1);
      });
  }, [currentPage]);

  const handleUpdateOrderTrash = (
    orderId: string,
    deleteStatus: IOrder['deleteStatus'],
    toastMessage?: string,
  ): void => {
    dispatch(updateOrderTrash({ orderId, payload: { deleteStatus } }))
      .then(unwrapResult)
      .then(() => {
        toastMessage ? toast.success(toastMessage) : '';
      });
  };
  const restoreAll = () => {
    const mapRestorePromise = Object.values(trash.data).map((order) =>
      handleUpdateOrderTrash(order._id, DELETE_STATUS.DELETED),
    );
    Promise.allSettled(mapRestorePromise).then(() => {
      getOrdersTrash();
      toast.success(ORDER_TO_CLEANALL);
    });
  };

  const handleConfirmDeleteModal = () => {
    Modal.confirm({
      title: 'By clearing trash, orders will not be recoverable!',
      icon: <ExclamationCircleOutlined />,
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => {
        return restoreAll();
      },
    });
  };

  useEffect(() => {
    getOrdersTrash();
  }, []);
  return (
    <>
      {isShowButton ? (
        <ClearTrashButton onClick={handleConfirmDeleteModal} />
      ) : null}
      <Spin spinning={initialSpinning} style={{ marginTop: 20 }}>
        <ListOfOrder
          handleUpdateOrderTrash={handleUpdateOrderTrash}
          trash={trash}
          loading={isFetching}
          handleGetOrderTrash={getOrdersTrash}
          ordersRef={ordersRef}
          globalSpinning={initialSpinning}
        />
      </Spin>
    </>
  );
};
export default Trash;

import React, { useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { IUploadMultipleRequest } from '@/stores/upload/type';
import compressImage from '@/utils/compressImage';
import { Collapse, Empty } from 'antd';
import { useWindowSize } from '@/hooks';

import {
  Wrapper,
  Photo,
  DeleteIcon,
  CustomFileUpload,
  Container,
  EmptyContainer,
} from './style';

interface IProps {
  photos: string[];
  viewOnly?: boolean;
  loading?: boolean;
  onDelete?: (photo: string) => void;
  onUpload?: (formData: IUploadMultipleRequest) => void;
}

const OrderPhotos: React.FC<IProps> = ({
  photos,
  viewOnly = false,
  onDelete,
  loading,
  onUpload,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const { Panel } = Collapse;
  const size = useWindowSize();

  const handleDelete = (photo: string) => {
    if (onDelete && !viewOnly) {
      onDelete(photo);
    }
  };

  const selectFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (onUpload && fileList) {
      const compressPromises: Promise<File>[] = [];
      for (const file of Array.from(fileList)) {
        compressPromises.push(compressImage(file));
      }
      Promise.all(compressPromises).then((compressedFiles) => {
        const formData = new FormData();
        compressedFiles.map((file) => {
          formData.append('photo', file);
        });
        onUpload(formData);
      });
    }
  };

  const allPadding = 60;

  return (
    <Wrapper>
      <Collapse defaultActiveKey={['orderPhotos']} accordion>
        <Panel header='Order photos (Bills, Payments, etc.)' key='orderPhotos'>
          <Container>
            {photos.length ? (
              photos?.map((photo, index) => (
                <Photo key={index.toString()}>
                  <img
                    onClick={() => {
                      setIsOpen(true);
                      setPhotoIndex(index);
                    }}
                    src={photo}
                    alt={photo}
                  />

                  {!viewOnly && (
                    <DeleteIcon onClick={() => handleDelete(photo)} />
                  )}
                </Photo>
              ))
            ) : (
              <>
                {viewOnly && (
                  <EmptyContainer style={{ width: size.width - allPadding }}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </EmptyContainer>
                )}
              </>
            )}

            {!viewOnly && (
              <CustomFileUpload>
                <input
                  id='inputMultiple'
                  type='file'
                  multiple
                  onChange={selectFiles}
                  accept='image/x-png,image/gif,image/jpeg'
                  style={{ height: 150, widows: 150 }}
                />
                <label htmlFor='inputMultiple'>
                  {loading ? <LoadingOutlined /> : <PlusOutlined />}
                  <div style={{ marginLeft: '0.25rem' }}>Upload</div>
                </label>
              </CustomFileUpload>
            )}
          </Container>
        </Panel>
      </Collapse>

      {isOpen && photos.length && (
        <Lightbox
          mainSrc={photos[photoIndex]}
          nextSrc={photos[(photoIndex + 1) % photos.length]}
          prevSrc={photos[(photoIndex + photos.length - 1) % photos.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % photos.length)
          }
        />
      )}
    </Wrapper>
  );
};

export default OrderPhotos;

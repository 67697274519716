import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { uploadSingleApi, uploadMultipleApi } from '@/apis/upload';

import {
  IUploadSingleRequest,
  IUploadSingleResponse,
  IUploadMultipleRequest,
  IUploadMultipleResponse,
} from './type';

export const uploadSingle = createAsyncThunk<
  IUploadSingleResponse,
  IUploadSingleRequest
>('upload/uploadSingle', async (body, { rejectWithValue }) => {
  try {
    return await uploadSingleApi(body);
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

export const uploadMultiple = createAsyncThunk<
  IUploadMultipleResponse,
  IUploadMultipleRequest
>('upload/uploadMultiple', async (body, { rejectWithValue }) => {
  try {
    return await uploadMultipleApi(body);
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

import React, { useContext, useEffect, useState } from 'react';
import { MenuContext } from '@/contexts/MenuContext';
import ROUTES from '@/constants/routes';
import { RouteComponentProps } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { updateSupplier } from '@/stores/supplier/actions';
import { IUploadSingleRequest } from '@/stores/upload/type';
import { uploadSingle } from '@/stores/upload/actions';
import { IUpdateSupplierRequest } from '@/stores/supplier/type';
import { useHistory } from 'react-router-dom';
import { UPDATE } from '@/constants/submitText';
import { SupplierForm, ISupplierFormValues } from '@/components';
import { unwrapResult } from '@reduxjs/toolkit';
import { getSupplier } from '@/stores/supplier/actions';
import { Spin } from 'antd';

interface RouteParams {
  id: string;
}

interface MyComponent extends RouteComponentProps<RouteParams> {}

const UpdateSupplier: React.FC<MyComponent> = (props) => {
  const { setBackToRoute } = useContext(MenuContext);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { supplier, upload } = useAppSelector((state) => state);
  const [initialValues, setInitialValues] = useState<ISupplierFormValues>();
  const [cover, setCover] = useState<string>('');
  const { id } = props.match.params;

  useEffect(() => {
    setBackToRoute(ROUTES.SUPPLIERS_PAGE.path);

    dispatch(getSupplier({ _id: id }))
      .then(unwrapResult)
      .then(({ overDay, name }) => {
        setInitialValues({
          name,
          overDay,
        });
      });
  }, []);

  const handleUpdateSupplier = (values: ISupplierFormValues): void => {
    if (cover) values.cover = cover;

    const supplierCustom: IUpdateSupplierRequest = {
      supplierId: id,
      payload: values,
    };

    dispatch(updateSupplier(supplierCustom))
      .then(unwrapResult)
      .then(() => {
        history.push(ROUTES.SUPPLIERS_PAGE.path);
      });
  };

  const handleUploadSingle = (formData: IUploadSingleRequest) => {
    dispatch(uploadSingle(formData))
      .then(unwrapResult)
      .then(({ url }) => {
        setCover(url);
      });
  };

  return (
    <Spin spinning={supplier.isFetching}>
      <SupplierForm
        supplier={supplier}
        submitText={UPDATE}
        upload={upload}
        cover={cover || supplier.data[id]?.cover}
        onFinish={handleUpdateSupplier}
        initialValues={initialValues}
        handleUploadSingle={handleUploadSingle}
      />
    </Spin>
  );
};

export default UpdateSupplier;

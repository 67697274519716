import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import ROUTES from '@/constants/routes';
import { MainLayout } from '@/layouts';
import { useAppSelector } from '@/hooks';

interface IProps extends RouteProps {}

const PrivateRoute: React.FC<IProps> = ({ ...rest }) => {
  const { auth } = useAppSelector((state) => state);

  if (!auth.isAuthenticate) {
    return <Redirect to={ROUTES.LOGIN_PAGE.path} />;
  }

  return (
    <MainLayout>
      <Route {...rest} />
    </MainLayout>
  );
};

export default PrivateRoute;

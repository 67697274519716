import React from 'react';
import moment from 'moment';
import { ImageLightBox } from '@/components';
import avatarDefault from '@/assets/images/avatarDefault.png';
import ReactMarkdown from 'react-markdown';
import {
  Wrapper,
  LeftContainer,
  RightContainer,
  AvatarContainer,
  Avatar,
  MessageContainer,
  TimeText,
  ImageContainer,
  FullName,
} from './style';

interface IProps {
  isMe?: boolean;
  message?: string;
  isImage?: boolean;
  imageUrl?: string;
  cover?: string;
  createdAt: Date;
  fullNameOther?: string;
}

const MessageItem: React.FC<IProps> = ({
  isMe = false,
  message,
  isImage = false,
  imageUrl,
  cover,
  createdAt,
  fullNameOther,
}) => {
  return (
    <Wrapper isMe={isMe}>
      <LeftContainer hidden={isMe}>
        <AvatarContainer>
          <Avatar
            width={32}
            height={32}
            preview={false}
            src={cover || avatarDefault}
          />
        </AvatarContainer>
      </LeftContainer>
      <RightContainer isMe={isMe}>
        {!isMe && <FullName>{fullNameOther}</FullName>}
        {isImage ? (
          <ImageContainer>
            <ImageLightBox imageUrl={imageUrl || ''} />
          </ImageContainer>
        ) : (
          <MessageContainer isMe={isMe}>
            <ReactMarkdown>{message || ''}</ReactMarkdown>
          </MessageContainer>
        )}

        <TimeText isImage={isImage}>
          {moment(createdAt).format('HH:mm')}
        </TimeText>
      </RightContainer>
    </Wrapper>
  );
};

export default MessageItem;

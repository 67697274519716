import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.primary};
  width: 100%;
  height: 100%;
  padding: 2rem;
`;

export const FormHeader = styled.div`
  text-align: center;

  img {
    width: 150px;
    height: 150px;
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    color: ${({ theme }) => theme.color.secondary};
  }
`;

export const FormWrapper = styled.div`
  .ant-btn.ant-btn-block {
    background: ${({ theme }) => theme.color.primary};
    border-color: ${({ theme }) => theme.color.secondary};
    color: ${({ theme }) => theme.color.secondary};
  }

  .ant-col.ant-form-item-label label {
    color: ${({ theme }) => theme.color.secondary};
  }
`;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SendOutlined } from '@ant-design/icons';
import { IUploadSingleRequest } from '@/stores/upload/type';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { IUploadState } from '@/stores/upload/type';
import compressImage from '@/utils/compressImage';
import '@draft-js-plugins/mention/lib/plugin.css';
import { EditorState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import { convertToRaw } from 'draft-js';
import createMentionPlugin, { MentionData } from '@draft-js-plugins/mention';
import 'draft-js/dist/Draft.css';
import { EditorContainer } from './style';

import {
  UploadImage,
  Wrapper,
  UploadContainer,
  ButtonCustom,
  IconUploadImage,
  Container,
} from './style';

export interface ISelectedUser {
  id: string;
  fullName: string;
}

export interface IFormValues {
  content: string;
}

interface IProps {
  upload: IUploadState;
  isFetching: boolean;
  foundUser: MentionData[];
  onFinish: (values: IFormValues) => void;
  handleUploadSingle: (values: IUploadSingleRequest) => void;
  handleGetUsers: () => void;
  addSelectedUser: (user: ISelectedUser) => void;
}

const OrderChatForm: React.FC<IProps> = ({
  onFinish,
  upload,
  foundUser,
  handleUploadSingle,
  handleGetUsers,
  addSelectedUser,
}) => {
  const ref = useRef<Editor>(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(foundUser);

  useEffect(() => {
    handleGetUsers();
  }, []);

  useEffect(() => {
    setSuggestions(foundUser);
  }, [foundUser]);

  useEffect(() => {
    const handlePressEnter = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleSendMessage();
        ref.current!.focus();

        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handlePressEnter);
    return () => {
      document.removeEventListener('keydown', handlePressEnter);
    };
  }, [editorState]);

  const handleUpload = ({ file }: UploadRequestOption) => {
    if (file) {
      Promise.resolve(compressImage(file as File)).then((fileCustom) => {
        const formData = new FormData();
        formData.append('photo', fileCustom);
        handleUploadSingle(formData);
      });
    }
  };

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionComponent(mentionProps) {
        return (
          <span className={mentionProps.className}>
            @{mentionProps.children}
          </span>
        );
      },
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ trigger, value }: { trigger: string; value: string }) => {
      //handle search
    },
    [],
  );

  const handleSubmit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    handleSendMessage();
    event.preventDefault();
  };

  const handleSendMessage = () => {
    let currentContent = editorState.getCurrentContent().getPlainText();

    if (currentContent) {
      let temp = 0;

      const mentions = convertToRaw(editorState?.getCurrentContent())?.blocks[0]
        .entityRanges;

      for (const mention of mentions) {
        const currentIndex = mention.offset + temp;

        const currentValues = currentContent.substring(
          currentIndex,
          currentIndex + mention.length,
        );

        currentContent = `${currentContent.substring(
          0,
          currentIndex,
        )}**@${currentValues}**${currentContent.substring(
          currentIndex + mention.length,
        )}`;

        temp += 5;
      }

      onFinish({
        content: currentContent,
      });

      setEditorState(EditorState.createEmpty());
    }
  };

  return (
    <Wrapper>
      <Container>
        <EditorContainer
          onClick={() => {
            ref.current!.focus();
          }}
        >
          <Editor
            placeholder='Please enter a message'
            editorKey={'editor'}
            editorState={editorState}
            onChange={setEditorState}
            plugins={plugins}
            ref={ref}
          />
          <MentionSuggestions
            open={open}
            onOpenChange={onOpenChange}
            suggestions={suggestions}
            onSearchChange={onSearchChange}
            onAddMention={(e) => {
              if (e.id && e.name) {
                addSelectedUser({
                  id: e.id as string,
                  fullName: e.name,
                });
              }
            }}
          />
        </EditorContainer>

        <UploadImage
          showUploadList={false}
          accept='image/*'
          customRequest={handleUpload}
        >
          <UploadContainer>
            <IconUploadImage />
          </UploadContainer>
        </UploadImage>

        <ButtonCustom
          type='primary'
          block
          loading={upload.isUploadingSingle}
          onClick={handleSubmit}
        >
          {!upload.isUploadingSingle && <SendOutlined />}
        </ButtonCustom>
      </Container>
    </Wrapper>
  );
};

export default OrderChatForm;

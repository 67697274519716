import React from 'react';
import { RestOutlined } from '@ant-design/icons';
import { ButtonClear, Wrapper, WrapperTittle } from './style';
type Props = {
  onClick: () => void;
};
const ClearTrashButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Wrapper>
      <ButtonClear onClick={onClick}>
        <RestOutlined />
        <WrapperTittle>Clear Trash</WrapperTittle>
      </ButtonClear>
    </Wrapper>
  );
};
export default ClearTrashButton;

import styled from 'styled-components';

import { HEADER_HEIGHT } from '@/constants/componentSize';

export const NotificationWrapper = styled.div`
  position: fixed;
  top: 60px;
  left: 0px;
  z-index: 2;
  width: 100%;
  height: calc(100% - ${HEADER_HEIGHT}px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: none;
  background-color: ${({ theme }) => theme.color.secondary};
`;

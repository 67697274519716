import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import ROUTES from '@/constants/routes';

import { useAppDispatch, useAppSelector } from '@/hooks';
import { Header, Breadcrumb } from '@/components';
import {
  getNotifications,
  setReadNotification,
} from '@/stores/notification/actions';
import { getMessages, setReadMessage } from '@/stores/message/actions';
import configs from '@/configs';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  setMessageData,
  decreaseTotalUnreadMessage,
} from '@/stores/message/reducer';
import {
  setNotificationData,
  decreaseTotalUnreadNotification,
} from '@/stores/notification/reducer';
import { HEADER_HEIGHT } from '@/constants/componentSize';

interface IProps {
  children: React.ReactNode;
}

const Container = styled.div`
  position: absolute;
  top: ${HEADER_HEIGHT}px;
  left: 0;
  width: 100%;
  z-index: 1;
`;

const MainLayout: React.FC<IProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [isShowBreadcrumb, setIsShowBreadcrumb] = useState<boolean>(false);
  const [notificationCurrentPage, setNotificationCurrentPage] =
    useState<number>(1);
  const [messageCurrentPage, setMessageCurrentPage] = useState<number>(1);
  const [unreadMessage, setUnreadMessage] = useState<boolean>(false);
  const [unreadNotification, setUnreadNotification] = useState<boolean>(false);
  const { limit } = configs;
  const { notification, message } = useAppSelector((state) => state);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === ROUTES.HOME_PAGE.path) {
      setIsShowBreadcrumb(false);
    } else {
      setIsShowBreadcrumb(true);
    }
  }, [location.pathname]);

  const handleGetNotifications = (): void => {
    dispatch(getNotifications({ limit, page: 1 }));
    setNotificationCurrentPage(2);
  };

  const handleGetMessages = (): void => {
    dispatch(getMessages({ limit, page: 1 }));
    setMessageCurrentPage(2);
  };

  const fetchMoreNotificationsData = (): void => {
    if (unreadNotification) {
      dispatch(
        getNotifications({
          limit,
          page: notificationCurrentPage,
          unread: unreadNotification,
        }),
      );
    }
    if (!unreadNotification)
      dispatch(getNotifications({ limit, page: notificationCurrentPage }));

    setNotificationCurrentPage(notificationCurrentPage + 1);
  };

  const fetchMoreMessagesData = (): void => {
    if (unreadMessage) {
      dispatch(
        getMessages({ limit, page: messageCurrentPage, unread: unreadMessage }),
      );
    }
    if (!unreadMessage)
      dispatch(getMessages({ limit, page: messageCurrentPage }));

    setMessageCurrentPage(messageCurrentPage + 1);
  };

  const handleViewUnreadMessages = (): void => {
    dispatch(getMessages({ limit, page: 1, unread: true }))
      .then(unwrapResult)
      .then((res) => {
        dispatch(setMessageData(res));
      });

    setUnreadMessage(true);
    setMessageCurrentPage(2);
  };

  const handleViewAllMessages = (): void => {
    dispatch(getMessages({ limit, page: 1 }))
      .then(unwrapResult)
      .then((res) => {
        dispatch(setMessageData(res));
      });
    setUnreadMessage(false);
    setMessageCurrentPage(2);
  };

  const handleViewAllNotifications = (): void => {
    dispatch(getNotifications({ limit, page: 1 }))
      .then(unwrapResult)
      .then((res) => {
        dispatch(setNotificationData(res));
      });
    setUnreadNotification(false);
    setMessageCurrentPage(2);
  };

  const handleViewUnreadNotifications = (): void => {
    dispatch(getNotifications({ limit, page: 1, unread: true }))
      .then(unwrapResult)
      .then((res) => {
        dispatch(setNotificationData(res));
      });
    setUnreadNotification(true);
    setMessageCurrentPage(2);
  };

  const handleSetReadMessage = (_id: string): void => {
    dispatch(setReadMessage({ _id }))
      .then(unwrapResult)
      .then(() => dispatch(decreaseTotalUnreadMessage()));
  };

  const handleSetReadNotification = (_id: string): void => {
    dispatch(setReadNotification({ _id }))
      .then(unwrapResult)
      .then(() => dispatch(decreaseTotalUnreadNotification()));
  };

  return (
    <div>
      <Header
        notification={notification}
        message={message}
        fetchMoreNotificationsData={fetchMoreNotificationsData}
        fetchMoreMessagesData={fetchMoreMessagesData}
        handleViewUnreadMessages={handleViewUnreadMessages}
        handleViewAllMessages={handleViewAllMessages}
        handleSetReadMessage={handleSetReadMessage}
        handleGetNotifications={handleGetNotifications}
        handleGetMessages={handleGetMessages}
        handleSetReadNotification={handleSetReadNotification}
        handleViewAllNotifications={handleViewAllNotifications}
        handleViewUnreadNotifications={handleViewUnreadNotifications}
      />

      <Container>
        {isShowBreadcrumb && <Breadcrumb />}
        {children}
      </Container>
    </div>
  );
};

export default MainLayout;

import React, { useRef, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spin, Divider, Empty } from 'antd';
import moment from 'moment';
import { useWindowSize } from '@/hooks';

import { IOrderState } from '@/stores/order/type';
import { IUser } from '@/type';
import MESSAGE_TYPES from '@/constants/messageTypes';
import {
  HEADER_HEIGHT,
  MESSAGE_FORM_HEIGHT,
  ORDER_TAB_HEIGHT,
  BREADCRUMB_HEIGHT,
} from '@/constants/componentSize';

import { MessageItem } from '../../components';
import { NotificationItem, EmptyContainer } from './style';

interface IProps {
  order: IOrderState;
  user: Partial<IUser>;
  fetchMoreMessagesData: () => void;
}

const Messages: React.FC<IProps> = ({ order, user, fetchMoreMessagesData }) => {
  const size = useWindowSize();

  const checkWithResponsive = (componentSize: number): number => {
    if (size.width > 480) {
      return componentSize + 16;
    }
    return componentSize;
  };

  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (
      messagesEndRef &&
      messagesEndRef.current &&
      order.messages.data.length
    ) {
      (messagesEndRef.current as HTMLElement | null)?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [order.messages.data]);

  return (
    <InfiniteScroll
      dataLength={order.messages.data.length}
      next={fetchMoreMessagesData}
      hasMore={order.messages.hasMore}
      style={{
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column-reverse',
        paddingBottom: '1.3rem',
      }}
      inverse={true}
      height={
        size.height -
        HEADER_HEIGHT -
        ORDER_TAB_HEIGHT -
        BREADCRUMB_HEIGHT -
        checkWithResponsive(MESSAGE_FORM_HEIGHT)
      }
      loader={
        <Spin
          style={{ width: '100%', marginTop: 20 }}
          spinning={order.messages.isFetching}
        />
      }
    >
      <div ref={messagesEndRef} />
      {order.messages.data.length ? (
        order.messages.data.map((message, index) => {
          const lastMessageIndex = order.messages.data.length - 1;
          const currentMessageDate = moment(message.createdAt).format(
            'YYYY-MM-DD',
          );
          const nextMessageDate =
            index < lastMessageIndex
              ? moment(order.messages.data[index + 1].createdAt).format(
                  'YYYY-MM-DD',
                )
              : moment().format('YYYY-MM-DD');

          const isToday = !moment().diff(currentMessageDate, 'day');
          const isDiffDate = !!moment(currentMessageDate).diff(
            nextMessageDate,
            'day',
          );

          let DateView = () => <div />;

          if (index === lastMessageIndex || isDiffDate) {
            DateView = () => (
              <Divider plain>
                {isToday
                  ? 'Today'
                  : moment(message.createdAt).format('MMMM Do YYYY')}
              </Divider>
            );
          }
          if (message.type === MESSAGE_TYPES.IMAGE && !message.isSystem) {
            return (
              <div key={index}>
                <DateView />
                <MessageItem
                  createdAt={message.createdAt}
                  cover={message.user.cover}
                  isImage={true}
                  imageUrl={message.content}
                  isMe={user._id === message.user._id}
                  fullNameOther={message.user.fullName}
                />
              </div>
            );
          }

          if (message.type === MESSAGE_TYPES.TEXT && !message.isSystem) {
            return (
              <div key={index}>
                <DateView />
                <MessageItem
                  createdAt={message.createdAt}
                  cover={message.user.cover}
                  message={message.content}
                  isMe={user._id === message.user._id}
                  fullNameOther={message.user.fullName}
                />
              </div>
            );
          }

          if (message.type === MESSAGE_TYPES.IMAGE && !message.isSystem) {
            return (
              <div key={index}>
                <DateView />
                <MessageItem
                  createdAt={message.createdAt}
                  cover={message.user.cover}
                  isImage={true}
                  imageUrl={message.content}
                  isMe={user._id === message.user._id}
                  fullNameOther={message.user.fullName}
                />
              </div>
            );
          }

          if (message.isSystem) {
            return (
              <div key={index}>
                <DateView />
                <NotificationItem key={index}>
                  <strong>Victoria</strong> đã đổi trạng thái đơn sang
                  <strong> Hoàn Thành</strong> lúc{' '}
                  {moment(message.createdAt).format('HH:mm')}
                </NotificationItem>
              </div>
            );
          }

          return null;
        })
      ) : (
        <EmptyContainer image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </InfiniteScroll>
  );
};

export default Messages;

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useAppSelector } from '@/hooks';
import ROUTES from '@/constants/routes';

import PrivateRoute from './PrivateRoute';

const Routes: React.FC = () => {
  const { auth } = useAppSelector((state) => state);
  const roleName = auth.user.role?.name;

  return (
    <Router>
      <Switch>
        {Object.values(ROUTES).map((route, index) => {
          if (route.isPrivate) {
            const isMatchRole = route.roles.find((role) => role === roleName);
            if (isMatchRole || !route.roles.length)
              return (
                <PrivateRoute
                  path={route.path}
                  exact
                  component={route.component}
                  key={index.toString()}
                />
              );
          }

          if (!route.isPrivate)
            return (
              <Route
                path={route.path}
                exact
                component={route.component}
                key={index.toString()}
              />
            );
        })}
      </Switch>
    </Router>
  );
};

export default Routes;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOrdersApi, updateOrderApi } from '@/apis/order';
import { toast } from 'react-toastify';

import {
  IGetOrdersRequest,
  IGetOrdersResponse,
  IUpdateOrderRequest,
  IUpdateOrderResponse,
} from './type';

export const getOrderTrash = createAsyncThunk<
  IGetOrdersResponse,
  IGetOrdersRequest
>('trash/getOrderTrash', async (body, { rejectWithValue }) => {
  try {
    return await getOrdersApi(body);
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const updateOrderTrash = createAsyncThunk<
  IUpdateOrderResponse,
  IUpdateOrderRequest
>('trash/updateOrderTrash', async (body, { rejectWithValue }) => {
  try {
    return await updateOrderApi(body);
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

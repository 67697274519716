import styled from 'styled-components';
import { Image, Menu } from 'antd';

import { EllipsisOutlined } from '@ant-design/icons';

export const Wrapper = styled.div`
  height: 74px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.color.lightBorder};
  padding: 1.45rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Avatar = styled(Image)`
  height: 42px;
  width: 42px;
  border-radius: 50%;
`;

export const LeftContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const RightContainer = styled.div``;

export const InformationContainer = styled.div`
  margin-left: 1.14rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const NameTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.43rem;
`;

export const OrderTitle = styled.div`
  width: 50%;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.43rem;
  color: ${({ theme }) => theme.color.grayBold};
`;

export const DayTitle = styled.div`
  width: 50%;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.43rem;
  color: ${({ theme }) => theme.color.grayBold};
`;

export const DropdownContainer = styled.a`
  display: flex;
  align-items: center;
`;

export const MenuContainer = styled(Menu)`
  position: absolute;
  width: 136px;
  top: 1.15rem;
  right: -1.35rem;
  z-index: 5;
`;

export const ItemText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
`;

export const IconMenu = styled(EllipsisOutlined)`
  font-size: 2.4rem;
  color: ${({ theme }) => theme.color.black};
`;

export const BlockText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.color.danger};
`;

export const BottomInformationContainer = styled.div`
  display: flex;
  height: 1.45rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

import apiRequest from '@/utils/apiRequest';
import {
  IGetNotificationsRequest,
  IGetNotificationsResponse,
  IGetTotalUnreadResponse,
  ISetReadNotificationRequest,
  ISetReadNotificationResponse,
} from '@/stores/notification/type';

const endpoint = '/notifications';

export const getNotificationsApi = (
  query: IGetNotificationsRequest,
): Promise<IGetNotificationsResponse> => {
  return apiRequest.get(endpoint, { params: query });
};

export const getTotalUnreadApi = (): Promise<IGetTotalUnreadResponse> => {
  return apiRequest.get(`${endpoint}/total-unread`);
};

export const setReadNotificationApi = (
  params: ISetReadNotificationRequest,
): Promise<ISetReadNotificationResponse> => {
  return apiRequest.put(`${endpoint}/${params._id}`);
};

import React, { useContext, useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { MenuContext } from '@/contexts/MenuContext';
import ROUTES from '@/constants/routes';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { createUser } from '@/stores/user/actions';
import { uploadSingle } from '@/stores/upload/actions';
import { useHistory } from 'react-router-dom';
import { ICreateUserRequest } from '@/stores/user/type';
import { IUploadSingleRequest } from '@/stores/upload/type';
import { AddEmployeeForm, IFormValues } from './components';

const NewEmployee: React.FC = () => {
  const { setBackToRoute } = useContext(MenuContext);
  const dispatch = useAppDispatch();
  const { role, user, upload } = useAppSelector((state) => state);

  const history = useHistory();
  const [cover, setCover] = useState<string>('');

  useEffect(() => {
    setBackToRoute(ROUTES.EMPLOYEES_PAGE.path);
  }, []);

  const handleAddEmployee = (values: IFormValues) => {
    const { address, phoneNumber, password, fullName, role } = values;

    const userCustom: ICreateUserRequest = {
      address,
      phoneNumber,
      password,
      fullName,
      role,
    };

    if (cover) userCustom.cover = cover;

    dispatch(createUser(userCustom))
      .then(unwrapResult)
      .then(() => {
        history.push(ROUTES.EMPLOYEES_PAGE.path);
      });
  };

  const handleUploadSingle = (formData: IUploadSingleRequest) => {
    dispatch(uploadSingle(formData))
      .then(unwrapResult)
      .then(({ url }) => {
        setCover(url);
      });
  };

  return (
    <AddEmployeeForm
      roles={role.data}
      cover={cover}
      upload={upload}
      handleUploadSingle={handleUploadSingle}
      isSubmitting={user.isSubmitting}
      onFinish={handleAddEmployee}
    />
  );
};

export default NewEmployee;

import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { OrderLayout } from '@/layouts';
import { OrderCard, OrderPhotos } from '@/components';
import ROUTES from '@/constants/routes';
import { unwrapResult } from '@reduxjs/toolkit';
import replaceRoutePath from '@/utils/replaceRoutePath';
import { useAppSelector, useAppDispatch } from '@/hooks';
import POSITIONS from '@/constants/positions';
import { updateOrder } from '@/stores/order/actions';
import { IOrder } from '@/type';
import {
  SUBMIT_REQUEST_DELETE_ORDER_SUCCESS,
  ADD_ORDER_TO_TRASH,
} from '@/constants/toastStatus';
import { MenuContext } from '@/contexts/MenuContext';

import { Owner, ActionBottom, Notes } from './components';
import { BottomContainer, Date } from './style';

const OrderInformation: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const { role, order, auth } = useAppSelector((state) => state);
  const roleName = auth.user.role?.name;
  const allowedSee =
    roleName === POSITIONS.ADMIN || roleName === POSITIONS.MANAGER;
  const { setBackToRoute } = useContext(MenuContext);

  const currentOrder = order.data[params.id] || {};

  const handleClickUpdateButton = () => {
    history.push(
      replaceRoutePath({
        originalPath: ROUTES.UPDATE_ORDER_PAGE.path,
        replacementValue: params.id,
      }),
    );
  };
  const handleUpdateOrder = (
    orderId: string,
    deleteStatus: IOrder['deleteStatus'],
  ): void => {
    dispatch(updateOrder({ orderId, payload: { deleteStatus } }))
      .then(unwrapResult)
      .then(() => {
        setBackToRoute('');
        history.push(ROUTES.HOME_PAGE.path);
        if (allowedSee) {
          toast.success(ADD_ORDER_TO_TRASH);
        }
        if (!allowedSee) {
          toast.success(SUBMIT_REQUEST_DELETE_ORDER_SUCCESS);
        }
      });
  };

  return (
    <OrderLayout>
      <OrderCard
        order={currentOrder}
        allowedSee={allowedSee}
        onUpdateOrder={handleUpdateOrder}
      />
      <div style={{ padding: '0 1rem' }}>
        <OrderPhotos photos={currentOrder?.photos || []} viewOnly />
      </div>

      <Owner user={currentOrder.user} roles={role.data} />

      <Notes notes={currentOrder.notes} />

      <BottomContainer>
        <div>
          <strong>Order date</strong>
          <p>{moment(currentOrder.orderDate).format('DD/MM/YYYY')}</p>
        </div>

        <Date>
          <strong>Order creation date</strong>
          <p>{moment(currentOrder.createdAt).format('DD/MM/YYYY')}</p>
        </Date>
      </BottomContainer>

      <ActionBottom onClick={handleClickUpdateButton} />
    </OrderLayout>
  );
};

export default OrderInformation;

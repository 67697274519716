import React, { useState } from 'react';
import { Image } from 'antd';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

interface IProps {
  imageUrl: string;
}

const ImageLightBox: React.FC<IProps> = ({ imageUrl }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Image
        src={imageUrl}
        preview={false}
        onClick={() => {
          setIsOpen(true);
        }}
      />
      {isOpen && (
        <Lightbox mainSrc={imageUrl} onCloseRequest={() => setIsOpen(false)} />
      )}
    </>
  );
};

export default ImageLightBox;

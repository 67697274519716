import React, { useState } from 'react';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { Image } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import compressImage from '@/utils/compressImage';
import { CropImage } from '@/components';
import { RcFile } from 'antd/lib/upload';
import AvatarEditor from 'react-avatar-editor';

import { UploadCoverContainer } from './style';

interface IProps {
  cover?: string;
  loading: boolean;
  onUpload: (formData: FormData) => void;
}

export interface IFile {
  name: string;
}

const UploadCover: React.FC<IProps> = ({ cover, loading, onUpload }) => {
  const [isShowCropImage, setIsShowCropImage] = useState<boolean>(false);
  const [image, setImage] = useState<RcFile>();
  const [editor, setEditor] = useState<AvatarEditor>();

  const handleUpload = ({ file }: UploadRequestOption) => {
    if (file) {
      setImage(file as RcFile);
      setIsShowCropImage(true);
    }
  };

  const setEditorRef = (ed: AvatarEditor) => {
    setEditor(ed);
  };

  const handleDataUrlToFile = async (
    dataUrl: string,
    fileName: string,
  ): Promise<File> => {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
  };

  const handleCropImageSuccess = async () => {
    if (editor && image) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      const newFile = await handleDataUrlToFile(croppedImg, image.name);
      Promise.resolve(compressImage(newFile as File)).then((fileCustom) => {
        const formData = new FormData();
        formData.append('photo', fileCustom);
        onUpload(formData);
      });
      setIsShowCropImage(false);
    }
  };

  return (
    <>
      <UploadCoverContainer
        listType='picture-card'
        showUploadList={false}
        accept='image/*'
        customRequest={handleUpload}
      >
        {cover && !loading ? (
          <Image src={cover} preview={false} />
        ) : (
          <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Cover</div>
          </div>
        )}
      </UploadCoverContainer>

      <CropImage
        image={image}
        visible={isShowCropImage}
        onCancel={() => setIsShowCropImage(false)}
        onFinish={handleCropImageSuccess}
        setEditorRef={setEditorRef}
      />
    </>
  );
};

export default UploadCover;

import { DeleteOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 74px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.color.lightBorder};
  padding: 1.45rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Avatar = styled(Image)`
  height: 42px;
  width: 42px;
  border-radius: 50%;
`;

export const LeftContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const RightContainer = styled.div`
  margin-left: 0.5rem;
`;

export const InformationContainer = styled.div`
  margin-left: 1.14rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const NameTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.43rem;
`;

export const SupplierTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.color.grayBold};
`;

export const DeleteIcon = styled(DeleteOutlined)`
  background: ${({ theme }) => theme.color.danger};
  color: ${({ theme }) => theme.color.secondary};
  padding: 0.5rem;
  border-radius: 0.2rem;
`;

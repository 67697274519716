import {
  AccountPage,
  ChangePasswordPage,
  HomePage,
  LoginPage,
  EmployeesPage,
  AddEmployeePage,
  SuppliersPage,
  AddSupplierPage,
  UpdateSupplierPage,
  OrderInformationPage,
  OrderChatPage,
  OrderActivityPage,
  AddOrderPage,
  UpdateOrderPage,
  OrderManagement,
  ProductPage,
  NotFoundPage,
  TrashPage,
} from '@/pages';
import POSITIONS from '@/constants/positions';

import {
  DynamicSupplierBreadcrumb,
  DynamicOrderBreadcrumb,
} from '@/components';

const employeesPath = '/employees';
const suppliersPath = '/suppliers';
const ordersPath = '/:id';
export const informationPath = '/information';
const productsPath = '/products';

const ROUTES = {
  LOGIN_PAGE: {
    path: '/login',
    breadcrumb: '',
    component: LoginPage,
    isPrivate: false,
    roles: [],
  },
  HOME_PAGE: {
    path: '/',
    breadcrumb: 'Home',
    component: HomePage,
    isPrivate: true,
    roles: [],
  },
  ACCOUNT_PAGE: {
    path: '/account',
    breadcrumb: 'Account',
    component: AccountPage,
    isPrivate: true,
    roles: [],
  },
  TRASH_PAGE: {
    path: '/trash',
    breadcrumb: 'Trash',
    component: TrashPage,
    isPrivate: true,
    roles: [],
  },
  CHANGE_PASSWORD_PAGE: {
    path: '/change-password',
    breadcrumb: 'Change password',
    component: ChangePasswordPage,
    isPrivate: true,
    roles: [],
  },
  PRODUCT_PAGE: {
    path: productsPath,
    breadcrumb: 'Products',
    component: ProductPage,
    isPrivate: true,
    roles: [POSITIONS.ADMIN, POSITIONS.MANAGER],
  },
  EMPLOYEES_PAGE: {
    path: employeesPath,
    breadcrumb: 'Employees',
    component: EmployeesPage,
    isPrivate: true,
    roles: [POSITIONS.ADMIN, POSITIONS.MANAGER],
  },
  ADD_EMPLOYEE_PAGE: {
    path: `${employeesPath}/add`,
    breadcrumb: 'Add new employee',
    component: AddEmployeePage,
    isPrivate: true,
    roles: [POSITIONS.ADMIN, POSITIONS.MANAGER],
  },
  ADD_ORDER_PAGE: {
    path: '/add-order',
    breadcrumb: 'Create new order',
    component: AddOrderPage,
    isPrivate: true,
    roles: [],
  },
  ORDER_MANAGEMENT_PAGE: {
    path: '/order-management',
    breadcrumb: 'Order management',
    component: OrderManagement,
    isPrivate: true,
    roles: [POSITIONS.ADMIN, POSITIONS.MANAGER],
  },
  SUPPLIERS_PAGE: {
    path: suppliersPath,
    breadcrumb: 'Suppliers',
    component: SuppliersPage,
    isPrivate: true,
    roles: [POSITIONS.ADMIN, POSITIONS.MANAGER],
  },
  ADD_SUPPLIER_PAGE: {
    path: `${suppliersPath}/add`,
    breadcrumb: 'Add new supplier',
    component: AddSupplierPage,
    isPrivate: true,
    roles: [POSITIONS.ADMIN, POSITIONS.MANAGER],
  },
  SUPPLIER_PAGE: {
    path: `${suppliersPath}/:id`,
    breadcrumb: DynamicSupplierBreadcrumb,
    component: UpdateSupplierPage,
    isPrivate: true,
    roles: [POSITIONS.ADMIN, POSITIONS.MANAGER],
  },
  UPDATE_SUPPLIER_PAGE: {
    path: `${suppliersPath}/:id/update`,
    breadcrumb: 'Update',
    component: UpdateSupplierPage,
    isPrivate: true,
    roles: [POSITIONS.ADMIN, POSITIONS.MANAGER],
  },
  ORDER_PAGE: {
    path: ordersPath,
    breadcrumb: DynamicOrderBreadcrumb,
    component: OrderInformationPage,
    isPrivate: true,
    roles: [],
  },
  ORDER_INFORMATION_PAGE: {
    path: `${ordersPath}${informationPath}`,
    breadcrumb: 'Information',
    component: OrderInformationPage,
    isPrivate: true,
    roles: [],
  },
  ORDER_CHAT_PAGE: {
    path: `${ordersPath}/chat`,
    breadcrumb: 'Chat',
    component: OrderChatPage,
    isPrivate: true,
    roles: [],
  },
  ORDER_ACTIVITY_PAGE: {
    path: `${ordersPath}/activity-history`,
    breadcrumb: 'Activity history',
    component: OrderActivityPage,
    isPrivate: true,
    roles: [],
  },
  UPDATE_ORDER_PAGE: {
    path: `${ordersPath}/update`,
    breadcrumb: 'Update order',
    component: UpdateOrderPage,
    isPrivate: true,
    roles: [],
  },
  NOT_FOUND_PAGE: {
    path: '*',
    breadcrumb: '',
    component: NotFoundPage,
    isPrivate: false,
    roles: [],
  },
};

export default ROUTES;

import React from 'react';
import { OrderItemPhotos } from '@/components';
import { IOrderItem } from '@/type';
import { IUploadMultipleRequest } from '@/stores/upload/type';
import { OrderPhotos } from '@/components';

interface IProps {
  orderItems: IOrderItem[];
  isUploadPhotos: boolean;
  photos: string[];
  isUploadProductPhotos: boolean;
  onUpload: (productName: string, formData: FormData) => void;
  onDeleteOrderPhoto: (photo: string) => void;
  onUploadOrderPhotos: (formData: IUploadMultipleRequest) => void;
}

const PhotosTab: React.FC<IProps> = ({
  orderItems,
  isUploadPhotos,
  photos,
  isUploadProductPhotos,
  onUpload,
  onDeleteOrderPhoto,
  onUploadOrderPhotos,
}) => {
  return (
    <>
      <OrderItemPhotos
        orderItems={orderItems}
        onUploadCover={onUpload}
        loading={isUploadProductPhotos}
      />
      <OrderPhotos
        loading={isUploadPhotos}
        photos={photos}
        onUpload={onUploadOrderPhotos}
        onDelete={onDeleteOrderPhoto}
      />
    </>
  );
};

export default PhotosTab;

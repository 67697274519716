import React from 'react';
import _ from 'lodash';
import { Form, Input, Button } from 'antd';
import { Wrapper } from './style';

interface IFormValues {
  oldPassword: string;
  password: string;
  confirmNewPassword: string;
}

export interface IFinishForm {
  values: Pick<IFormValues, 'oldPassword' | 'password'>;
  resetFields: () => void;
}

interface IProps {
  onFinish: (props: IFinishForm) => void;
  loading: boolean;
}

const LoginForm: React.FC<IProps> = ({ loading, onFinish }) => {
  const [form] = Form.useForm();

  const handleFinish = (values: IFormValues) => {
    const valuesPicked = _.pick(values, ['oldPassword', 'password']);
    onFinish({
      values: valuesPicked,
      resetFields: form.resetFields,
    });
  };

  return (
    <Wrapper>
      <Form layout='vertical' form={form} onFinish={handleFinish}>
        <Form.Item
          label='Old password'
          name='oldPassword'
          rules={[
            {
              required: true,
              message: 'Please input your old password!',
            },
            {
              min: 6,
              message: 'Password must be at least 6 characters!',
            },
          ]}
          hasFeedback
        >
          <Input.Password autoComplete='current-password' />
        </Form.Item>

        <Form.Item
          label='New password'
          name='password'
          rules={[
            {
              required: true,
              message: 'Please input your new password!',
            },
            {
              min: 6,
              message: 'Password must be at least 6 characters!',
            },
          ]}
          hasFeedback
        >
          <Input.Password autoComplete='new-password' />
        </Form.Item>

        <Form.Item
          label='Confirm new password'
          name='confirmNewPassword'
          rules={[
            {
              required: true,
              message: 'Please input confirm new password!',
            },
            {
              min: 6,
              message: 'Password must be at least 6 characters!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  'The two passwords that you entered do not match!',
                );
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password autoComplete='new-password' />
        </Form.Item>

        <Form.Item>
          <Button htmlType='submit' type='primary' block loading={loading}>
            Change password
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

export default LoginForm;

import apiRequest from '@/utils/apiRequest';
import {
  IGetSuppliersRequest,
  IGetSuppliersResponse,
  ICreateSupplierRequest,
  ICreateSupplierResponse,
  IUpdateSupplierRequest,
  IUpdateSupplierResponse,
  IDeleteSupplierRequest,
  IDeleteSupplierResponse,
  IGetSupplierRequest,
  IGetSupplierResponse,
} from '@/stores/supplier/type';

const endpoint = '/suppliers';

export const getSuppliersApi = (
  query: IGetSuppliersRequest,
): Promise<IGetSuppliersResponse> => {
  return apiRequest.get(endpoint, { params: query });
};

export const createSupplierApi = (
  body: ICreateSupplierRequest,
): Promise<ICreateSupplierResponse> => {
  return apiRequest.post(endpoint, body);
};

export const updateSupplierApi = (
  body: IUpdateSupplierRequest,
): Promise<IUpdateSupplierResponse> => {
  const { supplierId, payload } = body;
  return apiRequest.put(`${endpoint}/${supplierId}`, payload);
};

export const deleteSupplierApi = (
  params: IDeleteSupplierRequest,
): Promise<IDeleteSupplierResponse> => {
  return apiRequest.delete(`${endpoint}/${params._id}`);
};

export const getSupplierApi = (
  params: IGetSupplierRequest,
): Promise<IGetSupplierResponse> => {
  return apiRequest.get(`${endpoint}/${params._id}`);
};

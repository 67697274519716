/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { Form, Modal, Select, DatePicker, Input, Button } from 'antd';
import moment, { Moment } from 'moment';

import ORDER_STATUS, { ALL_STATUS } from '@/constants/orderStatus';
import { ALL_COUNTRIES, ALL_SUPPLIERS } from '@/constants/countries';

import { useAppSelector } from '@/hooks';
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter';
import { useQuery } from '@/hooks/useQuery';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface IFormValues {
  productName: string;
  customerName: string;
  country: string;
  status: string;
  orderDate: Date[];
  phoneNumber: string;
  supplier: string;
}

export interface IFinishFilterOrderForm {
  values: IFormValues;
  closeModal: () => void;
}

interface IProps {
  visible: boolean;
  loading: boolean;
  onFinish: (payload: IFinishFilterOrderForm) => void;
  onCancel: () => void;
  onClearAll: () => void;
}

const FilterModal: React.FC<IProps> = ({
  visible,
  loading,
  onFinish,
  onCancel,
  onClearAll,
}) => {
  const [form] = Form.useForm();
  const {
    country: { data },
    supplier,
  } = useAppSelector((state) => state);
  const query = useQuery();

  const initialValues = {
    country: 'all',
    status: 'all',
    supplier: 'all',
  };

  const disabledDate = (current: Moment) => {
    return current && current > moment().endOf('day');
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      onFinish({
        values,
        closeModal: onCancel,
      });
    });
  };

  useEffect(() => {
    const fields = [
      'customerName',
      'country',
      'productName',
      'phoneNumber',
      'status',
      'supplier',
    ];
    for (const field of fields) {
      const value = query.get(field);
      if (value !== null) {
        form.setFields([{ name: field, value }]);
      }
    }
    const fromDate = query.get('fromDate');
    const toDate = query.get('toDate');
    if (fromDate || toDate) {
      const orderDate = [
        fromDate ? moment(fromDate) : null,
        toDate ? moment(toDate) : null,
      ];
      form.setFields([{ name: 'orderDate', value: orderDate }]);
    }
  }, []);

  return (
    <Modal
      title='Filter order'
      visible={visible}
      centered
      onOk={handleOk}
      onCancel={onCancel}
      cancelText='Close'
      okText='Filter'
      okButtonProps={{ loading }}
    >
      <Form layout='vertical' form={form} initialValues={initialValues}>
        <Form.Item label='Product name' name='productName' hasFeedback>
          <Input />
        </Form.Item>
        <Form.Item label='Recipient’s name' name='customerName' hasFeedback>
          <Input />
        </Form.Item>
        <Form.Item label='Phone number' name='phoneNumber' hasFeedback>
          <Input />
        </Form.Item>
        <Form.Item label='Country' name='country'>
          <Select>
            <Option value='all'>{capitalizeFirstLetter(ALL_COUNTRIES)}</Option>
            {data.map(({ _id, name }) => (
              <Option key={_id} value={_id}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='Order status' name='status'>
          <Select>
            <Option value='all'>{capitalizeFirstLetter(ALL_STATUS)}</Option>
            {Object.values(ORDER_STATUS).map((value, index) => (
              <Option key={index.toString()} value={value}>
                {capitalizeFirstLetter(value)}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label='Supplier' name='supplier'>
          <Select showSearch optionFilterProp='name'>
            <Option value='all' name='All suppliers'>
              {capitalizeFirstLetter(ALL_SUPPLIERS)}
            </Option>
            {Object.values(supplier.data).map(({ _id, name }) => (
              <Option key={_id} value={_id} name={name}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label='Order date' name='orderDate'>
          <RangePicker style={{ width: '100%' }} disabledDate={disabledDate} />
        </Form.Item>

        <Button type='link' danger onClick={onClearAll}>
          Clear all filters
        </Button>
      </Form>
    </Modal>
  );
};

export default FilterModal;

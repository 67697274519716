import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { unwrapResult } from '@reduxjs/toolkit';
import { Steps, Form } from 'antd';
import { toast } from 'react-toastify';
import { COVER_ORDER_EMPTY } from '@/constants/toastStatus';

import ROUTES from '@/constants/routes';
import { MenuContext } from '@/contexts/MenuContext';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { getSuppliers } from '@/stores/supplier/actions';
import { createOrder } from '@/stores/order/actions';
import { uploadSingle, uploadMultiple } from '@/stores/upload/actions';
import {
  createAndUpdateCustomer,
  getCustomers,
} from '@/stores/customer/actions';
import { IOrderFormValues, CustomerForm, OrderForm } from '@/components';
import { ICustomer, IOrderItem } from '@/type';
import { IUploadMultipleRequest } from '@/stores/upload/type';
import {
  ORDER_TITLE,
  CUSTOMER_TITLE,
  PHOTO_TITLE,
} from '@/constants/stepOrderTitle';
import ORDER_STATUS from '@/constants/orderStatus';
import { setOrderForm } from '@/stores/form/reducer';
import { getProducts } from '@/stores/product/actions';
import { IGetCustomersRequest } from '@/stores/customer/type';

import { StepPhotosForm } from './components';
import {
  Wrapper,
  StepContainer,
  ButtonCustom,
  StepContent,
  WrapperButon,
} from './style';

const { Step } = Steps;

const AddOrder: React.FC = () => {
  const { setBackToRoute } = useContext(MenuContext);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { supplier, customer, order, formValues } = useAppSelector(
    (state) => state,
  );
  const [current, setCurrent] = useState<number>(0);
  const [orderFormValue, setOrderFormValue] = useState<IOrderFormValues>({
    orderId: '',
    orderItems: formValues.order.orderItems || [],
    shippingFee: 0,
    deliveryAddress: '',
    discount: 0,
    paidAmount: 0,
    orderDate: new Date(),
    status: ORDER_STATUS.PENDING,
    notes: '',
    photos: formValues.order.photos || [],
  });
  const [customerFormValue, setCustomerFormValue] = useState<ICustomer>({
    _id: '',
    facebookName: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    deliveryAddress: '',
    country: '',
  });

  const [form] = Form.useForm();

  useEffect(() => {
    setBackToRoute(ROUTES.HOME_PAGE.path);
    dispatch(getSuppliers({ limit: 1000 }));
  }, []);

  useEffect(() => form.setFieldsValue(customerFormValue), [customerFormValue]);

  const handleUploadOrderItemPhotos = (
    productName: string,
    formData: IUploadMultipleRequest,
  ) => {
    dispatch(uploadSingle(formData))
      .then(unwrapResult)
      .then(({ url }) => {
        const customOrderItems = orderFormValue.orderItems.map((item) => {
          if (item.product.name === productName) {
            return {
              ...item,
              product: { ...item.product, cover: url },
            };
          }
          return item;
        });

        setOrderFormValue({ ...orderFormValue, orderItems: customOrderItems });
        handleSetOrderFormValue({
          orderItems: customOrderItems,
        });
      });
  };

  const handleUploadOrderPhotos = (formData: IUploadMultipleRequest) => {
    dispatch(uploadMultiple(formData))
      .then(unwrapResult)
      .then((urls) => {
        const photos = [...orderFormValue.photos, ...urls];
        setOrderFormValue({ ...orderFormValue, photos });
        handleSetOrderFormValue({ photos });
      });
  };

  const handleSearchCustomer = (values: IGetCustomersRequest): void => {
    dispatch(getCustomers(values));
  };

  const handleFinishOrderForm = (values: IOrderFormValues): void => {
    const orderFormValuesCustom = { ...orderFormValue, ...values };
    const { orderId, discount, paidAmount, notes } = orderFormValuesCustom;
    if (!orderId) {
      delete orderFormValuesCustom?.orderId;
    }

    if (!discount) {
      orderFormValuesCustom.discount = 0;
    }
    if (!paidAmount) {
      orderFormValuesCustom.paidAmount = 0;
    }
    if (!notes) {
      delete orderFormValuesCustom.notes;
    }

    const customOrderItems: IOrderItem[] = [];

    orderFormValuesCustom.orderItems.map((item, index) => {
      const { price, name, supplier, size } = item;

      if (name && supplier)
        customOrderItems.push({
          price,
          product: {
            name,
            supplier,
            size: size || '',
            cover:
              name === orderFormValue?.orderItems[index]?.product?.name
                ? orderFormValue?.orderItems[index]?.product?.cover || ''
                : '',
          },
        });
    });

    setOrderFormValue({
      ...orderFormValuesCustom,
      orderItems: customOrderItems,
    });

    handleNext();
  };

  const handleFinishCustomerForm = (
    values: ICustomer & { updateCustomer: boolean },
  ): void => {
    const { updateCustomer, ...customerData } = values;

    setOrderFormValue({
      ...orderFormValue,
      deliveryAddress: customerData.deliveryAddress,
    });

    if (updateCustomer) {
      dispatch(createAndUpdateCustomer(customerData))
        .then(unwrapResult)
        .then(
          ({
            facebookName,
            fullName,
            _id,
            email,
            deliveryAddress,
            phoneNumber,
            country,
          }) => {
            setCustomerFormValue({
              _id,
              facebookName,
              fullName,
              email,
              deliveryAddress,
              phoneNumber,
              country,
            });

            dispatch(
              setOrderForm({
                ...formValues.order,
                deliveryAddress,
                customer: {
                  _id,
                  facebookName,
                  fullName,
                  email,
                  deliveryAddress,
                  phoneNumber,
                  country,
                },
              }),
            );

            handleNext();
          },
        );
    } else {
      setCustomerFormValue({
        ...customerData,
      });
      dispatch(
        setOrderForm({
          ...formValues.order,
          deliveryAddress: customerData.deliveryAddress,
          customer: {
            ...customerData,
          },
        }),
      );
      handleNext();
    }
  };

  const handleCreateOrder = (): void => {
    let isCover = false;
    for (const item of orderFormValue.orderItems) {
      if (!item.product.cover) {
        isCover = true;
      }
    }

    if (isCover) {
      toast.error(COVER_ORDER_EMPTY);
    } else {
      dispatch(
        createOrder({
          ...orderFormValue,
          customer: customerFormValue._id,
          customerCopy: customerFormValue,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          goBack();
          dispatch(
            setOrderForm({
              orderId: '',
              orderItems: [],
              shippingFee: 0,
              discount: 0,
              paidAmount: 0,
              orderDate: new Date(),
              status: ORDER_STATUS.PENDING,
              notes: '',
              customer: {
                _id: '',
                facebookName: '',
                deliveryAddress: '',
                email: '',
                phoneNumber: '',
                fullName: '',
                country: '',
              },
            }),
          );
        });
    }
  };

  const handleNext = (): void => {
    setCurrent(current + 1);
  };

  const handleBack = (): void => {
    setCurrent(current - 1);
  };

  const handleSelectStep = (currentNumber: number): void => {
    if (currentNumber <= current) setCurrent(currentNumber);
  };

  const handleDeleteOrderPhoto = (file: string) => {
    const photos = orderFormValue.photos?.filter((photo) => {
      if (photo !== file) {
        return photo;
      }
    });

    setOrderFormValue({ ...orderFormValue, photos });
    handleSetOrderFormValue({ photos });
  };

  const handleSetOrderFormValue = (values: Partial<IOrderFormValues>) => {
    dispatch(setOrderForm({ ...formValues.order, ...values }));
  };

  const handleSetCurrentOrderFormValues = (
    values: Partial<IOrderFormValues>,
  ) => {
    setOrderFormValue({ ...orderFormValue, ...values });
  };

  const goBack = (): void => {
    setBackToRoute('');
    history.push(ROUTES.HOME_PAGE.path);
  };

  const goBackStep = (): void => {
    if (current === 0) {
      goBack();
    } else if (current <= steps.length - 1) {
      handleBack();
    }
  };

  const handleGetProducts = (name: string) => {
    if (name) {
      dispatch(getProducts({ limit: 1000, name }));
    }
  };

  const steps = [
    {
      title: ORDER_TITLE,
      component: (
        <OrderForm
          order={formValues.order}
          form={form}
          supplier={supplier}
          onFinish={handleFinishOrderForm}
          onSetOrderFormValue={handleSetOrderFormValue}
          onSetCurrentFormValues={handleSetCurrentOrderFormValues}
          onSearchProducts={handleGetProducts}
        />
      ),
    },
    {
      title: CUSTOMER_TITLE,
      component: (
        <CustomerForm
          form={form}
          customer={formValues.order.customer}
          customers={customer.data}
          onFinish={handleFinishCustomerForm}
          onSearchCustomer={handleSearchCustomer}
        />
      ),
    },
    {
      title: PHOTO_TITLE,
      component: (
        <StepPhotosForm
          photos={orderFormValue.photos}
          orderFormValue={orderFormValue}
          onDeleteOrderPhoto={handleDeleteOrderPhoto}
          onUploadProductCover={handleUploadOrderItemPhotos}
          onUploadOrderPhotos={handleUploadOrderPhotos}
        />
      ),
    },
  ];

  return (
    <Wrapper>
      <StepContainer>
        <Steps current={current} onChange={handleSelectStep}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </StepContainer>

      <StepContent>{steps[current].component}</StepContent>

      <WrapperButon>
        <ButtonCustom onClick={goBackStep}>Back</ButtonCustom>
        {current < steps.length - 1 && (
          <ButtonCustom
            type='primary'
            onClick={form.submit}
            loading={customer.isSubmitting}
          >
            Next
          </ButtonCustom>
        )}
        {current === steps.length - 1 && (
          <ButtonCustom
            type='primary'
            onClick={handleCreateOrder}
            loading={order.isSubmitting}
          >
            Create order
          </ButtonCustom>
        )}
      </WrapperButon>
    </Wrapper>
  );
};

export default AddOrder;

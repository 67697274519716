import styled from 'styled-components';
import { Image } from 'antd';

export const Wrapper = styled.div<{
  isMe: boolean;
}>`
  display: flex;
  width: 100%;
  margin-top: 1rem;
  justify-content: ${({ isMe }) => (isMe ? 'flex-end' : 'flex-start')}};
`;

export const LeftContainer = styled.div``;

export const RightContainer = styled.div<{
  isMe: boolean;
}>`
  margin-left: 0.43rem;
  max-width: 75%;
  display: flex;
  flex-direction: column;
  align-items: ${({ isMe }) => (isMe ? 'flex-end' : 'flex-start')}};
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled(Image)`
  border-radius: 50%;
`;

export const MessageContainer = styled.div<{
  isMe: boolean;
}>`
  min-height: 32px; 
  max-width: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  background-color: ${({ isMe, theme }) =>
    isMe ? theme.color.primary : theme.color.gray}};
  border-radius: 1.145rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.57rem;
  display: inline-block;
  word-wrap: break-word;
  color: ${({ isMe, theme }) =>
    isMe ? theme.color.secondary : theme.color.black}};

  p { 
    margin-bottom: 0;
  }

  strong {
    color:   ${({ isMe, theme }) =>
      isMe ? theme.color.boldColor : theme.color.black}};
   }
`;

export const TimeText = styled.div<{
  isImage: boolean;
}>`
  font-style: normal;
  font-weight: normal;
  font-size: 0.715rem;
  line-height: 1.57rem;
  width: 100%;
  text-align: center;
  margin-top: 0.15rem;
  color: ${({ theme }) => theme.color.tertiaryGray};

  @media screen and (min-width: 480px) {
    max-width:  ${({ isImage }) => (isImage ? '50%' : '100%')}};
  }
`;

export const ImageContainer = styled.button`
  border: none;
  background: none;
  border-radius: 5%;
  min-width: 100px;
  max-width: 100%;
  margin-bottom: -0.4rem;
  @media screen and (min-width: 480px) {
    max-width: 50%;
  }
`;
export const FullName = styled.div`
  font-weight: 300;
  font-size: 0.875rem;
  padding-left: 1rem;
`;

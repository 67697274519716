export const LIGHT_THEME = {
  color: {
    primary: '#acddd4',
    secondary: '#ffffff',
    gray: '#F6F6F6',
    border: '#D9D9D9',
    grayBold: '#6C6C6C',
    black: '#000000',
    success: '#52C41A',
    pending: '#D59B00',
    shipping: '#363636',
    danger: '#e54345',
    lightBorder: '#f4f4f4',
    lightBg: '#2db7f5',
    secondaryGray: '#8C8C8C',
    tertiaryGray: '#B7B7B7',
    grayBg: '#F1F1F1',
    lightBlue: '#205AA7',
    inputColor: '#FAFAFA',
    boldColor: '#1890ff',
  },
};

// export const DARK_THEME = {};

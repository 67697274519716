import { combineReducers } from 'redux';
import authReducer from './auth/reducer';
import usersReducer from './user/reducer';
import roleReducer from './role/reducer';
import uploadReducer from './upload/reducer';
import supplierReducer from './supplier/reducer';
import orderReducer from './order/reducer';
import notificationReducer from './notification/reducer';
import messageReducer from './message/reducer';
import customerReducer from './customer/reducer';
import countryReducer from './country/reducer';
import formReducer from './form/reducer';
import productReducer from './product/reducer';
import trashReducer from './trash/reducer';
const rootReducer = combineReducers({
  auth: authReducer,
  user: usersReducer,
  role: roleReducer,
  upload: uploadReducer,
  supplier: supplierReducer,
  order: orderReducer,
  notification: notificationReducer,
  message: messageReducer,
  customer: customerReducer,
  country: countryReducer,
  formValues: formReducer,
  product: productReducer,
  trash: trashReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;

import { createSlice } from '@reduxjs/toolkit';
import configs from '@/configs';
import _ from 'lodash';

import { INotificationState } from './type';
import {
  getNotifications,
  setReadNotification,
  getTotalUnreadNotification,
} from './actions';

const initialState: INotificationState = {
  data: [],
  isFetching: false,
  hasMore: true,
  totalUnread: 0,
};

const notificationReducer = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationData(state, { payload }) {
      state.data = payload;
    },
    increaseTotalUnreadNotification: (state) => {
      state.totalUnread += 1;
    },
    decreaseTotalUnreadNotification: (state) => {
      state.totalUnread -= 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state, { meta }) => {
      if (meta.arg.page === 1) {
        state.hasMore = true;
        state.data = [];
      }
      state.isFetching = true;
    });
    builder.addCase(getNotifications.fulfilled, (state, { payload }) => {
      state.hasMore = !(payload.length < configs.limit);
      state.data = _.unionBy(state.data, payload, '_id');
      state.isFetching = false;
    });
    builder.addCase(getNotifications.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(
      getTotalUnreadNotification.fulfilled,
      (state, { payload }) => {
        state.totalUnread = payload;
      },
    );

    builder.addCase(setReadNotification.fulfilled, (state, { payload }) => {
      const index = state.data.findIndex(({ _id }) => _id === payload._id);
      state.data[index] = payload;
    });
  },
});

export const {
  increaseTotalUnreadNotification,
  setNotificationData,
  decreaseTotalUnreadNotification,
} = notificationReducer.actions;
export default notificationReducer.reducer;

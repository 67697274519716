import React, { createContext, useEffect } from 'react';
import io, { Socket } from 'socket.io-client';
import configs from '@/configs';
import SOCKET_EVENTS from '@/constants/socketEvents';
import { IMessage } from '@/type';

interface IProps {
  children: React.ReactNode;
  onNewNotification: () => void;
  onNewMessage: ({ message }: { message: IMessage }) => void;
}

export interface IContextValue {
  socket: Socket;
}

const socket = io(configs.socketUrl);
export const SocketContext = createContext<IContextValue>({ socket });

const SocketProvider: React.FC<IProps> = ({
  children,
  onNewNotification,
  onNewMessage,
}) => {
  useEffect(() => {
    socket.on(SOCKET_EVENTS.SERVER.NEW_NOTIFICATION, onNewNotification);
    socket.on(SOCKET_EVENTS.SERVER.NEW_MESSAGE, onNewMessage);
  }, []);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;

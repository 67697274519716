import React, { useState, useEffect } from 'react';
import { Modal, Slider } from 'antd';
import { RcFile } from 'antd/lib/upload';
import AvatarEditor from 'react-avatar-editor';

import { Container } from './style';

interface IProps {
  image?: RcFile;
  visible: boolean;
  onCancel: () => void;
  onFinish: () => void;
  setEditorRef: (editor: AvatarEditor) => void;
}

const CropImage: React.FC<IProps> = ({
  image,
  visible,
  onCancel,
  onFinish,
  setEditorRef,
}) => {
  const [zoom, setZoom] = useState<number>(1);
  const onChangeSlider = (value: number) => {
    setZoom(value);
  };

  useEffect(() => {
    if (image) {
      setZoom(1);
    }
  }, [visible, image]);

  return (
    <Modal
      visible={visible}
      title='Crop Image'
      okText='Crop'
      cancelText='Close'
      onCancel={onCancel}
      onOk={onFinish}
    >
      <Container>
        {image && (
          <>
            <AvatarEditor
              image={image}
              width={200}
              height={200}
              border={50}
              color={[255, 255, 255, 0.6]}
              rotate={0}
              scale={zoom}
              ref={setEditorRef}
            />

            <Slider
              min={1}
              max={10}
              step={0.1}
              tipFormatter={null}
              style={{ width: 300 }}
              value={zoom}
              onChange={onChangeSlider}
            />
          </>
        )}
      </Container>
    </Modal>
  );
};

export default CropImage;

import styled from 'styled-components';
import { Upload } from 'antd';

export const UploadCoverContainer = styled(Upload)`
  display: block;
  text-align: center;
  margin-bottom: 1rem;

  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    margin: 0;
  }
`;

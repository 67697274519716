import React from 'react';
import { Wrapper, CancelBtn, DeleteBtn } from './style';

interface IProps {
  isSubmitting: boolean;
  onDelete: () => void;
  onCancel: () => void;
}

const ActionButtons: React.FC<IProps> = ({
  isSubmitting,
  onCancel,
  onDelete,
}) => {
  return (
    <Wrapper>
      <CancelBtn onClick={onCancel} loading={isSubmitting}>
        Cancel
      </CancelBtn>
      <DeleteBtn onClick={onDelete}>Delete</DeleteBtn>
    </Wrapper>
  );
};

export default ActionButtons;

import React, { useContext, useEffect, useState } from 'react';

import { MenuContext } from '@/contexts/MenuContext';
import ROUTES from '@/constants/routes';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { uploadSingle } from '@/stores/upload/actions';
import { updateInformation } from '@/stores/auth/actions';
import { unwrapResult } from '@reduxjs/toolkit';

import { AccountForm, IFinishForm } from './components';

interface IPayload extends IFinishForm {
  cover?: string;
}

const Account: React.FC = () => {
  const { setBackToRoute } = useContext(MenuContext);
  const { auth, role, upload } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const [cover, setCover] = useState<string>(auth.user.cover || '');
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);

  useEffect(() => {
    setBackToRoute(ROUTES.HOME_PAGE.path);
  }, []);

  const handleUpdate = async (values: IFinishForm) => {
    const payload = { ...values } as IPayload;

    if (cover) {
      payload.cover = cover;
    }

    setUpdateLoading(true);
    dispatch(updateInformation(payload))
      .then(unwrapResult)
      .then(() => {
        setUpdateLoading(false);
      })
      .catch(() => {
        setUpdateLoading(false);
      });
  };

  const handleUploadCover = async (formData: FormData) => {
    await dispatch(uploadSingle(formData))
      .then(unwrapResult)
      .then(({ url }) => {
        setCover(url);
      });
  };

  return (
    <AccountForm
      values={auth.user}
      roles={role.data}
      onFinish={handleUpdate}
      onUploadCover={handleUploadCover}
      updateLoading={updateLoading}
      upload={upload}
      cover={cover}
    />
  );
};

export default Account;

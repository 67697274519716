import React, { useContext } from 'react';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { IMessage, INotification } from '@/type';
import avatarDefault from '@/assets/images/avatarDefault.png';
import orderDefault from '@/assets/images/orderDefault.png';
import replaceNotificationContent from '@/utils/replaceNotificationContent';
import NOTIFICATION_TYPES, {
  INotificationTypes,
} from '@/constants/notificationTypes';
import replaceRoutePath from '@/utils/replaceRoutePath';
import ROUTES from '@/constants/routes';
import getOrderStatusColor from '@/utils/getOrderStatusColor';
import MESSAGE_TYPES from '@/constants/messageTypes';
import DELETE_STATUS from '@/constants/deleteStatus';
import {
  ORDER_HAS_BEEN_DELETED,
  NOT_HAVE_PERMISSION,
} from '@/constants/toastStatus';
import { useAppSelector } from '@/hooks';
import { MenuContext } from '@/contexts/MenuContext';

import {
  Avatar,
  NotificationDetailContainer,
  NotificationDetail,
  LeftContainer,
  ImageContainer,
  DateTimeContainer,
  IconClock,
  NotificationContainer,
} from './style';

interface IProps {
  allowedSee?: boolean;
  data: INotification | IMessage;
  visibleOrderCover?: boolean;
  onRead?: (_id: string) => void;
  type?: INotificationTypes;
  handleCloseDropdown?: () => void;
}

interface IElement {
  props: {
    children: string[];
  };
}

const NotificationItem: React.FC<IProps> = ({
  data,
  allowedSee = false,
  visibleOrderCover = true,
  onRead,
  type = NOTIFICATION_TYPES.NOTIFICATION,
  handleCloseDropdown,
}) => {
  const { _id, content, user, order, isRead, createdAt } = data;
  const { auth } = useAppSelector((state) => state);
  const history = useHistory();
  const { setBackToRoute } = useContext(MenuContext);

  const handleNotificationContent = (type: string): string => {
    if (type === NOTIFICATION_TYPES.NOTIFICATION) {
      return replaceNotificationContent({ content, user, order });
    }

    let tagMessages = '';
    let fullNames = '';

    const tags = (data as IMessage)?.tags;

    if (tags?.length) {
      for (const mentionedUser of tags) {
        if (mentionedUser?._id === auth.user._id) {
          tagMessages = `**${user.fullName}** mentioned you in the order **#${order?.orderId}**`;
        }
      }
      if (!tagMessages) {
        for (const mentionedUser of tags) {
          fullNames += `${mentionedUser.fullName}, `;
        }

        fullNames = fullNames.slice(0, -2);
        tagMessages = `**${user.fullName}** mentioned **${fullNames}** in the order **#${order?.orderId}**`;
      }
    }

    if (tagMessages) return tagMessages;

    return `**${user.fullName}** sent a ${
      (data as IMessage).type === MESSAGE_TYPES.IMAGE ? 'Image' : 'Message'
    } to order **#${order?.orderId}**`;
  };

  const handleClickNotification = () => {
    if (onRead && handleCloseDropdown) {
      const activityPath = replaceRoutePath({
        originalPath: ROUTES.ORDER_INFORMATION_PAGE.path,
        replacementValue: order?._id,
      });
      const orderChatPath = replaceRoutePath({
        originalPath: ROUTES.ORDER_CHAT_PAGE.path,
        replacementValue: order?._id,
      });
      const isDeletedOrder = order?.deleteStatus === DELETE_STATUS.DELETED;

      if (!isRead) onRead(_id);

      if (type === NOTIFICATION_TYPES.NOTIFICATION) {
        const isDeleteOrderRequest = data.content.includes('request to delete');

        if (isDeleteOrderRequest) {
          switch (order?.deleteStatus) {
            case DELETE_STATUS.CANCEL: {
              history.push(activityPath);
              break;
            }
            case DELETE_STATUS.PENDING: {
              if (allowedSee) {
                history.push(ROUTES.ORDER_MANAGEMENT_PAGE.path);
              }
              if (!allowedSee) {
                toast.error(NOT_HAVE_PERMISSION);
                goHome();
              }
              break;
            }
          }
        }
        if (!isDeleteOrderRequest) {
          if (!isDeletedOrder) {
            history.push(activityPath);
          }
          if (isDeletedOrder) {
            toast.error(ORDER_HAS_BEEN_DELETED);
            goHome();
          }
        }
      }

      if (type === NOTIFICATION_TYPES.MESSAGE) {
        if (!isDeletedOrder) {
          history.push(orderChatPath);
        }
        if (isDeletedOrder) {
          toast.error(ORDER_HAS_BEEN_DELETED);
          goHome();
        }
      }
      handleCloseDropdown();
    }
  };

  const goHome = () => {
    setBackToRoute('');
    history.push(ROUTES.HOME_PAGE.path);
  };

  return (
    <NotificationContainer isRead={isRead} onClick={handleClickNotification}>
      <LeftContainer>
        <ImageContainer>
          <Avatar
            width={42}
            height={42}
            preview={false}
            src={user.cover || avatarDefault}
          />
        </ImageContainer>
        <NotificationDetailContainer>
          <NotificationDetail visibleOrderCover={visibleOrderCover}>
            <ReactMarkdown
              components={{
                em: (data) => {
                  const elm = data.children[0] as IElement;

                  if (elm.props.children[0]) {
                    return (
                      <span
                        style={{
                          color: getOrderStatusColor(elm.props.children[0]),
                          fontWeight: 'bold',
                        }}
                      >
                        {elm.props.children[0]}
                      </span>
                    );
                  }

                  return <span />;
                },
              }}
            >
              {handleNotificationContent(type)}
            </ReactMarkdown>
          </NotificationDetail>
          <DateTimeContainer>
            <IconClock />
            {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
          </DateTimeContainer>
        </NotificationDetailContainer>
      </LeftContainer>

      {visibleOrderCover && (
        <ImageContainer>
          <Avatar
            width={42}
            height={42}
            preview={false}
            src={
              order?.orderItems?.length
                ? order.orderItems[0]?.product?.cover || orderDefault
                : orderDefault
            }
          />
        </ImageContainer>
      )}
    </NotificationContainer>
  );
};

export default NotificationItem;

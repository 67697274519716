import React, { useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { IUploadSingleRequest } from '@/stores/upload/type';
import { IUploadState } from '@/stores/upload/type';
import { ISupplierState } from '@/stores/supplier/type';
import { ADD } from '@/constants/submitText';
import { UploadCover } from '@/components';

import { Wrapper, Description, InputNumberCustom } from './style';

export interface ISupplierFormValues {
  name: string;
  overDay: number;
  cover?: string;
}

interface IProps {
  onFinish: (values: ISupplierFormValues) => void;
  initialValues?: ISupplierFormValues;
  submitText: string;
  upload: IUploadState;
  supplier: ISupplierState;
  cover?: string;
  handleUploadSingle: (values: IUploadSingleRequest) => void;
}

const SupplierForm: React.FC<IProps> = ({
  onFinish,
  initialValues,
  submitText,
  upload,
  cover,
  supplier,
  handleUploadSingle,
}) => {
  const [form] = Form.useForm();

  useEffect(() => form.resetFields(), [initialValues]);

  return (
    <Wrapper>
      <UploadCover
        cover={cover}
        loading={upload.isUploadingSingle}
        onUpload={handleUploadSingle}
      />

      <Form
        layout='vertical'
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Form.Item
          label='Supplier name'
          name='name'
          rules={[
            {
              required: true,
              message: 'Please input your supplier name!',
            },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Over day'
          name='overDay'
          rules={[
            {
              required: true,
              message: 'Please input over day!',
            },
          ]}
          hasFeedback
        >
          <InputNumberCustom />
        </Form.Item>

        <Form.Item shouldUpdate>
          {() => {
            const { overDay = 0 } = form.getFieldsValue();
            return (
              <Description>
                After
                <strong> {overDay} days</strong>, if the orders of this supplier
                have not been processed, the system will automatically change
                the status of the order to <strong>Over</strong>.
              </Description>
            );
          }}
        </Form.Item>
        <Form.Item>
          <Button
            htmlType='submit'
            type='primary'
            block
            loading={
              submitText === ADD ? supplier.isSubmitting : supplier.isUpdating
            }
          >
            {submitText}
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

export default SupplierForm;

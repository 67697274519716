import { createSlice } from '@reduxjs/toolkit';
import ORDER_STATUS from '@/constants/orderStatus';

import { IFormState } from './type';

const initialState: IFormState = {
  order: {
    orderId: '',
    orderItems: [],
    shippingFee: 0,
    deliveryAddress: '',
    discount: 0,
    paidAmount: 0,
    orderDate: new Date(),
    status: ORDER_STATUS.PENDING,
    notes: '',
    customer: {
      _id: '',
      facebookName: '',
      email: '',
      deliveryAddress: '',
      phoneNumber: '',
      fullName: '',
      country: '',
    },
    photos: [],
  },
};

const formReducer = createSlice({
  name: 'formValues',
  initialState,
  reducers: {
    setOrderForm(state, { payload }) {
      state.order = payload;
    },
  },
});

export const { setOrderForm } = formReducer.actions;
export default formReducer.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import apiRequest from '@/utils/apiRequest';
import {
  loginApi,
  getMeApi,
  changePasswordApi,
  updateInformationApi,
} from '@/apis/auth';
import {
  CHANGE_PASSWORD_SUCCESS,
  UPDATE_INFORMATION_SUCCESS,
} from '@/constants/toastStatus';

import {
  ILoginRequest,
  ILoginResponse,
  IGetMeResponse,
  IChangePasswordRequest,
  IChangePasswordResponse,
  IUpdateInformationRequest,
  IUpdateInformationResponse,
} from './type';

export const login = createAsyncThunk<ILoginResponse, ILoginRequest>(
  'auth/login',
  async (body, { rejectWithValue }) => {
    try {
      const res = await loginApi(body);
      apiRequest.defaults.headers.Authorization = `Bearer ${res.token}`;
      return res;
    } catch (e) {
      toast.error((e as Error).message);
      return rejectWithValue(e);
    }
  },
);

export const getMe = createAsyncThunk<IGetMeResponse>(
  'auth/getMe',
  async (body, { rejectWithValue }) => {
    try {
      return await getMeApi();
    } catch (e) {
      toast.error((e as Error).message);
      return rejectWithValue(e);
    }
  },
);

export const changePassword = createAsyncThunk<
  IChangePasswordResponse,
  IChangePasswordRequest
>('auth/changePassword', async (body, { rejectWithValue }) => {
  try {
    const res = await changePasswordApi(body);
    toast.success(CHANGE_PASSWORD_SUCCESS);
    return res;
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

export const updateInformation = createAsyncThunk<
  IUpdateInformationResponse,
  IUpdateInformationRequest
>('auth/updateInformation', async (body, { rejectWithValue }) => {
  try {
    const res = await updateInformationApi(body);
    toast.success(UPDATE_INFORMATION_SUCCESS);
    return res;
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

import configs from '@/configs';
import { RootState } from '@/stores/combineReducers';
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { deleteProduct, getProducts } from './actions';
import { IProductState } from './type';

const initialState: IProductState = {
  data: {},
  isFetching: false,
  isDeleting: false,
  hasMore: true,
};

const productReducer = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProduct: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state, { meta }) => {
      if (meta.arg.page === 1) {
        state.hasMore = true;
        state.data = {};
      }
      state.isFetching = true;
      state.hasMore = true;
    });
    builder.addCase(getProducts.fulfilled, (state, { payload, meta }) => {
      const payloadCustom = _.keyBy(payload, '_id');
      state.hasMore = !(payload.length < (meta?.arg?.limit || configs.limit));

      if (meta?.arg?.page && meta?.arg?.page > 1) {
        state.data = Object.assign(state.data, payloadCustom);
      } else {
        state.data = payloadCustom;
      }

      state.isFetching = false;
    });
    builder.addCase(getProducts.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(deleteProduct.pending, (state) => {
      state.isDeleting = true;
    });
    builder.addCase(deleteProduct.fulfilled, (state, { payload }) => {
      const { _id } = payload;
      _id && delete state.data[_id];
      state.isDeleting = false;
    });
    builder.addCase(deleteProduct.rejected, (state) => {
      state.isDeleting = false;
    });
  },
});

export const productState = (state: RootState): IProductState => state.product;
export default productReducer.reducer;
export const { setProduct } = productReducer.actions;

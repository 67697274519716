import React, { useState, useContext } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';

import { useAppDispatch } from '@/hooks';
import { login } from '@/stores/auth/actions';
import ROUTES from '@/constants/routes';
import { MenuContext } from '@/contexts/MenuContext';

import { LoginForm, IFormValues } from './components';

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const { setBackToRoute } = useContext(MenuContext);

  const handleLogin = (values: IFormValues): void => {
    setLoading(true);

    dispatch(login(values))
      .then(unwrapResult)
      .then(() => {
        setBackToRoute('');
        history.push(ROUTES.HOME_PAGE.path);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return <LoginForm onFinish={handleLogin} loading={loading} />;
};

export default Login;

interface IParameters {
  replacementValue: string;
  originalPath: string;
}

const replaceRoutePath = ({
  originalPath,
  replacementValue,
}: IParameters): string => {
  return originalPath.replace(':id', replacementValue);
};

export default replaceRoutePath;

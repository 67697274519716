import styled from 'styled-components';

export const BottomContainer = styled.div`
  padding: 1rem;
  display: flex;
  height: 150px;
  justify-content: space-between;

  strong {
    font-size: 0.9rem;
  }
`;

export const Date = styled.div`
  text-align: end;
`;

import apiRequest from '@/utils/apiRequest';
import {
  IGetOrdersRequest,
  IGetOrdersResponse,
  IGetOrderRequest,
  IGetOrderResponse,
  IGetMessagesRequest,
  IGetMessagesResponse,
  IGetNotificationsRequest,
  IGetNotificationsResponse,
  ICreateOrderRequest,
  ICreateOrderResponse,
  IUpdateOrderRequest,
  IUpdateOrderResponse,
  IGetTotalAmountResponse,
} from '@/stores/order/type';

const endpoint = '/orders';

export const getOrdersApi = (
  query: IGetOrdersRequest,
): Promise<IGetOrdersResponse> => {
  return apiRequest.get(endpoint, { params: query });
};

export const getOrderApi = (
  params: IGetOrderRequest,
): Promise<IGetOrderResponse> => {
  return apiRequest.get(`${endpoint}/${params._id}`);
};

export const getMessagesApi = (
  requestData: IGetMessagesRequest,
): Promise<IGetMessagesResponse> => {
  const { _id, pagination } = requestData;
  return apiRequest.get(`${endpoint}/${_id}/messages`, {
    params: pagination,
  });
};

export const getNotificationsApi = (
  requestData: IGetNotificationsRequest,
): Promise<IGetNotificationsResponse> => {
  const { _id, pagination } = requestData;
  return apiRequest.get(`${endpoint}/${_id}/notifications`, {
    params: pagination,
  });
};

export const createOrderApi = (
  body: ICreateOrderRequest,
): Promise<ICreateOrderResponse> => {
  return apiRequest.post(endpoint, body);
};

export const updateOrderApi = (
  body: IUpdateOrderRequest,
): Promise<IUpdateOrderResponse> => {
  const { orderId, payload } = body;
  return apiRequest.put(`${endpoint}/${orderId}`, payload);
};

export const getTotalAmountApi = (): Promise<IGetTotalAmountResponse> => {
  return apiRequest.get(`${endpoint}/total-amount`);
};

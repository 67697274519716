import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  FormInstance,
  AutoComplete,
  Select,
  Modal,
  Radio,
  RadioChangeEvent,
} from 'antd';

import { ICountry, ICustomer } from '@/type';
import { useDebounce, useAppSelector } from '@/hooks';
import COUNTRIES from '@/constants/countries';
import { IGetCustomersRequest } from '@/stores/customer/type';

interface IProps {
  form: FormInstance<ICustomer & { updateCustomer: boolean }>;
  deliveryAddress?: string;
  customers: ICustomer[];
  customer?: ICustomer;
  onFinish: (values: ICustomer & { updateCustomer: boolean }) => void;
  onSearchCustomer: (values: IGetCustomersRequest) => void;
}

const { Option } = AutoComplete;

const CustomerForm: React.FC<IProps> = ({
  form,
  deliveryAddress,
  customer,
  customers,
  onFinish,
  onSearchCustomer,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [addresses, setAddresses] = useState<string[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<string>();
  const [facebookName, setFacebookName] = useState<string>('');
  const [isDeliveryAddressModalVisible, setIsDeliveryAddressModalVisible] =
    useState(false);

  const phoneNumberDebounce = useDebounce(phoneNumber, 500);
  const emailDebounce = useDebounce(email, 500);
  const facebookNameDebounce = useDebounce(facebookName, 500);

  const { data } = useAppSelector((state) => state.country);
  const usaId = data.find((country) => country.name === COUNTRIES.USA)?._id;

  useEffect(() => {
    if (phoneNumber) {
      onSearchCustomer({ phoneNumber });
    }
  }, [phoneNumberDebounce]);

  useEffect(() => {
    if (email) {
      onSearchCustomer({ email });
    }
  }, [emailDebounce]);

  useEffect(() => {
    if (facebookName) {
      onSearchCustomer({ facebookName });
    }
  }, [facebookNameDebounce]);

  useEffect(() => {
    if (customer) {
      const {
        _id,
        fullName,
        facebookName,
        deliveryAddress: customerDeliveryAddress,
        email,
        phoneNumber,
        country,
      } = customer;

      form.setFieldsValue({
        _id,
        fullName,
        facebookName,
        email,
        deliveryAddress: deliveryAddress || customerDeliveryAddress,
        phoneNumber,
        country: (country as ICountry)?._id || country || usaId,
      });

      setAddresses(customer.deliveryAddresses || []);
    } else {
      form.setFieldsValue({ country: usaId });
    }
  }, [customer]);

  const handleSelectPhoneNumber = (phoneNumber: string) => {
    const customerFound = customers.find(
      (customer) => customer.phoneNumber === phoneNumber,
    );

    if (customerFound) {
      setIsDeliveryAddressModalVisible(true);
      setAddresses(customerFound.deliveryAddresses || []);
      setSelectedAddress(customerFound.deliveryAddresses?.[0]);

      const {
        _id,
        fullName,
        facebookName,
        // deliveryAddress,
        deliveryAddresses,
        email,
        country,
      } = customerFound;

      form.setFieldsValue({
        _id,
        fullName,
        facebookName,
        // deliveryAddress,
        deliveryAddresses,
        email,
        phoneNumber,
        country,
      });
    }
  };

  const handleChangePhoneNumber = (value: string) => {
    setPhoneNumber(value);
  };

  const handleChangeEmail = (value: string) => {
    setEmail(value);
  };

  const handleChangeFacebookName = (value: string) => {
    setFacebookName(value);
  };

  const handleChangeSelectedAddress = (event: RadioChangeEvent) => {
    setSelectedAddress(event.target.value);
  };

  const handleConfirmAddress = () => {
    form.setFieldsValue({
      deliveryAddress: selectedAddress,
    });
    setIsDeliveryAddressModalVisible(false);
  };

  return (
    <Form layout='vertical' form={form} onFinish={onFinish}>
      <Form.Item
        label='Phone number'
        name='phoneNumber'
        rules={[
          {
            required: true,
            message: 'Please input phone number!',
          },
        ]}
        hasFeedback
      >
        <AutoComplete
          onChange={handleChangePhoneNumber}
          onSelect={handleSelectPhoneNumber}
        >
          {customers.map((customer) => (
            <Option value={customer.phoneNumber} key={customer._id}>
              {customer.phoneNumber} - {customer.fullName}
            </Option>
          ))}
        </AutoComplete>
      </Form.Item>

      <Form.Item label='Facebook name' name='facebookName' hasFeedback>
        <AutoComplete
          onChange={handleChangeFacebookName}
          onSelect={handleSelectPhoneNumber}
        >
          {customers.map((customer) => (
            <Option value={customer.phoneNumber} key={customer._id}>
              {customer.facebookName}
            </Option>
          ))}
        </AutoComplete>
      </Form.Item>

      <Form.Item
        label='Recipient’s name'
        name='fullName'
        rules={[
          {
            required: true,
            message: 'Please input full name!',
          },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Email'
        name='email'
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
        ]}
        hasFeedback
      >
        <AutoComplete
          onChange={handleChangeEmail}
          onSelect={handleSelectPhoneNumber}
        >
          {customers.map((customer) => (
            <Option value={customer.phoneNumber} key={customer._id}>
              {customer.email} - {customer.fullName}
            </Option>
          ))}
        </AutoComplete>
      </Form.Item>

      <Form.Item
        label={
          <div>
            <span>Delivery address</span>{' '}
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault();
                setIsDeliveryAddressModalVisible(true);
              }}
            >
              Select existing address
            </a>
          </div>
        }
        name='deliveryAddress'
        rules={[
          {
            required: true,
            message: 'Please input delivery address!',
          },
          {
            min: 6,
            message: 'Delivery address must be at least 6 characters!',
          },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Country'
        name='country'
        rules={[
          {
            required: true,
            message: 'Please select country!',
          },
        ]}
      >
        <Select>
          {data.map(({ _id, name }) => (
            <Option value={_id} key={_id}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label='Update existing data of customer who has the same phone number?'
        name='updateCustomer'
        rules={[
          {
            required: true,
            message: 'Please select an option!',
          },
        ]}
      >
        <Radio.Group>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>

      {addresses.length > 0 && (
        <Modal
          title='Select delivery address'
          visible={isDeliveryAddressModalVisible}
          cancelText='None of these'
          onOk={handleConfirmAddress}
          onCancel={() => {
            setIsDeliveryAddressModalVisible(false);
          }}
        >
          <Radio.Group
            options={addresses}
            defaultValue={deliveryAddress || addresses[0]}
            onChange={handleChangeSelectedAddress}
          />
        </Modal>
      )}
    </Form>
  );
};

export default CustomerForm;

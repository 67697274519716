import { ProductItem } from '@/components';
import config from '@/configs';
import ROUTES from '@/constants/routes';
import { MenuContext } from '@/contexts/MenuContext';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { deleteProduct, getProducts } from '@/stores/product/actions';
import { setProduct } from '@/stores/product/reducer';
import { IProduct } from '@/type';
import { unwrapResult } from '@reduxjs/toolkit';
import { Empty, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

const Products: React.FC = () => {
  const { setBackToRoute } = useContext(MenuContext);
  const [productSelect, setProductSelect] = useState<IProduct['_id']>('');
  const { product } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const [initialSpinning, setInitialSpinning] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { limit } = config;

  useEffect(() => {
    setBackToRoute(ROUTES.HOME_PAGE.path);
    handleFetchInit();
    return () => {
      dispatch(setProduct({}));
    };
  }, []);

  const handleFetchInit = () => {
    setInitialSpinning(true);
    dispatch(getProducts({ limit, page: currentPage }))
      .then(unwrapResult)
      .then(() => {
        setInitialSpinning(false);
        setCurrentPage(2);
      });
  };

  const fetchMoreProductsData = () => {
    dispatch(getProducts({ limit, page: currentPage }));
    setCurrentPage(currentPage + 1);
  };

  const handleDeleteProduct = (_id: IProduct['_id']) => {
    dispatch(deleteProduct({ _id }));
  };

  const handleSelectProduct = (_id: IProduct['_id']) => {
    setProductSelect(_id);
  };

  return (
    <Spin spinning={initialSpinning} style={{ marginTop: 20 }}>
      {currentPage !== 1 && (
        <InfiniteScroll
          dataLength={Object.keys(product.data).length}
          next={fetchMoreProductsData}
          hasMore={product.hasMore}
          loader={
            <Spin
              style={{ width: '100%', marginTop: 20 }}
              spinning={product.isFetching}
            />
          }
        >
          {Object.values(product.data).length ? (
            Object.values(product.data).map((productItem, index) => (
              <ProductItem
                product={productItem}
                key={index}
                handleDeleteProduct={handleDeleteProduct}
                handleSelectProduct={handleSelectProduct}
                isDeleting={
                  productSelect === productItem._id && product.isDeleting
                }
              />
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </InfiniteScroll>
      )}
    </Spin>
  );
};

export default Products;

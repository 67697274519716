import React from 'react';
import { Button } from 'antd';

import { Wrapper } from './style';

interface IProps {
  children: string;
  icon: React.ReactElement;
  revert?: boolean;
  type?: 'primary';
  onClick?: () => void;
  danger?: boolean;
}

const ActionButton: React.FC<IProps> = ({
  icon,
  type,
  revert,
  children,
  danger,
  onClick,
}) => {
  return (
    <Wrapper revert={revert} type={type} onClick={onClick}>
      <Button type={type} icon={icon} danger={danger} />
      <p>{children}</p>
    </Wrapper>
  );
};

export default ActionButton;

import React from 'react';
import { Form, Input, Button, Select } from 'antd';
import _ from 'lodash';
import { UploadCover } from '@/components';
import { IRole, IUser } from '@/type';
import { IUploadState } from '@/stores/upload/type';

import { Wrapper } from './style';

const { Option } = Select;

export interface IFormValues {
  fullName: string;
  phoneNumber: string;
  address: string;
  position: number;
}

export type IFinishForm = Pick<IFormValues, 'fullName' | 'address'>;

interface IProps {
  onFinish: (values: IFinishForm) => void;
  onUploadCover: (formData: FormData) => void;
  values: Partial<IUser>;
  roles: IRole[];
  upload: IUploadState;
  updateLoading: boolean;
  cover: string;
}

const AccountForm: React.FC<IProps> = ({
  values,
  roles,
  onFinish,
  onUploadCover,
  upload,
  updateLoading,
  cover,
}) => {
  const [form] = Form.useForm();

  const initialValues = {
    fullName: values.fullName,
    phoneNumber: values.phoneNumber,
    address: values.address,
    position: values.role?.name,
  };

  const handleFinish = (values: IFormValues) => {
    const valuesPicked = _.pick(values, ['fullName', 'address']);
    onFinish(valuesPicked);
  };

  return (
    <Wrapper>
      <UploadCover
        cover={cover}
        loading={upload.isUploadingSingle}
        onUpload={onUploadCover}
      />

      <Form
        layout='vertical'
        form={form}
        onFinish={handleFinish}
        initialValues={initialValues}
      >
        <Form.Item
          label='Full name'
          name='fullName'
          rules={[
            {
              required: true,
              message: 'Please input your full name!',
            },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Address'
          name='address'
          rules={[
            {
              required: true,
              message: 'Please input your address!',
            },
            {
              min: 6,
              message: 'Address must be at least 6 characters!',
            },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Phone number'
          name='phoneNumber'
          rules={[
            {
              required: true,
              message: 'Please input your phone number!',
            },
          ]}
          hasFeedback
        >
          <Input disabled />
        </Form.Item>

        <Form.Item label='Position' name='position'>
          <Select disabled>
            {roles.map((role) => (
              <Option key={role._id} value={role._id}>
                {role.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            htmlType='submit'
            type='primary'
            block
            loading={updateLoading}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

export default AccountForm;

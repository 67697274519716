import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import configs from '@/configs';
import DELETE_STATUS from '@/constants/deleteStatus';
import { IOrderState } from './type';
import { getOrderTrash, updateOrderTrash } from './actions';

const initialState: IOrderState = {
  data: {},
  count: 0,
  totalAmount: 0,
  isFetching: false,
  isSubmitting: false,
  hasMore: true,
  messages: {
    data: [],
    isFetching: false,
    hasMore: true,
  },
  notifications: {
    data: [],
    isFetching: false,
    hasMore: true,
  },
};

const trashReducer = createSlice({
  name: 'trash',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrderTrash.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getOrderTrash.fulfilled, (state, { payload, meta }) => {
      const payloadCustom = _.keyBy(payload.orders, '_id');
      state.hasMore = !(payload.orders.length < configs.limit);
      const page = meta.arg?.page;
      state.count = payload.count;
      state.totalAmount = payload.totalAmount;
      const concatOrderTrashConditions = Object.values(state.data).length === 1;
      if (page && page > 1) {
        state.data = Object.assign(state.data, payloadCustom);
      }

      if (!page || page === 1) {
        if (concatOrderTrashConditions) {
          const defaultOrder = Object.values(state.data)[0];

          if (defaultOrder) {
            delete payloadCustom[defaultOrder._id];
            state.data = Object.assign(state.data, payloadCustom);
          }
        }

        if (!concatOrderTrashConditions) {
          state.data = payloadCustom;
        }
      }

      state.isFetching = false;
    });
    builder.addCase(getOrderTrash.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(updateOrderTrash.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(updateOrderTrash.fulfilled, (state, { payload }) => {
      const { _id } = payload;
      state.data[_id] = payload;
      if (
        payload.deleteStatus &&
        (payload.deleteStatus === DELETE_STATUS.DELETED ||
          payload.deleteStatus === DELETE_STATUS.CANCEL ||
          payload.deleteStatus === DELETE_STATUS.TRASH ||
          payload.deleteStatus === DELETE_STATUS.RESTORE)
      ) {
        delete state.data[_id];
      }
      state.isSubmitting = false;
    });
    builder.addCase(updateOrderTrash.rejected, (state) => {
      state.isSubmitting = false;
    });
  },
});

export default trashReducer.reducer;

import React, { useState } from 'react';
import 'react-image-lightbox/style.css';
import { IUploadMultipleRequest } from '@/stores/upload/type';
import { IOrderItem } from '@/type';
import { UploadCover } from '@/components';

import { Wrapper, ProductContainer, TopContainer } from './style';

interface IProps {
  orderItems: IOrderItem[];
  viewOnly?: boolean;
  loading?: boolean;
  onUploadCover: (
    productName: string,
    formData: IUploadMultipleRequest,
  ) => void;
}

const OrderItemPhotos: React.FC<IProps> = ({
  orderItems,
  loading = false,
  onUploadCover,
}) => {
  const [selectedProduct, setSelectedProduct] = useState<string>('');

  return (
    <Wrapper>
      {orderItems.map((item, index) => (
        <ProductContainer
          onClick={() => setSelectedProduct(item.product.name)}
          key={index}
        >
          <TopContainer>{item.product.name}</TopContainer>
          <UploadCover
            cover={item.product.cover}
            loading={loading && item.product.name === selectedProduct}
            onUpload={(formData: IUploadMultipleRequest) => {
              onUploadCover(item.product.name, formData);
            }}
          />
        </ProductContainer>
      ))}
    </Wrapper>
  );
};

export default OrderItemPhotos;

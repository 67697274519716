import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.gray};
  padding: 1rem;
  min-height: 100px;

  p {
    margin: 0;
    font-weight: bold;
  }
`;

export const Description = styled.div`
  margin-top: 0.3rem;
  font-size: 1rem;
  word-wrap: break-word;
`;

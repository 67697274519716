import { LIGHT_THEME } from '@/constants/themes';
import ORDER_STATUS from '@/constants/orderStatus';

const getOrderStatusColor = (status: string): string => {
  switch (status) {
    case ORDER_STATUS.PENDING: {
      return LIGHT_THEME.color.pending;
    }

    case ORDER_STATUS.SHIPPING: {
      return LIGHT_THEME.color.lightBlue;
    }

    case ORDER_STATUS.DONE: {
      return LIGHT_THEME.color.success;
    }
  }

  return LIGHT_THEME.color.danger;
};

export default getOrderStatusColor;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  getCustomersApi,
  createAndUpdateCustomerApi,
  createCustomerApi,
} from '@/apis/customer';

import {
  IGetCustomersRequest,
  IGetCustomersResponse,
  ICreateAndUpdateCustomersRequest,
  ICreateAndUpdateCustomersResponse,
  ICreateCustomersResponse,
  ICreateCustomersRequest,
} from './type';

export const getCustomers = createAsyncThunk<
  IGetCustomersResponse,
  IGetCustomersRequest
>('customer/getCustomers', async (query, { rejectWithValue }) => {
  try {
    return await getCustomersApi(query);
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const createCustomer = createAsyncThunk<
  ICreateCustomersResponse,
  ICreateCustomersRequest
>('customer/createCustomer', async (body, { rejectWithValue }) => {
  try {
    return await createCustomerApi(body);
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

export const createAndUpdateCustomer = createAsyncThunk<
  ICreateAndUpdateCustomersResponse,
  ICreateAndUpdateCustomersRequest
>('customer/createAndUpdateCustomer', async (body, { rejectWithValue }) => {
  try {
    return await createAndUpdateCustomerApi(body);
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

import styled, { css } from 'styled-components';

interface IWrapperProps {
  revert?: boolean;
  type?: 'primary';
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ revert }: IWrapperProps) =>
    revert &&
    css`
      flex-direction: row-reverse;
    `}

  p {
    margin-bottom: 0;
    color: ${({ theme }) => theme.color.primary};
    margin-left: 0.5rem;
    font-weight: bold;

    ${({ revert }: { revert?: boolean }) =>
      revert &&
      css`
        margin-left: 0;
        margin-right: 0.5rem;
      `}
  }

  button.ant-btn.ant-btn-icon-only {
    color: ${({ theme }) => theme.color.primary};
    border-color: ${({ theme }) => theme.color.primary};

    ${({ type }: IWrapperProps) =>
      type === 'primary' &&
      css`
        color: ${({ theme }) => theme.color.secondary};
      `}
  }
`;

import styled from 'styled-components';
import { Button } from 'antd';

export const Wrapper = styled.div`
  padding: 1.145rem;
`;

export const StepContainer = styled.div`
  padding: 0 0.145rem;

  @media screen and (max-width: 480px) {
    .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background: transparent !important;
    }
  }
`;

export const ButtonCustom = styled(Button)`
  width: 100%;
`;

export const StepContent = styled.div`
  width: 100%;
  margin-top: 1.145rem;
`;

export const WrapperButon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${ButtonCustom}:not(:last-child) {
    margin-right: 1.145rem;
  }
`;

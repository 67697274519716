import React from 'react';
import { Form, Button } from 'antd';

import { CustomerForm } from '@/components';
import { ICustomer } from '@/type';
import { IGetCustomersRequest } from '@/stores/customer/type';

interface IProps {
  deliveryAddress?: string;
  customer: Omit<ICustomer, '_id'>;
  customers: ICustomer[];
  loading: boolean;
  onFinish: (values: ICustomer & { updateCustomer: boolean }) => void;
  onSearchCustomer: (values: IGetCustomersRequest) => void;
}

const CustomerTab: React.FC<IProps> = ({
  deliveryAddress,
  customer,
  customers,
  loading,
  onFinish,
  onSearchCustomer,
}) => {
  const [form] = Form.useForm();

  return (
    <>
      <CustomerForm
        deliveryAddress={deliveryAddress}
        customer={customer}
        customers={customers}
        form={form}
        onFinish={onFinish}
        onSearchCustomer={onSearchCustomer}
      />

      <Button type='primary' block onClick={form.submit} loading={loading}>
        Update
      </Button>
    </>
  );
};

export default CustomerTab;

export const CREATE_USER_SUCCESS = 'Create successful employee';
export const UPDATE_USER_SUCCESS = 'Update successful employee';
export const CHANGE_PASSWORD_USER_SUCCESS =
  'Change password successful employee';

export const CREATE_SUPPLIER_SUCCESS = 'Create successful supplier';
export const DELETE_SUPPLIER_SUCCESS = 'Delete successful supplier';
export const UPDATE_SUPPLIER_SUCCESS = 'Update successful supplier';

export const CHANGE_PASSWORD_SUCCESS = 'Change password successfully';
export const UPDATE_INFORMATION_SUCCESS = 'Update information successfully';

export const CREATE_ORDER_SUCCESS = 'Create order successfully';
export const UPDATE_ORDER_SUCCESS = 'Update order successfully';
export const DELETE_ORDER_SUCCESS = 'Delete order successfully';
export const ADD_ORDER_TO_TRASH = 'The item has been moved to trash';
export const ORDER_TO_RESTORE = 'The item has been restore';
export const ORDER_TO_CLEANALL = 'Trash has been emptied';
export const SUBMIT_REQUEST_DELETE_ORDER_SUCCESS =
  'Submit request to delete order successfully';
export const ORDER_HAS_BEEN_DELETED = 'Order has been deleted';
export const NOT_HAVE_PERMISSION =
  'You do not have permission to access the site';
export const CANCELED_DELETE_ORDER = 'Canceled order successfully';
export const ORDER_NOT_FOUND = 'Order not found';

export const UPDATE_CUSTOMER_SUCCESS = 'Update customer successfully';

export const COVER_ORDER_EMPTY = 'Cover is not allowed to be empty';

export const DELETE_PRODUCT_SUCCESS = 'Delete successful product';

import _ from 'lodash';
import ORDER_STATUS from '@/constants/orderStatus';
import POSITIONS from '@/constants/positions';
import { useAppSelector, useDebounce, useAppDispatch } from '@/hooks';
import { ISupplierState } from '@/stores/supplier/type';
import { IOrder, ISupplier, IProduct } from '@/type';
import formatCurrency from '@/utils/formatCurrency';
import { setProduct } from '@/stores/product/reducer';
import getPrices from '@/utils/getPrices';
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter';
import {
  Divider,
  Form,
  FormInstance,
  Select,
  Input,
  Button,
  Space,
  AutoComplete,
  Row,
  Col,
} from 'antd';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

import {
  DatePickerCustom,
  InputNumberCustom,
  ProductContainer,
  DeleteIcon,
} from './style';

const { Option, OptGroup } = Select;

export interface IOrderItems {
  product: IProduct;
  price: IOrder['price'];
  name?: string;
  supplier?: string;
  cover?: string;
  size?: string;
}

export interface IOrderFormValues {
  orderItems: IOrderItems[];
  shippingFee: IOrder['shippingFee'];
  deliveryAddress: IOrder['deliveryAddress'];
  discount: IOrder['discount'];
  paidAmount: IOrder['paidAmount'];
  orderDate: IOrder['orderDate'];
  status: IOrder['status'];
  orderId: IOrder['orderId'];
  notes: IOrder['notes'];
  photos: string[];
}

export interface ISupplierGroupAlphabet {
  label: string;
  options: ISupplier[];
}

interface IProps {
  supplier: ISupplierState;
  order?: Partial<IOrder>;
  onFinish: (values: IOrderFormValues) => void;
  form: FormInstance<IOrderFormValues>;
  isUpdateForm?: boolean;
  onSetOrderFormValue?: (values: Partial<IOrderFormValues>) => void;
  onSetCurrentFormValues?: (values: Partial<IOrderFormValues>) => void;
  onSetSelectedOrder?: (values: Partial<IOrderFormValues>) => void;
  onSearchProducts: (name: string) => void;
}

const OrderForm: React.FC<IProps> = ({
  form,
  supplier,
  order,
  isUpdateForm = false,
  onFinish,
  onSetOrderFormValue,
  onSetCurrentFormValues,
  onSetSelectedOrder,
  onSearchProducts,
}) => {
  const [productName, setProductName] = useState('');
  const [productIndex, setProductIndex] = useState(0);
  const dispatch = useAppDispatch();

  const initialValues = {
    orderDate: moment(),
    status: ORDER_STATUS.PENDING,
    orderItems: [undefined],
  };

  const { auth, product, formValues } = useAppSelector((state) => state);
  const { user } = auth;

  const debounce = useDebounce(productName, 500);

  useEffect(() => {
    onSearchProducts(productName);
  }, [debounce]);

  useEffect(() => {
    const values = {
      shippingFee: order?.shippingFee,
      discount: order?.discount,
      orderItems: convertOrderItemsForm(order?.orderItems),
      orderDate: isUpdateForm ? moment(order?.orderDate) : undefined,
      paidAmount: order?.paidAmount,
      orderId: order?.orderId,
      status: order?.status,
      notes: order?.notes,
    };

    if (!isUpdateForm) {
      delete values.orderDate;
    }

    if (!values.orderItems?.length) {
      delete values.orderItems;
    }

    form.setFieldsValue(values);
  }, [order]);

  const convertOrderItemsForm = (orderItems: IOrderItems[] | undefined) => {
    if (orderItems) {
      return orderItems.map((item) => ({
        price: item?.price || 0,
        name: item?.product?.name,
        size: item?.product?.size,
        supplier: handleGetSupplier(item?.product?.supplier),
      }));
    }

    return undefined;
  };

  const handleGetSupplier = (supplier: ISupplier | string) => {
    if (typeof supplier === 'string') {
      return supplier;
    }

    if (typeof supplier === 'object') {
      return supplier._id;
    }
  };

  const convertOrderItemsObject = (orderItems: IOrderItems[] | undefined) => {
    if (orderItems) {
      return orderItems.map((item, index) => {
        return {
          price: item?.price || 0,
          product: {
            name: item?.name,
            supplier: item?.supplier,
            size: item?.size,
            cover: handleGetPhotos(index, item?.name || ''),
          },
        };
      });
    }

    return [];
  };

  const handleGetPhotos = (index: number, name: string): string => {
    if (formValues?.order?.orderItems?.length && name) {
      if (formValues.order.orderItems[index]?.product?.name === name) {
        return formValues.order.orderItems[index].product?.cover || '';
      }
      return '';
    }

    return '';
  };

  const convertGroupAlphabet = (arr: ISupplier[]) => {
    const dataGroup = arr.reduce(
      (acc: ISupplierGroupAlphabet[], cur: ISupplier) => {
        const firstCharacter: string = cur?.name[0];
        const indexByLabel = acc.findIndex((obj) => {
          return obj.label.toLowerCase() === firstCharacter.toLowerCase();
        });
        if (indexByLabel < 0) {
          acc.push({ label: firstCharacter.toUpperCase(), options: [cur] });
        } else {
          acc[indexByLabel].options.push(cur);
        }
        return acc;
      },
      [],
    );
    const dataSort = _.sortBy(dataGroup, ['label']);
    return dataSort;
  };

  const handleChange = (value: string) => {
    setProductName(value);
  };

  const handleSelect = (productId: string) => {
    const foundProduct = product.data[productId];

    if (foundProduct) {
      const { orderItems } = form.getFieldsValue();
      const orderItemsStore = formValues.order.orderItems;
      const selectedOrder = [...orderItems];
      const orderItemsObject = [];
      const { name, supplier, cover, size } = foundProduct;

      orderItems[productIndex] = {
        ...orderItems[productIndex],
        price: orderItems[productIndex].price,
        name,
        supplier: (supplier as ISupplier)._id,
        size,
      };

      selectedOrder[productIndex] = {
        price: orderItems[productIndex].price,
        product: { name, supplier: (supplier as ISupplier)._id, cover, size },
      };

      form.setFieldsValue({ orderItems });

      for (let index = 0; index < orderItemsStore.length; index++) {
        if (index === productIndex) {
          orderItemsObject.push({
            price: orderItemsStore[productIndex].price,
            product: {
              name,
              cover,
              supplier: (supplier as ISupplier)._id,
              size,
            },
          });
        } else {
          orderItemsObject.push(orderItemsStore[index]);
        }
      }

      if (onSetCurrentFormValues) {
        onSetCurrentFormValues({ orderItems: orderItemsObject });
      }

      if (onSetOrderFormValue) {
        onSetOrderFormValue({ orderItems: orderItemsObject });
      }

      if (onSetSelectedOrder) {
        onSetSelectedOrder({ orderItems: selectedOrder });
      }

      dispatch(setProduct({}));
    }
  };

  return (
    <Form
      layout='vertical'
      form={form}
      onFinish={onFinish}
      initialValues={initialValues}
      onValuesChange={(values) => {
        if (onSetOrderFormValue) {
          if (values.orderItems) {
            const orderItems = convertOrderItemsObject(
              form.getFieldsValue().orderItems,
            );

            onSetOrderFormValue({
              orderItems: orderItems as IOrderItems[],
            });
          } else {
            onSetOrderFormValue(values);
          }
        }
      }}
    >
      <Form.Item
        style={{ marginTop: '1.5rem' }}
        label='Order No'
        name='orderId'
        hasFeedback
        rules={[
          {
            max: 19,
            message: 'Order No at most 19 characters!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.List name='orderItems'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, index) => (
              <ProductContainer key={key}>
                <Space
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                  align='baseline'
                >
                  <Row justify='space-between'>
                    <Col span={11}>
                      <Form.Item
                        {...restField}
                        label='Name'
                        name={[name, 'name']}
                        fieldKey={[fieldKey, 'name']}
                        rules={[
                          { required: true, message: 'Please input name!' },
                        ]}
                      >
                        <AutoComplete
                          onChange={(text) => {
                            handleChange(text);
                            setProductIndex(index);
                          }}
                          onSelect={handleSelect}
                          style={{ width: '100%' }}
                        >
                          {Object.values(product.data).map((item) =>
                            item._id ? (
                              <Option value={item._id} key={item._id}>
                                {item.name} {item.size ? `[${item.size}]` : ''}
                              </Option>
                            ) : null,
                          )}
                        </AutoComplete>
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        {...restField}
                        label='Price ($)'
                        name={[name, 'price']}
                        fieldKey={[fieldKey, 'price']}
                        rules={[
                          { required: true, message: 'Please input Price' },
                        ]}
                      >
                        <InputNumberCustom
                          min={0}
                          formatter={formatCurrency}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={1}>
                      <DeleteIcon
                        onClick={() => {
                          if (form.getFieldsValue().orderItems.length > 1) {
                            remove(name);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </Space>

                <Space
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                  align='baseline'
                >
                  <Row justify='space-between'>
                    <Col span={11}>
                      <Form.Item
                        {...restField}
                        name={[name, 'supplier']}
                        fieldKey={[fieldKey, 'supplier']}
                        rules={[
                          {
                            required: true,
                            message: 'Please input supplier',
                          },
                        ]}
                      >
                        <Select
                          placeholder='Select supplier'
                          showSearch
                          optionFilterProp='children'
                        >
                          {convertGroupAlphabet(
                            Object.values(supplier.data),
                          ).map((supplierGroup) => {
                            return (
                              <OptGroup
                                label={supplierGroup.label}
                                key={supplierGroup.label}
                              >
                                {supplierGroup.options.map((option) => {
                                  return (
                                    <Option value={option._id} key={option._id}>
                                      {option.name}
                                    </Option>
                                  );
                                })}
                              </OptGroup>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        {...restField}
                        name={[name, 'size']}
                        fieldKey={[fieldKey, 'size']}
                      >
                        <Input placeholder='Size' />
                      </Form.Item>
                    </Col>

                    <Col span={1} />
                  </Row>
                </Space>

                <Divider style={{ marginTop: 0 }} />
              </ProductContainer>
            ))}
            <Form.Item>
              <Button
                type='dashed'
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Product
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item
        label='Shipping free ($)'
        name='shippingFee'
        rules={[
          {
            required: true,
            message: 'Please input shipping fee!',
          },
        ]}
        hasFeedback
      >
        <InputNumberCustom min={0} formatter={formatCurrency} />
      </Form.Item>

      <Form.Item label='Discount ($)' shouldUpdate>
        {() => {
          const { orderItems } = form.getFieldsValue();

          return (
            <Form.Item name='discount' hasFeedback>
              <InputNumberCustom
                min={0}
                max={getPrices(orderItems)}
                formatter={formatCurrency}
              />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item label='Total ($)' shouldUpdate>
        {() => {
          const {
            orderItems,
            shippingFee = 0,
            discount = 0,
          } = form.getFieldsValue();

          return (
            <InputNumberCustom
              disabled
              value={getPrices(orderItems) + shippingFee - discount}
              formatter={formatCurrency}
            />
          );
        }}
      </Form.Item>

      <Divider />

      <Form.Item label='Paid amount ($)' shouldUpdate>
        {() => {
          const {
            orderItems,
            shippingFee = 0,
            discount = 0,
          } = form.getFieldsValue();

          const totalAmount = getPrices(orderItems) + shippingFee - discount;

          return (
            <Form.Item name='paidAmount' hasFeedback>
              <InputNumberCustom max={totalAmount} formatter={formatCurrency} />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item label='Unpaid amount ($)' shouldUpdate hasFeedback>
        {() => {
          const {
            orderItems,
            shippingFee = 0,
            discount = 0,
            paidAmount = 0,
          } = form.getFieldsValue();

          return (
            <InputNumberCustom
              disabled
              value={
                getPrices(orderItems) + shippingFee - discount - paidAmount
              }
              formatter={formatCurrency}
            />
          );
        }}
      </Form.Item>

      <Divider />

      <Form.Item label='Status' shouldUpdate>
        {() => {
          const {
            orderItems,
            shippingFee = 0,
            discount = 0,
            paidAmount = 0,
          } = form.getFieldsValue();
          const unpaidAmount =
            getPrices(orderItems) + shippingFee - discount - paidAmount;
          if (unpaidAmount > 0 && user.role?.name !== POSITIONS.ADMIN) {
            setTimeout(() => {
              form.setFieldsValue({ status: ORDER_STATUS.PENDING });
            }, 500);
          }

          return (
            <Form.Item name='status'>
              <Select placeholder='Select status'>
                {Object.values(ORDER_STATUS).map((status, index) => {
                  if (
                    (unpaidAmount > 0 && status === ORDER_STATUS.PENDING) ||
                    (unpaidAmount === 0 &&
                      (status === ORDER_STATUS.PENDING ||
                        status === ORDER_STATUS.SHIPPING ||
                        (status === ORDER_STATUS.DONE && isUpdateForm))) ||
                    (user.role?.name === POSITIONS.ADMIN &&
                      status !== ORDER_STATUS.OVER)
                  ) {
                    return (
                      <Option value={status} key={index.toString()}>
                        {capitalizeFirstLetter(status)}
                      </Option>
                    );
                  }
                })}
              </Select>
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item
        label='Order date'
        name='orderDate'
        rules={[
          {
            required: true,
            message: 'Please input order date!',
          },
        ]}
        hasFeedback
      >
        <DatePickerCustom />
      </Form.Item>

      <Form.Item label='Notes' name='notes' hasFeedback>
        <Input.TextArea />
      </Form.Item>
    </Form>
  );
};

export default OrderForm;

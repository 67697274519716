import apiRequest from '@/utils/apiRequest';
import {
  ILoginRequest,
  ILoginResponse,
  IGetMeResponse,
  IChangePasswordRequest,
  IChangePasswordResponse,
  IUpdateInformationRequest,
  IUpdateInformationResponse,
} from '@/stores/auth/type';

const endpoint = '/auth';

export const loginApi = (body: ILoginRequest): Promise<ILoginResponse> => {
  return apiRequest.post(`${endpoint}/login`, body);
};

export const getMeApi = (): Promise<IGetMeResponse> => {
  return apiRequest.get(`${endpoint}/me`);
};

export const changePasswordApi = (
  body: IChangePasswordRequest,
): Promise<IChangePasswordResponse> => {
  return apiRequest.put(`${endpoint}/change-password`, body);
};

export const updateInformationApi = (
  body: IUpdateInformationRequest,
): Promise<IUpdateInformationResponse> => {
  return apiRequest.put(`${endpoint}/update-information`, body);
};

import { createSlice } from '@reduxjs/toolkit';
import { ICustomerState } from './type';
import {
  getCustomers,
  createAndUpdateCustomer,
  createCustomer,
} from './actions';

const initialState: ICustomerState = {
  data: [],
  isSubmitting: false,
};

const customerReducer = createSlice({
  name: 'customer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomers.fulfilled, (state, { payload }) => {
      state.data = payload;
    });

    builder.addCase(createAndUpdateCustomer.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(createAndUpdateCustomer.fulfilled, (state) => {
      state.isSubmitting = false;
    });
    builder.addCase(createAndUpdateCustomer.rejected, (state) => {
      state.isSubmitting = false;
    });

    builder.addCase(createCustomer.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(createCustomer.fulfilled, (state) => {
      state.isSubmitting = false;
    });
    builder.addCase(createCustomer.rejected, (state) => {
      state.isSubmitting = false;
    });
  },
});

export default customerReducer.reducer;

import React, { useContext, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { IUploadMultipleRequest } from '@/stores/upload/type';
import { MenuContext } from '@/contexts/MenuContext';
import ROUTES from '@/constants/routes';
import replaceRoutePath from '@/utils/replaceRoutePath';
import { ICustomer, IOrderItem, ISupplier } from '@/type';
import { getSuppliers } from '@/stores/supplier/actions';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { getOrder, updateOrder } from '@/stores/order/actions';
import { uploadMultiple, uploadSingle } from '@/stores/upload/actions';
import { IGetCustomersRequest } from '@/stores/customer/type';
import {
  createAndUpdateCustomer,
  getCustomers,
} from '@/stores/customer/actions';
import {
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_ORDER_SUCCESS,
} from '@/constants/toastStatus';
import { IUpdateOrderPayload } from '@/stores/order/type';
import { IOrderItems, IOrderFormValues } from '@/components';
import { getProducts } from '@/stores/product/actions';

import { OrderTab, CustomerTab, PhotosTab, Wrapper } from './components';

const { TabPane } = Tabs;

const UpdateOrder: React.FC = () => {
  const { setBackToRoute } = useContext(MenuContext);
  const params = useParams<{ id: string }>();
  const { supplier, order, customer } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const [selectedOrder, setSelectedOrder] =
    useState<Partial<IOrderFormValues>>();
  const currentOrder = order.data[params.id];
  const [photos, setPhotos] = useState<string[]>([]);
  const [isUploadPhotos, setIsUploadPhotos] = useState<boolean>(false);
  const [isUploadProductPhotos, setIsUploadProductPhotos] =
    useState<boolean>(false);
  const [isUpdateUploadPhotos, setIsUpdateUploadPhotos] =
    useState<boolean>(false);
  const [isUpdateProductPhotos, setIsUpdateProductPhotos] =
    useState<boolean>(false);

  useEffect(() => {
    if (currentOrder?.photos) {
      setPhotos(currentOrder.photos);
    }
  }, [currentOrder?.photos]);

  useEffect(() => {
    dispatch(getSuppliers({ limit: 1000 }));
    dispatch(getOrder({ _id: params.id }));
  }, []);

  useEffect(() => {
    setBackToRoute(
      replaceRoutePath({
        originalPath: ROUTES.ORDER_INFORMATION_PAGE.path,
        replacementValue: params.id,
      }),
    );
  }, []);

  const handleCreateAndUpdateCustomer = (
    values: ICustomer & { updateCustomer: boolean },
  ) => {
    const { updateCustomer, ...customerData } = values;
    if (updateCustomer) {
      dispatch(createAndUpdateCustomer(customerData))
        .then(unwrapResult)
        .then((res) => {
          handleUpdateOrder(
            {
              customer: res._id,
              deliveryAddress: customerData.deliveryAddress,
            },
            false,
            () => {
              toast.success(UPDATE_CUSTOMER_SUCCESS);
            },
          );
        });
    } else {
      dispatch(
        updateOrder({
          orderId: params.id,
          payload: {
            customerCopy: customerData,
            deliveryAddress: customerData.deliveryAddress,
          },
        }),
      )
        .then(unwrapResult)
        .then(() => {
          toast.success(UPDATE_CUSTOMER_SUCCESS);
        });
    }
  };

  const handleSearchCustomer = (values: IGetCustomersRequest) => {
    dispatch(getCustomers(values));
  };

  const handleUpdateOrder = (
    values: IUpdateOrderPayload,
    showToast = true,
    callback?: () => void,
  ) => {
    const orderItems: IOrderItem[] = [];

    if (values?.orderItems) {
      setIsUpdateProductPhotos(true);
      values.orderItems.map((item, index) => {
        const { price, name, supplier, size } = item as IOrderItems;

        if (price && name && supplier) {
          orderItems.push({
            price,
            product: {
              name,
              supplier,
              cover: handleGetPhotos(index, name),
              size: size || '',
            },
          });
        }
      });
    }

    if (orderItems.length) {
      values.orderItems = orderItems;
    }

    if (values?.photos) {
      setIsUpdateUploadPhotos(true);
    }

    dispatch(updateOrder({ orderId: params.id, payload: values }))
      .then(unwrapResult)
      .then(() => {
        if (showToast) {
          toast.success(UPDATE_ORDER_SUCCESS);
        }

        if (values?.photos) {
          setIsUpdateUploadPhotos(false);
        }
        if (values?.orderItems) {
          setIsUpdateProductPhotos(false);
        }

        if (callback) {
          callback();
        }
      });
  };

  const handleGetPhotos = (index: number, name: string): string => {
    const currentProduct = currentOrder?.orderItems[index]?.product;

    if (name === currentProduct?.name) {
      return currentOrder?.orderItems[index]?.product?.cover || '';
    }
    if (selectedOrder?.orderItems?.length) {
      const foundOrderItem = selectedOrder.orderItems.find((item) => {
        return item.product?.name === name;
      });

      if (foundOrderItem) {
        return foundOrderItem.product.cover || '';
      }
    }

    return '';
  };

  const handleOrderItemPhotos = (productName: string, formData: FormData) => {
    setIsUploadProductPhotos(true);
    dispatch(uploadSingle(formData))
      .then(unwrapResult)
      .then((res) => {
        setIsUploadProductPhotos(false);
        const orderItems = currentOrder.orderItems.map((item) => {
          if (item?.product?.name === productName) {
            return {
              ...item,
              product: {
                ...item.product,
                cover: res.url,
                supplier: (item.product.supplier as ISupplier)._id,
              },
            };
          }
          return {
            ...item,
            product: {
              ...item.product,
              supplier: (item.product.supplier as ISupplier)._id,
            },
          };
        });

        handleUpdateOrder({ orderItems }, false);
      });
  };

  const handleSearchProducts = (name: string) => {
    if (name) dispatch(getProducts({ limit: 1000, name }));
  };

  const handleUploadOrderPhotos = (formData: IUploadMultipleRequest) => {
    setIsUploadPhotos(true);
    dispatch(uploadMultiple(formData))
      .then(unwrapResult)
      .then((urls) => {
        setIsUploadPhotos(false);
        setPhotos([...photos, ...urls]);
        handleUpdateOrder({ photos: [...photos, ...urls] }, false);
      });
  };

  const handleDeleteOrderPhoto = (file: string) => {
    setIsUploadPhotos(true);
    const customPhotos = photos?.filter((photo) => {
      if (photo !== file) {
        return photo;
      }
    });
    setPhotos(customPhotos);
    handleUpdateOrder({ photos: customPhotos }, false);
    setIsUploadPhotos(false);
  };

  if (!currentOrder) {
    return null;
  }

  const customerData = currentOrder.customerCopy ?? currentOrder.customer;

  if (!customerData) {
    return null;
  }

  const tabs = {
    ORDER: {
      name: 'Order',
      key: 'order',
      component: (
        <OrderTab
          supplier={supplier}
          order={currentOrder}
          loading={order.isSubmitting}
          onFinish={handleUpdateOrder}
          onSetSelectedOrder={setSelectedOrder}
          onSearchProducts={handleSearchProducts}
        />
      ),
    },
    CUSTOMER: {
      name: 'Customer',
      key: 'customer',
      component: (
        <CustomerTab
          deliveryAddress={currentOrder?.deliveryAddress}
          customer={customerData}
          customers={customer.data}
          loading={customer.isSubmitting || order.isSubmitting}
          onFinish={handleCreateAndUpdateCustomer}
          onSearchCustomer={handleSearchCustomer}
        />
      ),
    },
    PHOTOS: {
      name: 'Photos',
      key: 'photos',
      component: (
        <PhotosTab
          photos={photos}
          orderItems={currentOrder?.orderItems}
          isUploadProductPhotos={isUploadProductPhotos || isUpdateProductPhotos}
          isUploadPhotos={isUploadPhotos || isUpdateUploadPhotos}
          onUpload={handleOrderItemPhotos}
          onUploadOrderPhotos={handleUploadOrderPhotos}
          onDeleteOrderPhoto={handleDeleteOrderPhoto}
        />
      ),
    },
  };

  return (
    <Wrapper>
      <Tabs defaultActiveKey='1'>
        {Object.values(tabs).map((tab) => (
          <TabPane tab={tab.name} key={tab.key}>
            {tab.component}
          </TabPane>
        ))}
      </Tabs>
    </Wrapper>
  );
};

export default UpdateOrder;

import React, { useContext } from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { MenuContext } from '@/contexts/MenuContext';
import ROUTES from '@/constants/routes';

import { Wrapper } from './style';

const BreadcrumbComponent: React.FC = () => {
  const routes = Object.values(ROUTES);
  const breadcrumbs = useBreadcrumbs(routes);
  const { setBackToRoute } = useContext(MenuContext);

  const handleClickBreadcrumb = (pathname: string) => {
    if (pathname === ROUTES.HOME_PAGE.path) {
      setBackToRoute('');
    }
  };
  return (
    <Wrapper>
      <Breadcrumb>
        {breadcrumbs.map((breadcrumbData, index) => (
          <Breadcrumb.Item
            key={index.toString()}
            onClick={() => {
              handleClickBreadcrumb(breadcrumbData.match.url);
            }}
          >
            <Link to={{ pathname: breadcrumbData.match.url }}>
              {breadcrumbData.breadcrumb}
            </Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </Wrapper>
  );
};

export default BreadcrumbComponent;

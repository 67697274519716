import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';

import LoadRoutes from '@/routes';
import { persistor, store } from '@/stores/configureStore';
import ThemeProvider from '@/ThemeProvider';
import { WrapperLayout } from '@/layouts';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider>
          <WrapperLayout>
            <ToastContainer />
            <LoadRoutes />
          </WrapperLayout>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;

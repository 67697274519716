import React from 'react';
import { Modal, Form, Input } from 'antd';

import { Description, FormContainer } from './style';

export interface IStaffPasswordFormValues {
  newPassword: string;
  confirmNewPassword: string;
}

interface IProps {
  visible: boolean;
  fullName: string;
  onCancel: () => void;
  onFinish: (value: IStaffPasswordFormValues) => void;
}

const ChangeStaffPasswordModal: React.FC<IProps> = ({
  visible,
  fullName,
  onCancel,
  onFinish,
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title='Change employee’s password'
      okText='Change password'
      cancelText='Close'
      onCancel={onCancel}
      onOk={form.submit}
    >
      <Description>
        You are changing the password for <strong>{`${fullName} `} </strong>
        account.
      </Description>

      <FormContainer>
        <Form layout='vertical' form={form} onFinish={onFinish}>
          <Form.Item
            label='New password'
            name='newPassword'
            rules={[
              {
                required: true,
                message: 'Please input your new password!',
              },
              {
                min: 6,
                message: 'Password must be at least 6 characters!',
              },
            ]}
            hasFeedback
          >
            <Input.Password autoComplete='new-password' />
          </Form.Item>

          <Form.Item
            label='Confirm new password'
            name='confirmNewPassword'
            rules={[
              {
                required: true,
                message: 'Please input confirm new password!',
              },
              {
                min: 6,
                message: 'Password must be at least 6 characters!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    'The two passwords that you entered do not match!',
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password autoComplete='new-password' />
          </Form.Item>
        </Form>
      </FormContainer>
    </Modal>
  );
};

export default ChangeStaffPasswordModal;

import styled from 'styled-components';
import { MinusCircleOutlined } from '@ant-design/icons';
import { InputNumber, DatePicker } from 'antd';

export const InputNumberCustom = styled(InputNumber)`
  width: 100%;
`;

export const DatePickerCustom = styled(DatePicker)`
  width: 100%;
`;

export const ProductContainer = styled.div`
  width: 100%;

  .ant-space-item {
    width: 100%;
  }
  .ant-divider {
    border-width: 2px;
  }
`;

export const DeleteIcon = styled(MinusCircleOutlined)`
  position: relative;
  top: 38px;
`;

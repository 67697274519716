import React, { useEffect, useContext, useState } from 'react';
import { MenuContext } from '@/contexts/MenuContext';
import ROUTES from '@/constants/routes';
import { getOrders, updateOrder } from '@/stores/order/actions';
import { useAppDispatch } from '@/hooks';
import DELETE_STATUS from '@/constants/deleteStatus';
import configs from '@/configs';
import { unwrapResult } from '@reduxjs/toolkit';
import { OrderCard } from '@/components';
import { useAppSelector, useWindowSize } from '@/hooks';
import { Empty, Spin, Modal } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { HEADER_HEIGHT, BREADCRUMB_HEIGHT } from '@/constants/componentSize';
import { toast } from 'react-toastify';
import {
  CANCELED_DELETE_ORDER,
  ADD_ORDER_TO_TRASH,
} from '@/constants/toastStatus';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { IDeleteStatus, IOrder } from '@/type';

import { ActionButtons } from './components';

const OrderManagement: React.FC = () => {
  const dispatch = useAppDispatch();
  const { setBackToRoute } = useContext(MenuContext);
  const [currentPage, setCurrentPage] = useState(1);
  const { order } = useAppSelector((state) => state);
  const orders = Object.values(order.data);
  const size = useWindowSize();
  const { isSubmitting } = order;
  const [selectedOrder, setSelectedOrder] = useState<IOrder['_id']>();

  useEffect(() => {
    setBackToRoute(ROUTES.HOME_PAGE.path);
    handleGetOrders(1);
  }, []);

  const handleGetOrders = (pageIndex: number) => {
    dispatch(
      getOrders({
        limit: configs.limit,
        page: pageIndex,
        deleteStatus: DELETE_STATUS.PENDING,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        setCurrentPage(pageIndex + 1);
      });
  };

  const handleFetchMoreOrders = () => {
    handleGetOrders(currentPage);
  };

  const handleUpdateOrder = (
    orderId: string,
    deleteStatus: IDeleteStatus,
  ): void => {
    dispatch(
      updateOrder({
        orderId,
        payload: { deleteStatus },
      }),
    )
      .then(unwrapResult)
      .then(() => {
        if (deleteStatus === DELETE_STATUS.TRASH) {
          toast.success(ADD_ORDER_TO_TRASH);
        }
        if (deleteStatus === DELETE_STATUS.CANCEL) {
          toast.success(CANCELED_DELETE_ORDER);
        }
      });
  };

  const handleConfirmDeleteModal = (orderId: string) => {
    Modal.confirm({
      title: 'Are you sure you want to move this order to trash?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: () => {
        handleUpdateOrder(orderId, DELETE_STATUS.TRASH);
      },
    });
  };

  const handleCancel = (orderId: string) => {
    handleUpdateOrder(orderId, DELETE_STATUS.CANCEL);
  };

  return (
    <Spin spinning={order.isFetching} style={{ marginTop: 20 }}>
      {currentPage !== 1 && (
        <InfiniteScroll
          dataLength={orders.length}
          next={handleFetchMoreOrders}
          hasMore={order.hasMore}
          height={size.height - HEADER_HEIGHT - BREADCRUMB_HEIGHT}
          loader={
            <Spin
              style={{ width: '100%', marginTop: 20 }}
              spinning={order.isFetching}
            />
          }
        >
          {orders.length ? (
            orders.map((order) => (
              <div key={order._id}>
                <OrderCard order={order} />
                <ActionButtons
                  isSubmitting={isSubmitting && selectedOrder === order._id}
                  onDelete={() => {
                    handleConfirmDeleteModal(order._id);
                  }}
                  onCancel={() => {
                    setSelectedOrder(order._id);
                    handleCancel(order._id);
                  }}
                />
              </div>
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </InfiniteScroll>
      )}
    </Spin>
  );
};

export default OrderManagement;

import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/stores/combineReducers';
import configs from '@/configs';

import { IUserState } from './type';
import { getUsers, updateUser, createUser, changePassword } from './actions';

const initialState: IUserState = {
  data: {},
  isFetching: false,
  isUpdating: false,
  isSubmitting: false,
  hasMore: true,
};

const usersReducer = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.isFetching = true;
      state.hasMore = true;
    });
    builder.addCase(getUsers.fulfilled, (state, { payload, meta }) => {
      const payloadCustom = _.keyBy(payload, '_id');

      state.hasMore = !(payload.length < configs.limit);
      if (meta.arg.page === 1) {
        state.data = payloadCustom;
      } else {
        state.data = Object.assign(state.data, payloadCustom);
      }

      state.isFetching = false;
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(updateUser.pending, (state) => {
      state.isUpdating = true;
    });
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      const { _id } = payload;
      state.data[_id] = payload;
      state.isUpdating = false;
    });
    builder.addCase(updateUser.rejected, (state) => {
      state.isUpdating = false;
    });

    builder.addCase(createUser.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      const { _id } = payload;
      state.data = { [_id]: payload, ...state.data };
      state.isSubmitting = false;
    });
    builder.addCase(createUser.rejected, (state) => {
      state.isSubmitting = false;
    });

    builder.addCase(changePassword.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      state.isSubmitting = false;
    });
    builder.addCase(changePassword.rejected, (state) => {
      state.isSubmitting = false;
    });
  },
});

export const userState = (state: RootState): IUserState => state.user;
export default usersReducer.reducer;

import React, { useContext, useEffect } from 'react';
import { Tabs } from 'antd';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import ROUTES from '@/constants/routes';
import { useAppDispatch, useWindowSize } from '@/hooks';
import { MenuContext } from '@/contexts/MenuContext';
import { getOrder } from '@/stores/order/actions';
import { HEADER_HEIGHT, BREADCRUMB_HEIGHT } from '@/constants/componentSize';

import { Wrapper, Content } from './style';

const { TabPane } = Tabs;

const tabs = {
  INFORMATION: {
    name: 'Information',
    key: 'information',
  },
  CHAT: {
    name: 'Chat',
    key: 'chat',
  },
  ACTIVITY_HISTORY: {
    name: 'Activity history',
    key: 'activity-history',
  },
};

interface IProps {
  children: React.ReactNode;
}

const OrderLayout: React.FC<IProps> = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const key = location.pathname.split('/')[2];
  const { setBackToRoute } = useContext(MenuContext);
  const { height } = useWindowSize();

  const handleChange = (key: string) => {
    history.push(`/${params.id}/${key}`);
  };

  useEffect(() => {
    setBackToRoute(ROUTES.HOME_PAGE.path);
    dispatch(
      getOrder({
        _id: params.id,
      }),
    );
  }, [params.id]);

  return (
    <Wrapper style={{ height: height - HEADER_HEIGHT - BREADCRUMB_HEIGHT }}>
      <Tabs animated={false} activeKey={key} onChange={handleChange}>
        {Object.values(tabs).map((tab) => (
          <TabPane tab={tab.name} key={tab.key} />
        ))}
      </Tabs>

      <Content>{children}</Content>
    </Wrapper>
  );
};

export default OrderLayout;

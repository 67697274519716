import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spin, Empty } from 'antd';

import { MenuContext } from '@/contexts/MenuContext';
import ROUTES from '@/constants/routes';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { getSuppliers, deleteSupplier } from '@/stores/supplier/actions';
import config from '@/configs';
import { ISupplier } from '@/type';
import { SupplierItem } from '@/components';
import { ActionButtons } from './components';

const Suppliers: React.FC = () => {
  const { setBackToRoute } = useContext(MenuContext);
  const { supplier } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [supplierSelected, setSupplierSelected] = useState<ISupplier>();
  const { limit } = config;

  useEffect(() => {
    setBackToRoute(ROUTES.HOME_PAGE.path);
    fetchMoreSuppliersData();
  }, []);

  const fetchMoreSuppliersData = () => {
    dispatch(getSuppliers({ limit, page: currentPage }));
    setCurrentPage(currentPage + 1);
  };

  const handleSelectSupplier = (supplierId: ISupplier['_id']): void => {
    setSupplierSelected(supplier.data[supplierId]);
  };

  const handleDeleteSupplier = () => {
    if (supplierSelected)
      dispatch(deleteSupplier({ _id: supplierSelected?._id }));
  };

  return (
    <>
      <ActionButtons />
      <InfiniteScroll
        dataLength={Object.keys(supplier.data).length}
        next={fetchMoreSuppliersData}
        hasMore={supplier.hasMore}
        loader={
          <Spin
            style={{ width: '100%', marginTop: 20 }}
            spinning={supplier.isFetching}
          />
        }
      >
        {Object.values(supplier.data).length ? (
          Object.values(supplier.data).map((supplier, index) => (
            <SupplierItem
              supplierSelected={supplierSelected}
              supplier={supplier}
              key={index}
              handleSelectSupplier={handleSelectSupplier}
              handleDeleteSupplier={handleDeleteSupplier}
            />
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </InfiniteScroll>
    </>
  );
};

export default Suppliers;

import { deleteProductApi, getProductsApi } from '@/apis/product';
import { DELETE_PRODUCT_SUCCESS } from '@/constants/toastStatus';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  IDeleteProductRequest,
  IDeleteProductResponse,
  IGetProductsRequest,
  IGetProductsReturn,
} from './type';

export const getProducts = createAsyncThunk<
  IGetProductsReturn,
  IGetProductsRequest
>('product/getProducts', async (body, { rejectWithValue }) => {
  try {
    return await getProductsApi(body);
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

export const deleteProduct = createAsyncThunk<
  IDeleteProductResponse,
  IDeleteProductRequest
>('product/deleteProduct', async (body, { rejectWithValue }) => {
  try {
    const res = await deleteProductApi(body);
    toast.success(DELETE_PRODUCT_SUCCESS);
    return res;
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';

export const Wrapper = styled.div`
  width: 100%;
  margin: 1rem 0;

  .ant-collapse-header {
    padding: 0.6rem 1rem !important;
  }
`;

export const Photo = styled.div`
  position: relative;
  img {
    width: 100%;
    border-radius: 0.25rem;
  }
`;

export const DeleteIcon = styled(DeleteOutlined)`
  position: absolute;
  top: 0;
  right: 0;
  background: ${({ theme }) => theme.color.danger};
  color: ${({ theme }) => theme.color.secondary};
  padding: 0.5rem;
  border-radius: 0 0 0 1rem;
`;

export const CustomFileUpload = styled.div`
  width: 90px;
  height: 90px;
  border: 1px dashed ${({ theme }) => theme.color.border};
  background-color: ${({ theme }) => theme.color.inputColor};
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  display: flex;
  align-item: center;
  justify-content: center;
  input[type='file'] {
    display: none;
  }
  label {
    display: flex;
    flex-direction: column;
    align-item: center;
    justify-content: center;
    div {
      margin-top: 0.5rem;
    }
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 0.375rem));

  @media screen and (min-width: 480px) {
    grid-template-columns: repeat(6, calc(15% - 0.375rem));
  }

  grid-gap: 0.5rem;

  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    width: 100%;
    padding-top: 100%;
    height: auto;
    position: relative;
    margin: 0;

    .ant-upload {
      position: absolute;
      top: calc(50% - 22px);
      left: 0;
      padding-top: 0;
      height: auto;
      width: 100%;
    }
  }
`;

export const EmptyContainer = styled.div`
  padding: 0;

  .ant-empty {
    margin: 1rem 0 !important;
  }
`;

import React, { useState, useContext } from 'react';
import { Menu, Dropdown } from 'antd';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '@/hooks';
import { Notifications, Messages } from '@/components';
import ROUTES from '@/constants/routes';
import { MenuContext } from '@/contexts/MenuContext';
import avatarDefault from '@/assets/images/avatarDefault.png';
import { useAppSelector } from '@/hooks';
import { logOut } from '@/stores/auth/reducer';
import POSITIONS from '@/constants/positions';
import { INotificationState } from '@/stores/notification/type';
import { IMessageState } from '@/stores/message/type';
import configs from '@/configs';

import {
  HeaderWrapper,
  Avatar,
  IconContainer,
  Count,
  IconNotify,
  IconMessages,
  LeftContainer,
  RightContainer,
  IconBack,
  DropdownContainer,
  ItemText,
  MenuContainer,
  CallBackButton,
} from './style';

interface IProps {
  notification: INotificationState;
  message: IMessageState;
  fetchMoreNotificationsData: () => void;
  fetchMoreMessagesData: () => void;
  handleViewUnreadMessages: () => void;
  handleViewAllMessages: () => void;
  handleSetReadMessage: (_id: string) => void;
  handleGetNotifications: () => void;
  handleGetMessages: () => void;
  handleSetReadNotification: (_id: string) => void;
  handleViewAllNotifications: () => void;
  handleViewUnreadNotifications: () => void;
}

const Header: React.FC<IProps> = ({
  notification,
  message,
  fetchMoreMessagesData,
  fetchMoreNotificationsData,
  handleViewUnreadMessages,
  handleViewAllMessages,
  handleSetReadMessage,
  handleGetNotifications,
  handleGetMessages,
  handleSetReadNotification,
  handleViewAllNotifications,
  handleViewUnreadNotifications,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isShowNotification, setIsShowNotification] = useState<boolean>(false);
  const [isShowMessages, setIsShowMessages] = useState<boolean>(false);
  const { backToRoute, setBackToRoute } = useContext(MenuContext);
  const { auth } = useAppSelector((state) => state);
  const roleName = auth.user.role?.name;
  const allowedSee =
    roleName === POSITIONS.ADMIN || roleName === POSITIONS.MANAGER;

  const onClickNotifications = () => {
    if (!isShowNotification) {
      handleGetNotifications();
    }
    setIsShowNotification(!isShowNotification);
    setIsShowMessages(false);
  };

  const onClickMessages = () => {
    if (!isShowMessages) {
      handleGetMessages();
    }
    setIsShowMessages(!isShowMessages);
    setIsShowNotification(false);
  };

  const handleClickMenu = ({ key }: { key: string }) => {
    if (key === ROUTES.LOGIN_PAGE.path) {
      dispatch(logOut());
    }

    history.push(key);
    handleCloseDropdown();
    setBackToRoute('');
  };

  const handleClickBackButton = () => {
    if (backToRoute !== configs.reRender) {
      history.push(backToRoute);
    }

    handleCloseDropdown();
    setBackToRoute('');
  };

  const handleCloseDropdown = () => {
    setIsShowMessages(false);
    setIsShowNotification(false);
  };

  const menu = (
    <MenuContainer onClick={handleClickMenu}>
      <Menu.Item key={ROUTES.ACCOUNT_PAGE.path}>
        <ItemText>Account</ItemText>
      </Menu.Item>
      <Menu.Item key={ROUTES.CHANGE_PASSWORD_PAGE.path}>
        <ItemText>Change password</ItemText>
      </Menu.Item>
      <Menu.Item key={ROUTES.EMPLOYEES_PAGE.path} hidden={!allowedSee}>
        <ItemText>Employees</ItemText>
      </Menu.Item>
      <Menu.Item key={ROUTES.SUPPLIERS_PAGE.path} hidden={!allowedSee}>
        <ItemText>Suppliers</ItemText>
      </Menu.Item>
      <Menu.Item key={ROUTES.ORDER_MANAGEMENT_PAGE.path} hidden={!allowedSee}>
        <ItemText>Order management</ItemText>
      </Menu.Item>
      <Menu.Item key={ROUTES.PRODUCT_PAGE.path} hidden={!allowedSee}>
        <ItemText>Products</ItemText>
      </Menu.Item>
      <Menu.Item key={ROUTES.TRASH_PAGE.path} hidden={!allowedSee}>
        <ItemText>Trash</ItemText>
      </Menu.Item>
      <Menu.Item key={ROUTES.LOGIN_PAGE.path}>
        <ItemText>Logout</ItemText>
      </Menu.Item>
    </MenuContainer>
  );

  return (
    <HeaderWrapper>
      <LeftContainer>
        <CallBackButton>
          <IconBack hidden={!backToRoute} onClick={handleClickBackButton} />
        </CallBackButton>
      </LeftContainer>
      <RightContainer>
        <IconContainer onClick={onClickNotifications}>
          <IconNotify $isShowNotification={isShowNotification} />
          {notification.totalUnread ? (
            <Count>{notification.totalUnread}</Count>
          ) : null}
        </IconContainer>
        <IconContainer onClick={onClickMessages}>
          <IconMessages $isShowMessages={isShowMessages} />
          {message.totalUnread ? <Count>{message.totalUnread}</Count> : null}
        </IconContainer>

        <Dropdown overlay={menu} trigger={['click']}>
          <DropdownContainer
            className='ant-dropdown-link'
            onClick={(e) => e.preventDefault()}
          >
            <Avatar
              width={42}
              height={42}
              preview={false}
              src={auth.user.cover || avatarDefault}
            />
          </DropdownContainer>
        </Dropdown>
      </RightContainer>

      {isShowNotification && (
        <Notifications
          notification={notification}
          allowedSee={allowedSee}
          fetchMoreData={fetchMoreNotificationsData}
          handleSetRead={handleSetReadNotification}
          handleViewAll={handleViewAllNotifications}
          handleViewUnread={handleViewUnreadNotifications}
          handleCloseDropdown={handleCloseDropdown}
        />
      )}
      {isShowMessages && (
        <Messages
          message={message}
          fetchMoreData={fetchMoreMessagesData}
          handleViewUnread={handleViewUnreadMessages}
          handleViewAll={handleViewAllMessages}
          handleSetRead={handleSetReadMessage}
          handleCloseDropdown={handleCloseDropdown}
        />
      )}
    </HeaderWrapper>
  );
};

export default Header;

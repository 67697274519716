import apiRequest from '@/utils/apiRequest';
import {
  IGetUsersRequest,
  IGetUsersResponse,
  IUpdateUserRequest,
  IUpdateUserResponse,
  ICreateUserRequest,
  ICreateUserResponse,
  IChangePasswordUserRequest,
  IChangePasswordUserResponse,
} from '@/stores/user/type';

const endpoint = '/users';

export const getUsersApi = (
  query: IGetUsersRequest,
): Promise<IGetUsersResponse> => {
  return apiRequest.get(endpoint, { params: query });
};

export const updateUserApi = (
  body: IUpdateUserRequest,
): Promise<IUpdateUserResponse> => {
  const { _id, payload } = body;
  return apiRequest.put(`${endpoint}/${_id}`, payload);
};

export const createUserApi = (
  body: ICreateUserRequest,
): Promise<ICreateUserResponse> => {
  return apiRequest.post(endpoint, body);
};

export const changePasswordUserApi = (
  body: IChangePasswordUserRequest,
): Promise<IChangePasswordUserResponse> => {
  const { payload, _id } = body;
  return apiRequest.put(`${endpoint}/${_id}/change-password`, {
    ...payload,
  });
};

import { createSlice } from '@reduxjs/toolkit';
import apiRequest from '@/utils/apiRequest';
import { REHYDRATE } from 'redux-persist/es/constants';

import { IAuthState } from './type';
import { login, getMe, updateInformation } from './actions';
import { IRehydrateAction } from './type';

const initialState: IAuthState = {
  user: {},
  token: '',
  isAuthenticate: false,
};

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut(state) {
      state.user = {};
      state.token = '';
      state.isAuthenticate = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action: IRehydrateAction) => {
      const token = action.payload?.auth?.token;

      if (token) {
        apiRequest.defaults.headers.Authorization = `Bearer ${token}`;
      }
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.isAuthenticate = true;
      state.user = payload.user;
      state.token = payload.token;
    });
    builder.addCase(getMe.fulfilled, (state, { payload }) => {
      state.user = payload;
    });
    builder.addCase(updateInformation.fulfilled, (state, { payload }) => {
      state.user = payload;
    });
  },
});

export default authReducer.reducer;
export const { logOut } = authReducer.actions;

import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 35px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.color.border};
  display: flex;
  align-items: center;
  padding-left: 1rem;
`;

import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spin, Empty } from 'antd';

import { useAppDispatch, useAppSelector } from '@/hooks';
import { OrderLayout } from '@/layouts';
import { NotificationItem } from '@/components';
import { getNotifications } from '@/stores/order/actions';
import { setNotifications } from '@/stores/order/reducer';
import { useWindowSize } from '@/hooks';

import configs from '@/configs';
import {
  HEADER_HEIGHT,
  BREADCRUMB_HEIGHT,
  ORDER_TAB_HEIGHT,
} from '@/constants/componentSize';

interface RouteParams {
  id: string;
}

interface MyComponent extends RouteComponentProps<RouteParams> {}

const OrderActivity: React.FC<MyComponent> = (props) => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { limit } = configs;
  const { id } = props.match.params;
  const { order } = useAppSelector((state) => state);
  const size = useWindowSize();

  useEffect(() => {
    handleGetNotifications();

    return () => {
      dispatch(setNotifications([]));
    };
  }, []);

  const handleGetNotifications = () => {
    dispatch(
      getNotifications({
        _id: id,
        pagination: { limit, page: currentPage },
      }),
    );
    setCurrentPage(currentPage + 1);
  };

  return (
    <OrderLayout>
      <InfiniteScroll
        dataLength={order.notifications.data.length}
        next={handleGetNotifications}
        hasMore={order.notifications.hasMore}
        height={
          size.height - HEADER_HEIGHT - BREADCRUMB_HEIGHT - ORDER_TAB_HEIGHT
        }
        loader={
          <Spin
            style={{ width: '100%', marginTop: 20 }}
            spinning={order.notifications.isFetching}
          />
        }
      >
        {order.notifications.data.length ? (
          order.notifications.data.map((notification) => {
            return (
              <NotificationItem
                data={notification}
                key={notification._id}
                visibleOrderCover={false}
              />
            );
          })
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </InfiniteScroll>
    </OrderLayout>
  );
};

export default OrderActivity;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { ActionButton } from '@/components';
import ROUTES from '@/constants/routes';

import { Wrapper } from './style';
import FilterModal, { IFinishFilterOrderForm } from '../FilterModal';

interface IProps {
  onFilter: (values: IFinishFilterOrderForm) => void;
  loading: boolean;
}

const ActionButtons: React.FC<IProps> = ({ onFilter, loading }) => {
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const history = useHistory();

  return (
    <Wrapper>
      <ActionButton
        type='primary'
        icon={<PlusOutlined />}
        onClick={() => {
          history.push(ROUTES.ADD_ORDER_PAGE.path);
        }}
      >
        Create order
      </ActionButton>
      <ActionButton
        icon={<FilterOutlined />}
        revert
        onClick={() => setVisibleModal(true)}
      >
        Filter
      </ActionButton>

      <FilterModal
        visible={visibleModal}
        loading={loading}
        onFinish={onFilter}
        onCancel={() => setVisibleModal(false)}
        onClearAll={() => {
          setVisibleModal(false);
          history.push('/');
        }}
      />
    </Wrapper>
  );
};

export default ActionButtons;

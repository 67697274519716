import styled, { css } from 'styled-components';
import { Image } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

export const NotificationContainer = styled.button<{
  isRead: boolean;
}>`
  border: none;
  background-color: ${({ isRead, theme }) =>
    isRead ? theme.color.secondary : theme.color.grayBg};
  min-height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
`;

export const Avatar = styled(Image)`
  border-radius: 50%;
`;

export const NotificationDetailContainer = styled.div`
  width: 100%;
  min-height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const NotificationDetail = styled.div`
  margin: 0 1.43rem;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.43rem;

  ${({ visibleOrderCover }: { visibleOrderCover: boolean }) =>
    !visibleOrderCover &&
    css`
      margin-right: 0;
    `}

  p {
    margin-bottom: 0;
  }
`;

export const LeftContainer = styled.div`
  min-height: 48px;
  display: flex;
  align-items: center;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DateTimeContainer = styled.div`
  margin: 0.2rem 1.43rem -0.3rem 1.43rem;
  font-style: normal;
  font-weight: normal;
  font-size: 0.85rem;
  line-height: 1.429rem;
  color: ${({ theme }) => theme.color.grayBold};
  display: flex;
  align-items: center;
`;

export const IconClock = styled(ClockCircleOutlined)`
  font-size: 1rem;
  margin-right: 0.45rem;
  color: ${({ theme }) => theme.color.grayBold};
`;

import styled from 'styled-components';
import { Empty } from 'antd';

const EMPTY_WIDTH = 73;

export const DateText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.57rem;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1.715rem;
  color: ${({ theme }) => theme.color.grayBold};
`;

export const NotificationItem = styled.div`
  margin: 1rem 0;
  font-style: normal;
  font-weight: normal;
  font-size: 0.85rem;
  line-height: 1.57rem;
  text-align: center;
  color: ${({ theme }) => theme.color.grayBold};
`;

export const EmptyContainer = styled(Empty)`
  position: absolute;
  top: 20px;
  left: calc(50% - ${EMPTY_WIDTH / 2}px);
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.primary};
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-bottom: 0;
    font-weight: bold;
    color: ${({ theme }) => theme.color.secondary};
  }
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  getUsersApi,
  updateUserApi,
  createUserApi,
  changePasswordUserApi,
} from '@/apis/users';
import {
  CREATE_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  CHANGE_PASSWORD_USER_SUCCESS,
} from '@/constants/toastStatus';

import {
  IGetUsersRequest,
  IGetUsersResponse,
  IUpdateUserRequest,
  IUpdateUserResponse,
  ICreateUserRequest,
  ICreateUserResponse,
  IChangePasswordUserRequest,
  IChangePasswordUserResponse,
} from './type';

export const getUsers = createAsyncThunk<IGetUsersResponse, IGetUsersRequest>(
  'user/getUsers',
  async (query, { rejectWithValue }) => {
    try {
      return await getUsersApi(query);
    } catch (e) {
      toast.error((e as Error).message);
      return rejectWithValue(e);
    }
  },
);

export const updateUser = createAsyncThunk<
  IUpdateUserResponse,
  IUpdateUserRequest
>('user/updateUser', async (body, { rejectWithValue }) => {
  try {
    const res = await updateUserApi(body);
    toast.success(UPDATE_USER_SUCCESS);

    return res;
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

export const createUser = createAsyncThunk<
  ICreateUserResponse,
  ICreateUserRequest
>('user/createUser', async (body, { rejectWithValue }) => {
  try {
    const res = await createUserApi(body);
    toast.success(CREATE_USER_SUCCESS);

    return res;
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

export const changePassword = createAsyncThunk<
  IChangePasswordUserResponse,
  IChangePasswordUserRequest
>('user/changePassword', async (body, { rejectWithValue }) => {
  try {
    const res = await changePasswordUserApi(body);

    toast.success(CHANGE_PASSWORD_USER_SUCCESS);
    return res;
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { getRoles } from '@/stores/role/actions';
import { getCountries } from '@/stores/country/actions';
import { getSuppliers } from '@/stores/supplier/actions';
import { getMe } from '@/stores/auth/actions';
import { getTotalUnreadNotification } from '@/stores/notification/actions';
import { getTotalUnreadMessage } from '@/stores/message/actions';
import { increaseTotalUnreadNotification } from '@/stores/notification/reducer';
import { increaseTotalUnreadMessage } from '@/stores/message/reducer';
import { receiveNewMessage } from '@/stores/order/reducer';
import SocketContextProvider from '@/contexts/SocketContext';
import MenuContextProvider from '@/contexts/MenuContext';
import { IMessage } from '@/type';
import configs from '@/configs';

const WrapperLayout: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (auth.isAuthenticate) {
      dispatch(getRoles());
      dispatch(getCountries());
      dispatch(getMe());
      dispatch(getTotalUnreadNotification());
      dispatch(getTotalUnreadMessage());
      dispatch(getSuppliers({ limit: configs.maxLimit }));
    }
  }, [auth.isAuthenticate]);

  const handleNewNotification = () => {
    dispatch(increaseTotalUnreadNotification());
  };

  const handleNewMessage = ({ message }: { message: IMessage }) => {
    dispatch(increaseTotalUnreadMessage());
    dispatch(receiveNewMessage(message));
  };

  return (
    <SocketContextProvider
      onNewNotification={handleNewNotification}
      onNewMessage={handleNewMessage}
    >
      <MenuContextProvider>{children}</MenuContextProvider>
    </SocketContextProvider>
  );
};

export default WrapperLayout;

import React from 'react';
import { Form, Input, Button, Select } from 'antd';
import { IRole } from '@/type';
import { IUploadSingleRequest } from '@/stores/upload/type';
import { IUploadState } from '@/stores/upload/type';
import { UploadCover } from '@/components';

import { Wrapper } from './style';

const { Option } = Select;

export interface IFormValues {
  fullName: string;
  phoneNumber: string;
  address: string;
  role: string;
  password: string;
}

interface IProps {
  isSubmitting: boolean;
  roles: Array<IRole>;
  cover: string;
  upload: IUploadState;
  onFinish: (values: IFormValues) => void;
  handleUploadSingle: (values: IUploadSingleRequest) => void;
}

const AddEmployeeForm: React.FC<IProps> = ({
  isSubmitting,
  roles,
  cover,
  upload,
  onFinish,
  handleUploadSingle,
}) => {
  const [form] = Form.useForm();

  return (
    <Wrapper>
      <UploadCover
        cover={cover}
        loading={upload.isUploadingSingle}
        onUpload={handleUploadSingle}
      />

      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Form.Item
          label='Full name'
          name='fullName'
          rules={[
            {
              required: true,
              message: 'Please input full name!',
            },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Phone number'
          name='phoneNumber'
          rules={[
            {
              required: true,
              message: 'Please input phone number!',
            },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Address'
          name='address'
          rules={[
            {
              required: true,
              message: 'Please input address!',
            },
            {
              min: 6,
              message: 'Address must be at least 6 characters!',
            },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Position'
          name='role'
          rules={[
            {
              required: true,
              message: 'Please input position!',
            },
          ]}
        >
          <Select placeholder='Select position'>
            {roles.map((role, index) => (
              <Option value={role._id} key={index.toString()}>
                {role.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label='Password'
          name='password'
          rules={[
            {
              required: true,
              message: 'Please input password!',
            },
            {
              min: 6,
              message: 'Password must be at least 6 characters!',
            },
          ]}
          hasFeedback
        >
          <Input.Password autoComplete='password' />
        </Form.Item>

        <Form.Item
          label='Confirm password'
          name='confirmPassword'
          rules={[
            {
              required: true,
              message: 'Please input confirm password!',
            },
            {
              min: 6,
              message: 'Password must be at least 6 characters!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  'The two passwords that you entered do not match!',
                );
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password autoComplete='password' />
        </Form.Item>

        <Form.Item>
          <Button htmlType='submit' type='primary' block loading={isSubmitting}>
            Add
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

export default AddEmployeeForm;

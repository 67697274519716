import React from 'react';
import { Wrapper } from './style';

interface IProps {
  onClick: () => void;
}

const ActionBottom: React.FC<IProps> = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <p>Update order</p>
    </Wrapper>
  );
};

export default ActionBottom;

import React, { useContext, useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';

import { MenuContext } from '@/contexts/MenuContext';
import ROUTES from '@/constants/routes';
import { useAppDispatch } from '@/hooks';
import { changePassword } from '@/stores/auth/actions';

import { ChangePasswordForm, IFinishForm } from './components';

const ChangePassword: React.FC = () => {
  const { setBackToRoute } = useContext(MenuContext);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setBackToRoute(ROUTES.HOME_PAGE.path);
  }, []);

  const handleChangePassword = ({ values, resetFields }: IFinishForm) => {
    setLoading(true);
    dispatch(changePassword(values))
      .then(unwrapResult)
      .then(() => {
        resetFields();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <ChangePasswordForm onFinish={handleChangePassword} loading={loading} />
  );
};

export default ChangePassword;

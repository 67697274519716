const formatCurrency = (
  value: number | string | undefined,
  prefix?: string,
): string => {
  let total = '0';

  if (value) {
    total = `${value}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  return prefix ? `${prefix}${total}` : total;
};

export default formatCurrency;

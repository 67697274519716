import styled from 'styled-components';
import { Image, Menu } from 'antd';

import { BellOutlined, CommentOutlined, LeftOutlined } from '@ant-design/icons';
import { HEADER_HEIGHT } from '@/constants/componentSize';

export const HeaderWrapper = styled.div`
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const LeftContainer = styled.div``;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled(Image)`
  border-radius: 50%;
`;

export const IconContainer = styled.button`
  margin-right: 2rem;
  height: 2.15rem;
  width: 2.15rem;
  position: relative;
  border: none;
  background: none;
`;

export const Count = styled.div`
  position: absolute;
  top: 0;
  right: -0.25rem;
  background-color: ${({ theme }) => theme.color.danger};
  padding: 0 5px;
  border-radius: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.85rem;
  color: ${({ theme }) => theme.color.secondary};
`;

export const IconNotify = styled(BellOutlined)<{
  $isShowNotification: boolean;
}>`
  font-size: 2.15rem;
  color: ${({ $isShowNotification, theme }) =>
    $isShowNotification ? theme.color.grayBold : theme.color.secondary}};
`;

export const IconMessages = styled(CommentOutlined)<{
  $isShowMessages: boolean;
}>`
  font-size: 2rem;
  color: ${({ $isShowMessages, theme }) =>
    $isShowMessages ? theme.color.grayBold : theme.color.secondary}};
`;

export const IconBack = styled(LeftOutlined)`
  font-size: 2rem;
  color: ${({ theme }) => theme.color.secondary};
`;

export const DropdownContainer = styled.a`
  display: flex;
  align-items: center;
`;

export const ItemText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
`;

export const MenuContainer = styled(Menu)`
  position: absolute;
  top: 0.375rem;
  right: -1rem;
  z-index: 5;
`;

export const CallBackButton = styled.button`
  border: none;
  background: none;
`;

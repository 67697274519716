import { createSlice } from '@reduxjs/toolkit';
import configs from '@/configs';
import _ from 'lodash';
import { IMessageState } from './type';
import { getMessages, setReadMessage, getTotalUnreadMessage } from './actions';

const initialState: IMessageState = {
  data: [],
  isFetching: false,
  hasMore: true,
  totalUnread: 0,
};

const messageReducer = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessageData(state, { payload }) {
      state.data = payload;
    },
    increaseTotalUnreadMessage(state) {
      state.totalUnread += 1;
    },
    decreaseTotalUnreadMessage: (state) => {
      state.totalUnread -= 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMessages.pending, (state, { meta }) => {
      if (meta.arg.page === 1) {
        state.hasMore = true;
        state.data = [];
      }
      state.isFetching = true;
    });
    builder.addCase(getMessages.fulfilled, (state, { payload }) => {
      state.hasMore = !(payload.length < configs.limit);
      state.data = _.unionBy(state.data, payload, '_id');
      state.isFetching = false;
    });
    builder.addCase(getMessages.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(setReadMessage.fulfilled, (state, { payload }) => {
      const index = state.data.findIndex(({ _id }) => _id === payload._id);
      state.data[index] = payload;
    });

    builder.addCase(getTotalUnreadMessage.fulfilled, (state, { payload }) => {
      state.totalUnread = payload;
    });
  },
});

export const {
  setMessageData,
  increaseTotalUnreadMessage,
  decreaseTotalUnreadMessage,
} = messageReducer.actions;
export default messageReducer.reducer;

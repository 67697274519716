/* eslint-disable no-console */
import React, { useEffect, useState, useContext, useRef } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';
import { MenuContext } from '@/contexts/MenuContext';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { getOrders } from '@/stores/order/actions';
import useSocket from '@/hooks/useSocket';
import SOCKET_EVENTS from '@/constants/socketEvents';
import POSITIONS from '@/constants/positions';
import config from '@/configs';
import { useQuery } from '@/hooks/useQuery';
import _ from 'lodash';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  ActionButtons,
  Statistics,
  IFinishFilterOrderForm,
  ListOfOrder,
} from './components';

type OrderFilterQuery = {
  productName?: string;
  customerName?: string;
  country?: string;
  phoneNumber?: string;
  status?: string;
  supplier?: string;
  fromDate?: string;
  toDate?: string;
};

export const getOrderFilterQuery = (
  query: URLSearchParams,
): OrderFilterQuery => {
  const customerName = query.get('customerName');
  const country = query.get('country');
  const productName = query.get('productName');
  const phoneNumber = query.get('phoneNumber');
  const status = query.get('status');
  const supplier = query.get('supplier');
  const fromDate = query.get('fromDate');
  const toDate = query.get('toDate');

  return {
    productName: productName !== null ? productName : undefined,
    customerName: customerName !== null ? customerName : undefined,
    country: country !== null ? country : undefined,
    phoneNumber: phoneNumber !== null ? phoneNumber : undefined,
    status: status !== null ? status : undefined,
    supplier: supplier !== null ? supplier : undefined,
    fromDate: fromDate !== null ? fromDate : undefined,
    toDate: toDate !== null ? toDate : undefined,
  };
};

const Home: React.FC = () => {
  const { backToRoute } = useContext(MenuContext);
  const dispatch = useAppDispatch();
  const { order, auth } = useAppSelector((state) => state);
  const { isFetching, count, totalAmount } = order;
  const roleName = auth.user.role?.name;
  const { socket } = useSocket();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const allowedSee =
    roleName === POSITIONS.ADMIN || roleName === POSITIONS.MANAGER;
  const { limit } = config;
  const [initialSpinning, setInitialSpinning] = useState<boolean>(false);
  const ordersRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    if (!backToRoute) {
      socket.emit(SOCKET_EVENTS.CLIENT.TEST, { name: 'Monla Frontend' });
      setCurrentPage(1);
      setInitialSpinning(true);

      if (ordersRef && ordersRef.current && Object.values(order.data).length) {
        ordersRef.current?.scrollIntoView({
          behavior: 'smooth',
          inline: 'start',
        });
      }
    }
    const orderFilterQuery = getOrderFilterQuery(query);

    dispatch(
      getOrders({
        limit,
        page: 1,
        ...orderFilterQuery,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        setInitialSpinning(false);
        setCurrentPage(2);
      });
  }, [backToRoute, query]);

  const handleFilterOrder = ({
    values,
    closeModal,
  }: IFinishFilterOrderForm) => {
    const {
      productName,
      customerName,
      country,
      status,
      phoneNumber,
      supplier,
    } = values;
    const customValues = _.pickBy(
      { productName, customerName, status, country, phoneNumber, supplier },
      _.identity,
    );
    let timeDistance;

    if (values.orderDate?.length) {
      timeDistance = {
        fromDate: moment(values.orderDate[0]).startOf('date').toISOString(),
        toDate: moment(values.orderDate[1]).endOf('date').toISOString(),
      };
    }

    history.push({
      pathname: '/',
      search: new URLSearchParams({
        ...customValues,
        ...timeDistance,
      }).toString(),
    });

    closeModal();
  };

  const handleGetOrders = () => {
    const orderFilterQuery = getOrderFilterQuery(query);
    dispatch(getOrders({ limit, page: currentPage, ...orderFilterQuery }));
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <ActionButtons onFilter={handleFilterOrder} loading={isFetching} />
      <Spin spinning={initialSpinning} style={{ marginTop: 20 }}>
        <ListOfOrder
          loading={isFetching}
          order={order}
          handleGetOrders={handleGetOrders}
          allowedSee={allowedSee}
          ordersRef={ordersRef}
          globalSpinning={initialSpinning}
        />
      </Spin>

      {allowedSee && <Statistics count={count} totalAmount={totalAmount} />}
    </>
  );
};

export default Home;

import styled from 'styled-components';
import { Image, Menu } from 'antd';

import { EllipsisOutlined } from '@ant-design/icons';

export const Wrapper = styled.div`
  height: 74px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.color.lightBorder};
  padding: 1.45rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Avatar = styled(Image)`
  border-radius: 50%;
`;

export const LeftContainer = styled.div`
  display: flex;
`;

export const RightContainer = styled.div``;

export const InformationContainer = styled.div`
  margin-left: 1.14rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const NameTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.43rem;
`;

export const PhoneTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.43rem;
  margin-top: 1px;
  color: ${({ theme }) => theme.color.grayBold};
`;

export const DropdownContainer = styled.a`
  display: flex;
  align-items: center;
`;

export const MenuContainer = styled(Menu)`
  position: absolute;
  top: 1.15rem;
  right: -1.35rem;
  z-index: 5;
`;

export const ItemText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
`;

export const IconMenu = styled(EllipsisOutlined)`
  font-size: 2.4rem;
  color: ${({ theme }) => theme.color.black};
`;

export const BlockText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.color.danger};
`;

export const TopInformationContainer = styled.div`
  display: flex;
  height: 1.45rem;
  align-items: center;
`;

export const RoleContainer = styled.div<{
  // will update boolean data late, because another component called
  isBlocked: boolean | undefined;
}>`
  margin-left: 0.4rem;
  height: 1.07rem;
  padding: 0 0.3rem;
  padding-top: 0.005rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2rem;
  background-color: ${({ isBlocked, theme }) =>
    isBlocked ? theme.color.danger : theme.color.lightBg}};
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.43rem;
  color: ${({ theme }) => theme.color.secondary};
`;

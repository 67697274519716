import { IOrder, IUser } from '@/type';

interface IParams {
  content: string;
  user: IUser;
  order: IOrder;
}

const replaceMessageContent = ({ content, user, order }: IParams): string => {
  return content
    .replaceAll('_USER_', user.fullName)
    .replaceAll('_ORDER_', `#${order?.orderId || ''}`);
};

export default replaceMessageContent;

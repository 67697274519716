import { createSlice } from '@reduxjs/toolkit';
import { ICountryState } from './type';
import { getCountries } from './actions';

const initialState: ICountryState = {
  data: [],
  isFetching: false,
};

const roleReducer = createSlice({
  name: 'country',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCountries.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getCountries.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.data = payload;
    });
    builder.addCase(getCountries.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export default roleReducer.reducer;

import React from 'react';
import { Empty, Spin } from 'antd';
import { OrderCard } from '@/components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IOrderState } from '@/stores/order/type';
import {
  HEADER_HEIGHT,
  ACTION_BUTTON_HEIGHT,
  STATISTICS_HEIGHT,
} from '@/constants/componentSize';
import { useWindowSize } from '@/hooks';

import { Wrapper } from './style';

interface IProps {
  order: IOrderState;
  loading: boolean;
  allowedSee: boolean;
  globalSpinning: boolean;
  ordersRef: React.RefObject<HTMLDivElement>;
  handleGetOrders: () => void;
}

const ListOfOrder: React.FC<IProps> = ({
  order,
  loading,
  allowedSee,
  ordersRef,
  globalSpinning,
  handleGetOrders,
}) => {
  const orders = Object.values(order.data);
  const size = useWindowSize();

  return (
    <Wrapper>
      <InfiniteScroll
        dataLength={orders.length}
        next={handleGetOrders}
        hasMore={order.hasMore}
        height={
          size.height -
          HEADER_HEIGHT -
          ACTION_BUTTON_HEIGHT -
          (allowedSee ? STATISTICS_HEIGHT : 0)
        }
        loader={
          <Spin style={{ width: '100%', marginTop: 20 }} spinning={loading} />
        }
      >
        <div ref={ordersRef} />
        {orders.length || globalSpinning ? (
          orders.map((order) => (
            <div style={{ marginBottom: '1rem' }} key={order._id}>
              <OrderCard order={order} />
            </div>
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </InfiniteScroll>
    </Wrapper>
  );
};

export default ListOfOrder;

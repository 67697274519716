import React, { useMemo } from 'react';
import { Avatar, Dropdown, Menu, Modal } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AntDesignOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import ROUTES from '@/constants/routes';
import replaceRoutePath from '@/utils/replaceRoutePath';
import { IOrder, ISupplier } from '@/type';
import formatCurrency from '@/utils/formatCurrency';
import getOrderStatusColor from '@/utils/getOrderStatusColor';
import { ImageLightBox } from '@/components';
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter';
import { DELETE_ORDER_KEY } from '@/constants/homeDropdownKey';
import { informationPath } from '@/constants/routes';
import DELETE_STATUS from '@/constants/deleteStatus';
import getPrices from '@/utils/getPrices';
import moment from 'moment';

import {
  Wrapper,
  Order,
  Status,
  OrderId,
  Customer,
  CInformation,
  Payment,
  PaidContainer,
  PaidTitle,
  CoverContainer,
  MenuContainer,
  DropdownContainer,
  DeleteText,
  IconMenu,
  TopRightContainer,
  UnderTopRightContainer,
  ProductContainer,
  PLeftContainer,
  PRightContainer,
  ListProductContainer,
  Date,
  BottomRightContainer,
  Size,
} from './style';

interface IProps {
  order: IOrder;
  allowedSee?: boolean;
  onUpdateOrder?: (
    orderId: string,
    deleteStatus: IOrder['deleteStatus'],
  ) => void;
}

const OrderCard: React.FC<IProps> = ({
  order,
  allowedSee = false,
  onUpdateOrder,
}) => {
  const history = useHistory();
  const location = useLocation();

  const handleClick = () => {
    const path = replaceRoutePath({
      originalPath: ROUTES.ORDER_INFORMATION_PAGE.path,
      replacementValue: order._id,
    });

    history.push(path);
  };

  const isInformationPage = useMemo(() => {
    if (location.pathname.includes(informationPath)) {
      return true;
    }

    return false;
  }, [location.pathname]);

  const totalAmount =
    getPrices(order.orderItems) + order.shippingFee - order.discount;

  const handleUpdateDeleteStatus = () => {
    if (onUpdateOrder) {
      onUpdateOrder(
        order._id,
        allowedSee ? DELETE_STATUS.TRASH : DELETE_STATUS.PENDING,
      );
    }
  };

  const handleOpenDeleteModel = () => {
    const deleteTextForEmployee =
      'After deleting the order, a notification will be sent to the admin to wait for confirmation!';

    const deleteTextForAdmin =
      'After deleting an order, it will be moved to the trash!';

    Modal.confirm({
      title: allowedSee ? deleteTextForAdmin : deleteTextForEmployee,
      icon: <ExclamationCircleOutlined />,
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleUpdateDeleteStatus,
    });
  };

  const menu = (
    <MenuContainer onClick={handleOpenDeleteModel}>
      <Menu.Item key={DELETE_ORDER_KEY}>
        <DeleteText>Move to trash</DeleteText>
      </Menu.Item>
    </MenuContainer>
  );

  const customer = order.customerCopy ?? order.customer;

  return (
    <Wrapper onClick={handleClick}>
      <Order>
        <ListProductContainer>
          {order?.orderItems?.map((item) => (
            <ProductContainer key={item._id}>
              <PLeftContainer>
                {item.product.cover ? (
                  <CoverContainer>
                    <ImageLightBox imageUrl={item.product.cover || ''} />
                  </CoverContainer>
                ) : (
                  <Avatar
                    shape='square'
                    icon={<AntDesignOutlined />}
                    size={64}
                  />
                )}
              </PLeftContainer>
              <PRightContainer>
                <h3>{item.product.name}</h3>
                <div style={{ display: 'flex', gap: 10 }}>
                  <p>{(item.product.supplier as ISupplier).name}</p>
                  {!!item.product?.size && (
                    <Size>
                      <p>size</p> <p>{item.product.size}</p>
                    </Size>
                  )}
                </div>

                <p>{formatCurrency(item.price, '$')}</p>
              </PRightContainer>
            </ProductContainer>
          ))}
          <TopRightContainer>
            {isInformationPage ? (
              <Dropdown overlay={menu} trigger={['click']}>
                <DropdownContainer
                  className='ant-dropdown-link'
                  onClick={(e) => e.preventDefault()}
                >
                  <IconMenu />
                </DropdownContainer>
              </Dropdown>
            ) : (
              <>
                <Status color={getOrderStatusColor(order.status)}>
                  {capitalizeFirstLetter(order.status || '')}
                </Status>
                <OrderId>#{order?.orderId}</OrderId>
              </>
            )}
          </TopRightContainer>

          {!isInformationPage && (
            <BottomRightContainer>
              <Date>
                <CalendarOutlined />
                <span>{moment(order?.createdAt).format('DD/MM/YYYY')}</span>
              </Date>
            </BottomRightContainer>
          )}

          {isInformationPage && (
            <UnderTopRightContainer>
              <Status color={getOrderStatusColor(order.status)}>
                {capitalizeFirstLetter(order.status || '')}
              </Status>
              <OrderId>#{order?.orderId}</OrderId>
            </UnderTopRightContainer>
          )}
        </ListProductContainer>
      </Order>

      <Customer>
        <CInformation>
          <p>{customer?.facebookName}</p>
          <p>{customer?.fullName}</p>
          <p>{customer?.phoneNumber}</p>
          <p>{customer?.email}</p>
          <p>{order?.deliveryAddress || customer?.deliveryAddress}</p>
          <p>
            {typeof customer?.country !== 'string' && customer?.country?.name}
          </p>
        </CInformation>

        <Payment>
          <p>Total price: {formatCurrency(getPrices(order.orderItems), '$')}</p>
          <p>Shipping: {formatCurrency(order.shippingFee, '$')}</p>
          <p>Discount: {formatCurrency(order.discount, '$')}</p>
          <p>Total amount: {formatCurrency(totalAmount, '$')}</p>

          {order.paidAmount === totalAmount ? (
            <PaidContainer>
              <CheckCircleOutlined style={{ fontSize: '1.43rem' }} />
              <PaidTitle>Paid</PaidTitle>
            </PaidContainer>
          ) : (
            <>
              <p>Paid amount: {formatCurrency(order.paidAmount, '$')}</p>
              <p>
                Unpaid amount:{' '}
                {formatCurrency(totalAmount - order.paidAmount, '$')}
              </p>
            </>
          )}
        </Payment>
      </Customer>
    </Wrapper>
  );
};

export default OrderCard;

import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import configs from '@/configs';
import DELETE_STATUS from '@/constants/deleteStatus';
import { IOrderState } from './type';
import {
  getOrders,
  getMessages,
  getNotifications,
  getOrder,
  createOrder,
  updateOrder,
  getTotalAmount,
} from './actions';

const initialState: IOrderState = {
  data: {},
  count: 0,
  totalAmount: 0,
  isFetching: false,
  isSubmitting: false,
  hasMore: true,
  messages: {
    data: [],
    isFetching: false,
    hasMore: true,
  },
  notifications: {
    data: [],
    isFetching: false,
    hasMore: true,
  },
};

const orderReducer = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setMessages(state, { payload }) {
      state.messages = { ...state.messages, ...payload };
    },
    setOrders(state, { payload }) {
      state.data = _.keyBy(payload, '_id');
    },
    setHasMore(state, { payload }) {
      state.hasMore = payload;
    },
    setNotifications(state, { payload }) {
      state.notifications.data = payload;
    },
    receiveNewMessage(state, { payload }) {
      state.messages.data.unshift(payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getOrders.fulfilled, (state, { payload, meta }) => {
      const payloadCustom = _.keyBy(payload.orders, '_id');
      state.hasMore = !(payload.orders.length < configs.limit);
      const page = meta.arg?.page;
      state.count = payload.count;
      state.totalAmount = payload.totalAmount;
      if (page && page > 1) {
        state.data = Object.assign(state.data, payloadCustom);
      }

      if (!page || page === 1) {
        state.data = payloadCustom;
      }

      state.isFetching = false;
    });
    builder.addCase(getOrders.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(getOrder.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getOrder.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.data = {};
      state.data[payload._id] = payload;
    });
    builder.addCase(getOrder.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(getMessages.pending, (state) => {
      state.messages.isFetching = true;
    });
    builder.addCase(getMessages.fulfilled, (state, { payload }) => {
      state.messages.isFetching = false;
      state.messages.hasMore = !(payload.messages.length < configs.limit);

      if (payload.page && payload.page > 1) {
        state.messages.data = _.unionBy(
          state.messages.data,
          payload.messages,
          '_id',
        );
      } else {
        state.messages.data = payload.messages;
      }
    });
    builder.addCase(getMessages.rejected, (state, { meta }) => {
      if (meta.arg.pagination.page === 1) {
        state.hasMore = true;
        state.notifications.data = [];
      }

      state.messages.isFetching = false;
    });

    builder.addCase(getNotifications.pending, (state) => {
      state.notifications.isFetching = true;
      state.notifications.hasMore = true;
    });
    builder.addCase(getNotifications.fulfilled, (state, { payload }) => {
      state.notifications.isFetching = false;
      state.notifications.hasMore = !(payload.length < configs.limit);

      state.notifications.data = _.unionBy(
        state.notifications.data,
        payload,
        '_id',
      );
    });
    builder.addCase(getNotifications.rejected, (state) => {
      state.notifications.isFetching = false;
    });

    builder.addCase(createOrder.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(createOrder.fulfilled, (state, { payload }) => {
      const { _id } = payload;
      state.data = { [_id]: payload, ...state.data };
      state.isSubmitting = false;
    });
    builder.addCase(createOrder.rejected, (state) => {
      state.isSubmitting = false;
    });

    builder.addCase(updateOrder.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(updateOrder.fulfilled, (state, { payload }) => {
      const { _id } = payload;
      state.data[_id] = payload;
      if (
        payload.deleteStatus &&
        (payload.deleteStatus === DELETE_STATUS.DELETED ||
          payload.deleteStatus === DELETE_STATUS.CANCEL ||
          payload.deleteStatus === DELETE_STATUS.TRASH ||
          payload.deleteStatus === DELETE_STATUS.RESTORE)
      ) {
        delete state.data[_id];
      }
      state.isSubmitting = false;
    });
    builder.addCase(updateOrder.rejected, (state) => {
      state.isSubmitting = false;
    });

    builder.addCase(getTotalAmount.fulfilled, (state, { payload }) => {
      const { count, totalAmount } = payload;
      state.count = count;
      state.totalAmount = totalAmount;
    });
  },
});

export const {
  setMessages,
  setNotifications,
  setOrders,
  receiveNewMessage,
  setHasMore,
} = orderReducer.actions;
export default orderReducer.reducer;

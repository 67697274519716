import React from 'react';
import { StaffItem } from '@/components';
import { IRole, IUser } from '@/type';
import { Wrapper } from './style';

interface IProps {
  roles: IRole[];
  user: IUser;
}
const Owner: React.FC<IProps> = ({ roles, user }) => {
  if (!user) {
    return null;
  }

  return (
    <Wrapper>
      <p>Created by:</p>
      <StaffItem staff={user} roles={roles} viewOnly />
    </Wrapper>
  );
};

export default Owner;

import styled from 'styled-components';
import { Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.gray};
  position: relative;
`;

export const Order = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};

  h3 {
    font-size: 1em;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
    color: ${({ theme }) => theme.color.grayBold};
    font-size: 0.85rem;
  }
`;

export const Status = styled.div<{
  color: string;
}>`
  background: ${({ color }) => color};
  color: ${({ theme }) => theme.color.secondary};
  width: 66px;
  padding-left: 0.2rem;
  text-align: center;
  font-size: 0.9rem;
`;

export const OrderId = styled.div`
  width: 100px;
  font-size: 0.9rem;
  margin: 0.4rem 0.2rem 0.1rem 0;
  word-wrap: break-word;
  font-weight: bold;
  text-align: end;
`;

export const Customer = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    margin-bottom: 0;
    font-size: 0.85em;
  }
`;

export const CInformation = styled.div`
  padding: 0.5rem 1rem;
  flex-basis: 50%;
`;

export const Payment = styled.div`
  padding: 0.5rem 1rem;
  flex-basis: 50%;

  border-left: 1px solid ${({ theme }) => theme.color.border};
`;

export const PaidContainer = styled.div`
  width: 6rem;
  height: 2.2rem;
  border: 1px solid ${({ theme }) => theme.color.danger};
  color: ${({ theme }) => theme.color.danger};
  border-radius: 0.3rem;
  margin-top: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PaidTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.57rem;
  color: ${({ theme }) => theme.color.danger};
  margin-left: 0.5rem;
`;

export const CoverContainer = styled.div`
  min-height: 60px;
  min-width: 60px;
  height: 60px;
  width: 60px;
`;

export const MenuContainer = styled(Menu)`
  position: absolute;
  top: 1.15rem;
  right: 0;
  z-index: 5;
`;

export const DeleteText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.color.danger};
`;

export const DropdownContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 50px;
  padding-top: 0.4rem;
  position: absolute;
  top: 0;
  right: 0;
`;

export const TopRightContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 0.5rem;
`;

export const BottomRightContainer = styled.div`
  position: absolute;
  right: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 0.5rem;
  padding-top: 1rem;
`;

export const IconMenu = styled(EllipsisOutlined)`
  font-size: 2.5rem;
  height: 30px;
  width: 60px;
  color: ${({ theme }) => theme.color.black};
`;

export const IContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const UnderTopRightContainer = styled.div`
  position: absolute;
  top: 36px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ListProductContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const ProductContainer = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  width: 100%;
`;

export const PLeftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PRightContainer = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  p {
    margin-bottom: 0.2rem;
  }

  h3 {
    width: calc(100% - 60px);
    word-wrap: break-word;
  }

  p,
  h3 {
    text-transform: capitalize;
  }
`;

export const Date = styled.div`
  font-size: 1rem;
  margin-top: -20px;

  span {
    margin-left: 0.4rem;
  }
`;

export const Size = styled.div`
  font-size: 12px;
  display: flex;
  gap: 4px;
  color: ${({ theme }) => theme.color.black};

  p {
    font-size: 12px;
    margin: 0;
  }
`;

export const WrapperStatusOder = styled.div`
  margin-bottom: 10px;
`;

export const WrapperIcon = styled.div`
  display: inline-block;
  margin-right: 5px;
`;

export const ButtonRestore = styled.button`
  height: 30px;
  width: 120px;
  border: none;
  background-color: green;
  color: white;
  border-radius: 0.3rem;
`;

export const WrapperButton = styled.div``;

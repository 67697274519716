import { createSlice } from '@reduxjs/toolkit';

import { IUploadState } from './type';
import { uploadSingle, uploadMultiple } from './actions';

const initialState: IUploadState = {
  isUploadingSingle: false,
  isUploadingMultiple: false,
};

const uploadReducer = createSlice({
  name: 'upload',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadSingle.pending, (state) => {
      state.isUploadingSingle = true;
    });
    builder.addCase(uploadSingle.fulfilled, (state) => {
      state.isUploadingSingle = false;
    });
    builder.addCase(uploadSingle.rejected, (state) => {
      state.isUploadingSingle = false;
    });

    builder.addCase(uploadMultiple.pending, (state) => {
      state.isUploadingMultiple = true;
    });
    builder.addCase(uploadMultiple.fulfilled, (state) => {
      state.isUploadingMultiple = false;
    });
    builder.addCase(uploadMultiple.rejected, (state) => {
      state.isUploadingMultiple = false;
    });
  },
});

export default uploadReducer.reducer;

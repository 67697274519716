import styled from 'styled-components';
import { Button } from 'antd';

export const Wrapper = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  margin-bottom: 1rem;
`;

export const CancelBtn = styled(Button)`
  width: 50%;
  background: none;
  border: none;
  border: 1px solid ${({ theme }) => theme.color.border};
  font-size: 1rem;
  height: 2.5rem;
`;

export const DeleteBtn = styled.button`
  width: 50%;
  background-color: ${({ theme }) => theme.color.danger};
  color: ${({ theme }) => theme.color.secondary};
  border: none;
  border-color: ${({ theme }) => theme.color.border};
  border-style: solid
  border-width: 1px 1px 1px 0;
  font-size: 1rem;
  height: 2.5rem;
`;

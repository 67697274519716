import apiRequest from '@/utils/apiRequest';
import {
  IGetCustomersRequest,
  IGetCustomersResponse,
  ICreateAndUpdateCustomersRequest,
  ICreateAndUpdateCustomersResponse,
  ICreateCustomersRequest,
  ICreateCustomersResponse,
} from '@/stores/customer/type';

const endpoint = '/customers';

export const getCustomersApi = (
  query: IGetCustomersRequest,
): Promise<IGetCustomersResponse> => {
  return apiRequest.get(endpoint, { params: query });
};

export const createCustomerApi = (
  body: ICreateCustomersRequest,
): Promise<ICreateCustomersResponse> => {
  return apiRequest.post(endpoint, body);
};

export const createAndUpdateCustomerApi = (
  body: ICreateAndUpdateCustomersRequest,
): Promise<ICreateAndUpdateCustomersResponse> => {
  return apiRequest.put(endpoint, body);
};

import React from 'react';
import { OrderItemPhotos, IOrderFormValues, OrderPhotos } from '@/components';
import { IUploadMultipleRequest } from '@/stores/upload/type';
import { useAppSelector } from '@/hooks';

import { StepPhotosWrapper } from './style';

interface IProps {
  orderFormValue: IOrderFormValues;
  photos: string[];
  onUploadProductCover: (
    productName: string,
    formData: IUploadMultipleRequest,
  ) => void;
  onDeleteOrderPhoto: (photo: string) => void;
  onUploadOrderPhotos: (formData: IUploadMultipleRequest) => void;
}

const StepPhotosForm: React.FC<IProps> = ({
  orderFormValue,
  photos,
  onUploadProductCover,
  onDeleteOrderPhoto,
  onUploadOrderPhotos,
}) => {
  const { orderItems } = orderFormValue;
  const { upload } = useAppSelector((state) => state);

  return (
    <StepPhotosWrapper>
      <OrderItemPhotos
        loading={upload.isUploadingSingle}
        orderItems={orderItems}
        onUploadCover={onUploadProductCover}
      />

      <OrderPhotos
        loading={upload.isUploadingMultiple}
        photos={photos}
        onUpload={onUploadOrderPhotos}
        onDelete={onDeleteOrderPhoto}
      />
    </StepPhotosWrapper>
  );
};

export default StepPhotosForm;

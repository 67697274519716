import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getOrdersApi,
  getMessagesApi,
  getNotificationsApi,
  getOrderApi,
  createOrderApi,
  updateOrderApi,
  getTotalAmountApi,
} from '@/apis/order';
import { toast } from 'react-toastify';

import { CREATE_ORDER_SUCCESS, ORDER_NOT_FOUND } from '@/constants/toastStatus';

import {
  IGetOrdersRequest,
  IGetOrdersResponse,
  IGetOrderRequest,
  IGetOrderResponse,
  IGetMessagesRequest,
  IGetMessagesReturn,
  IGetNotificationsRequest,
  IGetNotificationsResponse,
  ICreateOrderRequest,
  ICreateOrderResponse,
  IUpdateOrderRequest,
  IUpdateOrderResponse,
  IGetTotalAmountResponse,
} from './type';

export const getOrders = createAsyncThunk<
  IGetOrdersResponse,
  IGetOrdersRequest
>('order/getOrders', async (body, { rejectWithValue }) => {
  try {
    return await getOrdersApi(body);
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const getOrder = createAsyncThunk<IGetOrderResponse, IGetOrderRequest>(
  'order/getOrder',
  async (body, { rejectWithValue }) => {
    try {
      return await getOrderApi(body);
    } catch (e) {
      toast.error(ORDER_NOT_FOUND);
      return rejectWithValue(e);
    }
  },
);

export const getMessages = createAsyncThunk<
  IGetMessagesReturn,
  IGetMessagesRequest
>('order/getMessages', async (requestData, { rejectWithValue }) => {
  try {
    const res = await getMessagesApi(requestData);
    return { messages: res, page: requestData.pagination.page };
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const getNotifications = createAsyncThunk<
  IGetNotificationsResponse,
  IGetNotificationsRequest
>('order/getNotifications', async (requestData, { rejectWithValue }) => {
  try {
    return await getNotificationsApi(requestData);
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const createOrder = createAsyncThunk<
  ICreateOrderResponse,
  ICreateOrderRequest
>('order/createOrder', async (body, { rejectWithValue }) => {
  try {
    const res = await createOrderApi(body);
    toast.success(CREATE_ORDER_SUCCESS);
    return res;
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

export const updateOrder = createAsyncThunk<
  IUpdateOrderResponse,
  IUpdateOrderRequest
>('order/updateOrder', async (body, { rejectWithValue }) => {
  try {
    return await updateOrderApi(body);
  } catch (e) {
    toast.error((e as Error).message);
    return rejectWithValue(e);
  }
});

export const getTotalAmount = createAsyncThunk<IGetTotalAmountResponse>(
  'order/getTotalAmount',
  async (body, { rejectWithValue }) => {
    try {
      return await getTotalAmountApi();
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

import styled from 'styled-components';
import { InputNumber, DatePicker } from 'antd';

export const Wrapper = styled.div``;

export const StepPhotosWrapper = styled.div`
  margin-bottom: 1.7rem;
`;

export const InputNumberCustom = styled(InputNumber)`
  width: 100%;
  color: ${({ theme }) => theme.color.black};
`;

export const DatePickerCustom = styled(DatePicker)`
  width: 100%;
`;

export const ImagesContainer = styled.div``;

import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: white;
  padding: 1rem;
  display: flex;
`;

export const ButtonClear = styled.button`
  background-color: red;
  color: white;
  padding: 0.3rem 0.5rem;
  border: none;
  border-radius: 0.5rem;
`;

export const WrapperTittle = styled.div`
  display: inline-block;
  margin-left: 0.3rem;
`;

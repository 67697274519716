import React from 'react';
import { CloseCircleFilled } from '@ant-design/icons';

import {
  Wrapper,
  TextButton,
  Title,
  CloseButton,
  ButtonContainer,
  SetReadTitle,
} from './style';

interface IProps {
  isUnread: boolean;
  title: string;
  setIsUnread: (values: boolean) => void;
  handleViewAll: () => void;
  handleViewUnread: () => void;
  handleCloseDropdown: () => void;
}

const NotificationHeader: React.FC<IProps> = ({
  isUnread,
  title,
  setIsUnread,
  handleViewUnread,
  handleViewAll,
  handleCloseDropdown,
}) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <ButtonContainer>
        {isUnread ? (
          <TextButton
            onClick={() => {
              setIsUnread(false);
              handleViewAll();
            }}
          >
            <SetReadTitle>View all {title}</SetReadTitle>
          </TextButton>
        ) : (
          <TextButton
            onClick={() => {
              setIsUnread(true);
              handleViewUnread();
            }}
          >
            <SetReadTitle>View unread {title}</SetReadTitle>
          </TextButton>
        )}

        <CloseButton onClick={handleCloseDropdown}>
          <CloseCircleFilled style={{ fontSize: 17 }} />
        </CloseButton>
      </ButtonContainer>
    </Wrapper>
  );
};

export default NotificationHeader;

import { OrderCardTrash } from '@/components';
import { ACTION_BUTTON_HEIGHT, HEADER_HEIGHT } from '@/constants/componentSize';
import { useWindowSize } from '@/hooks';
import { IOrderState } from '@/stores/trash/type';
import { IOrder } from '@/type';
import { Empty, Spin } from 'antd';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Wrapper } from './style';
interface IProps {
  trash: IOrderState;
  loading: boolean;
  globalSpinning: boolean;
  ordersRef: React.RefObject<HTMLDivElement>;
  handleGetOrderTrash: () => void;
  handleUpdateOrderTrash: (
    orderId: string,
    deleteStatus: IOrder['deleteStatus'],
    toastStatus?: string,
  ) => void;
}
const ListOfOrder: React.FC<IProps> = ({
  trash,
  loading,
  ordersRef,
  globalSpinning,
  handleGetOrderTrash,
  handleUpdateOrderTrash,
}) => {
  const orderTrashs = Object.values(trash.data);
  const size = useWindowSize();
  return (
    <Wrapper>
      <InfiniteScroll
        dataLength={orderTrashs.length}
        next={handleGetOrderTrash}
        hasMore={trash.hasMore}
        height={size.height - HEADER_HEIGHT - ACTION_BUTTON_HEIGHT}
        loader={
          <Spin style={{ width: '100%', marginTop: 20 }} spinning={loading} />
        }
      >
        <div ref={ordersRef} />
        {orderTrashs.length || globalSpinning ? (
          orderTrashs.map((order) => (
            <div style={{ marginBottom: '1rem' }} key={order._id}>
              <OrderCardTrash
                onUpdateOrderTrash={handleUpdateOrderTrash}
                order={order}
              />
            </div>
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </InfiniteScroll>
    </Wrapper>
  );
};
export default ListOfOrder;

import productDefault from '@/assets/images/productDefault.png';
import { IProduct } from '@/type';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import {
  Avatar,
  DeleteIcon,
  InformationContainer,
  LeftContainer,
  NameTitle,
  RightContainer,
  SupplierTitle,
  Wrapper,
} from './style';

interface IProps {
  product: IProduct;
  handleSelectProduct: (productId: IProduct['_id']) => void;
  handleDeleteProduct: (productId: IProduct['_id']) => void;
  isDeleting: boolean;
}

const ProductItem: React.FC<IProps> = ({
  product,
  handleDeleteProduct,
  handleSelectProduct,
  isDeleting,
}) => {
  const handleShowModalDelete = () => {
    handleSelectProduct(product._id);
    Modal.confirm({
      title: 'Are you sure you want to delete this product?',
      icon: <ExclamationCircleOutlined />,
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => handleDeleteProduct(product._id),
      visible: isDeleting,
    });
  };

  return (
    <Wrapper>
      <LeftContainer>
        <Avatar preview={false} src={product?.cover || productDefault} />
        <InformationContainer>
          <NameTitle>{product?.name}</NameTitle>
          <SupplierTitle>
            {typeof product.supplier === 'string'
              ? product.supplier
              : product.supplier?.name}
          </SupplierTitle>
        </InformationContainer>
      </LeftContainer>

      <RightContainer>
        {isDeleting ? (
          <LoadingOutlined />
        ) : (
          <DeleteIcon onClick={handleShowModalDelete} />
        )}
      </RightContainer>
    </Wrapper>
  );
};

export default ProductItem;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { getRolesApi } from '@/apis/role';

import { IGetRolesResponse } from './type';

export const getRoles = createAsyncThunk<IGetRolesResponse>(
  'role/getRoles',
  async (body, { rejectWithValue }) => {
    try {
      return await getRolesApi();
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

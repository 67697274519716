export const NOTIFICATION_TYPES = {
  NOTIFICATION: 'NOTIFICATION',
  MESSAGE: 'MESSAGE',
};

export type INotificationTypes =
  | typeof NOTIFICATION_TYPES.NOTIFICATION
  | typeof NOTIFICATION_TYPES.MESSAGE;

export default NOTIFICATION_TYPES;

import styled from 'styled-components';

export const Wrapper = styled.div`
  .ant-tabs-nav {
    padding: 0 1rem;
    margin-bottom: 0;
  }
`;

export const Content = styled.div``;

import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/stores/combineReducers';
import _ from 'lodash';
import configs from '@/configs';

import { ISupplierState } from './type';
import {
  getSuppliers,
  createSupplier,
  updateSupplier,
  deleteSupplier,
  getSupplier,
} from './actions';

const initialState: ISupplierState = {
  data: {},
  isFetching: false,
  isDeleting: false,
  isUpdating: false,
  isSubmitting: false,
  hasMore: true,
};

const supplierReducer = createSlice({
  name: 'supplier',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSuppliers.pending, (state) => {
      state.isFetching = true;
      state.hasMore = true;
    });
    builder.addCase(getSuppliers.fulfilled, (state, { payload }) => {
      const payloadCustom = _.keyBy(payload, '_id');

      state.hasMore = !(payload.length < configs.limit);
      state.data = Object.assign(state.data, payloadCustom);
      state.isFetching = false;
    });
    builder.addCase(getSuppliers.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(createSupplier.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(createSupplier.fulfilled, (state, { payload }) => {
      const { _id } = payload;
      state.data = { [_id]: payload, ...state.data };
      state.isSubmitting = false;
    });
    builder.addCase(createSupplier.rejected, (state) => {
      state.isSubmitting = false;
    });

    builder.addCase(updateSupplier.pending, (state) => {
      state.isUpdating = true;
    });
    builder.addCase(updateSupplier.fulfilled, (state, { payload }) => {
      const { _id } = payload;
      state.data[_id] = payload;
      state.isUpdating = false;
    });
    builder.addCase(updateSupplier.rejected, (state) => {
      state.isUpdating = false;
    });

    builder.addCase(deleteSupplier.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(deleteSupplier.fulfilled, (state, { payload }) => {
      const { _id } = payload;
      delete state.data[_id];
      state.isFetching = false;
    });
    builder.addCase(deleteSupplier.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(getSupplier.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getSupplier.fulfilled, (state, { payload }) => {
      const { _id } = payload;
      state.data[_id] = payload;
      state.isFetching = false;
    });
    builder.addCase(getSupplier.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export const supplierState = (state: RootState): ISupplierState =>
  state.supplier;
export default supplierReducer.reducer;

import apiRequest from '@/utils/apiRequest';
import {
  IUploadSingleRequest,
  IUploadSingleResponse,
  IUploadMultipleRequest,
  IUploadMultipleResponse,
} from '@/stores/upload/type';

const endpoint = '/uploads';

export const uploadSingleApi = (
  body: IUploadSingleRequest,
): Promise<IUploadSingleResponse> => {
  return apiRequest.post(`${endpoint}/single`, body);
};

export const uploadMultipleApi = (
  body: IUploadMultipleRequest,
): Promise<IUploadMultipleResponse> => {
  return apiRequest.post(`${endpoint}/multiple`, body);
};

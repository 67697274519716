import styled from 'styled-components';
import { InputNumber } from 'antd';

export const Wrapper = styled.div`
  width: 100%;
  padding: 1.145rem;
`;

export const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.57rem;
  color: ${({ theme }) => theme.color.secondaryGray};
  margin: 0;

  strong {
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.57rem;
    color: ${({ theme }) => theme.color.danger};
  }
`;

export const InputNumberCustom = styled(InputNumber)`
  width: 100%;
`;

import apiRequest from '@/utils/apiRequest';

import {
  IGetProductsRequest,
  IGetProductsResponse,
  IDeleteProductRequest,
  IDeleteProductResponse,
} from '@/stores/product/type';

const endpoint = '/products';

export const getProductsApi = (
  query: IGetProductsRequest,
): Promise<IGetProductsResponse> => {
  return apiRequest.get(endpoint, { params: query });
};

export const deleteProductApi = (
  params: IDeleteProductRequest,
): Promise<IDeleteProductResponse> => {
  return apiRequest.delete(`${endpoint}/${params._id}`);
};

import styled from 'styled-components';
import { Upload, Button, Form, Mentions, Image } from 'antd';
import { PictureOutlined } from '@ant-design/icons';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 2.85rem;
  border-top: 1px solid #d9d9d9;
  border-radius: 0.075rem;
  background-color: ${({ theme }) => theme.color.secondary};
  position: fixed;
  bottom: 0;

  @media screen and (min-width: 480px) {
    min-height: 4rem;
  }
`;

export const UploadImage = styled(Upload)`
  display: flex;
  align-items: center;
  height: 2.85rem;
  width: 2.85rem;
  margin-right: 0.2rem;
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    margin: 0;
  }

  @media screen and (min-width: 480px) {
    margin-right: 1rem;
    height: 4rem;
    width: 4rem;
  }
`;

export const UploadContainer = styled.div`
  height: 1.715rem;
  width: 1.715rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.color.border};
  color: ${({ theme }) => theme.color.border};

  @media screen and (min-width: 480px) {
    height: 3rem;
    width: 3rem;
  }
`;

export const ButtonCustom = styled(Button)`
  min-height: 2.85rem;
  width: 3.29rem;

  span {
    @media screen and (min-width: 480px) {
      font-size: 1.7rem;
    }
  }

  @media screen and (min-width: 480px) {
    height: 4rem;
    width: 5rem;
  }
`;

export const IconUploadImage = styled(PictureOutlined)`
  font-size: 0.857rem;

  @media screen and (min-width: 480px) {
    font-size: 1.7rem;
  }
`;

export const FormItemContainer = styled(Form.Item)`
  margin: 0;
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;

  @media screen and (min-width: 480px) {
    min-height: 4rem;
  }
`;

export const InputCustom = styled(Mentions)`
  margin: 0;
  width: 100%;
  padding: 0 1rem;
  border: none;
  outline: none;
  box-shadow: none;

  .antd-mentions-measure {
    position: absolute;
    top: 0;
  }

  &:focus,
  &:hover {
    outline: none;
    box-shadow: none;
  }
`;

export const ButtonFormItemContainer = styled(Form.Item)`
  margin: 0;
  padding: 0;
  min-height: 2.85rem;
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  div {
    margin: 0 0.7rem;
    font-size: 1rem;
  }
`;

export const Avatar = styled(Image)`
  border-radius: 50%;
`;

export const EditorContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  cursor: text;
  padding: 0 16px;
  padding-top: 0.5rem;
  min-height: 2.85rem;
  max-height: 6rem;
  width: calc(100% - 2.85rem - 3rem);
  overflow-y: scroll;

  img {
    height: 28px;
    width: 28px;
    border-radius: 30px;
    margin: 0 0.5rem;
    margin-top: -1.8rem;
  }

  @media screen and (min-width: 480px) {
    padding-top: 0.8rem;
    font-size: 1.5rem;
  }

  .mnw6qvm {
    padding: 0;

    span {
      padding-top: 0.4rem;
    }
  }
`;

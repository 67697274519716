import React, { useState } from 'react';
import { Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { INotificationState } from '@/stores/notification/type';
import { HEADER_HEIGHT, BREADCRUMB_HEIGHT } from '@/constants/componentSize';
import { NotificationHeader, NotificationItem } from '@/components';
import { useWindowSize } from '@/hooks';

import { NotificationWrapper } from './style';

interface IProps {
  notification: INotificationState;
  allowedSee?: boolean;
  fetchMoreData: () => void;
  handleSetRead: (_id: string) => void;
  handleViewAll: () => void;
  handleViewUnread: () => void;
  handleCloseDropdown: () => void;
}

const Notifications: React.FC<IProps> = ({
  notification,
  allowedSee,
  fetchMoreData,
  handleSetRead,
  handleViewUnread,
  handleViewAll,
  handleCloseDropdown,
}) => {
  const [isUnread, setIsUnread] = useState<boolean>(false);
  const title = 'notification';
  const size = useWindowSize();

  return (
    <NotificationWrapper>
      <NotificationHeader
        title={title}
        isUnread={isUnread}
        setIsUnread={setIsUnread}
        handleViewAll={handleViewAll}
        handleViewUnread={handleViewUnread}
        handleCloseDropdown={handleCloseDropdown}
      />

      <InfiniteScroll
        dataLength={notification.data.length}
        next={fetchMoreData}
        height={size.height - HEADER_HEIGHT - BREADCRUMB_HEIGHT}
        hasMore={notification.hasMore}
        loader={
          <Spin
            spinning={notification.isFetching}
            style={{ marginTop: 20, width: '100%' }}
          />
        }
      >
        {notification.data.map((notification) => (
          <NotificationItem
            data={notification}
            key={notification._id}
            allowedSee={allowedSee}
            onRead={handleSetRead}
            handleCloseDropdown={handleCloseDropdown}
          />
        ))}
      </InfiniteScroll>
    </NotificationWrapper>
  );
};

export default Notifications;

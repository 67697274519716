import axios, { AxiosResponse, AxiosError } from 'axios';
import configs from '@/configs';

const apiRequest = axios.create({
  baseURL: configs.apiUrl,
});

const handleResponseSuccess = (response: AxiosResponse) => {
  return Promise.resolve(response.data);
};

const handleResponseFailed = (error: AxiosError) => {
  if (error.response?.status === 401) {
    return Promise.reject('Login session has expired.');
  }

  return Promise.reject(error.response?.data || { message: error.message });
};

apiRequest.interceptors.response.use(
  handleResponseSuccess,
  handleResponseFailed,
);

export default apiRequest;

import React from 'react';
import { match } from 'react-router-dom';
import { useAppSelector } from '@/hooks';

interface IProps {
  match: match<{ id: string }>;
}

const DynamicOrderBreadcrumb: React.FC<IProps> = ({ match }) => {
  const { order } = useAppSelector((state) => state);
  return <span>{`#${order.data?.[match.params.id]?.orderId || ''}`}</span>;
};

export default DynamicOrderBreadcrumb;
